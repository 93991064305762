import { Send } from '@material-ui/icons';
import { Inline, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';

interface TripPaymentStatusTagProps {
  isDriverPaid?: boolean | null;
  isPaidByCustomer?: boolean | null;
  isCustomerBilled?: boolean | null;
}

export function TripPaymentStatusTag({
  isDriverPaid,
  isPaidByCustomer,
  isCustomerBilled,
}: TripPaymentStatusTagProps) {
  return (
    <Inline>
      {isDriverPaid && (
        <Tag color="green" variant="subtle">
          <Inline verticalAlign="center" space="xxsmall" noWrap={true}>
            <DriveawayIcon fontSize="small" />
            <TextBox variant="body-semibold" color="inherit" noWrap={true}>
              Driver Paid
            </TextBox>
          </Inline>
        </Tag>
      )}

      {isPaidByCustomer && (
        <Tag color="green" variant="subtle">
          <Inline verticalAlign="center" space="xxsmall" noWrap={true}>
            <DriveawayIcon fontSize="small" />
            <TextBox variant="body-semibold" color="inherit" noWrap={true}>
              Paid by Customer
            </TextBox>
          </Inline>
        </Tag>
      )}

      {isCustomerBilled && (
        <Tag color="green" variant="subtle">
          <Inline verticalAlign="center" space="xxsmall" noWrap={true}>
            <Send fontSize="small" />
            <TextBox variant="body-semibold" color="inherit" noWrap={true}>
              Billed
            </TextBox>
          </Inline>
        </Tag>
      )}
    </Inline>
  );
}
