import { parseDate } from '@superdispatch/dates';
import { useMemo } from 'react';
import { useInsurances } from 'shared/modules/verified-carrier-application/core/CargoInsuranceList';

export function isInsuranceExpiring(expirationDate: string) {
  const diffDate = parseDate(expirationDate).diffNow('days');
  return diffDate.isValid && diffDate.days <= 30;
}

export function useCargoInsuranceExpiring() {
  const { currentInsurance, renewalInsurance } = useInsurances();

  return useMemo(() => {
    return {
      isInsuranceExpiring:
        currentInsurance?.expiration_date &&
        isInsuranceExpiring(currentInsurance.expiration_date),
      isRenewalInsuranceUploaded: !!renewalInsurance,
      isRenewalInsuranceVerified: !!renewalInsurance?.is_verified,
    };
  }, [currentInsurance, renewalInsurance]);
}
