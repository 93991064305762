import {
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Inline, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useLocationParams } from 'shared/routing/LocationParams';
import {
  tripsPageParamsSchema,
  TripStatus,
  TRIP_STATUSES,
} from '../data/TripsDTO';
import { TripStatusTag } from './TripStatusTag';

function ensureTripStatusArray(value: unknown): TripStatus[] {
  if (
    Array.isArray(value) &&
    value.every((item) => TRIP_STATUSES.includes(item))
  ) {
    return value as TripStatus[];
  }
  throw new Error('Value is not a valid TripStatus array');
}

export function TripStatusFilter() {
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const statusFilter = useMemo(
    () => (params.trip_status?.split(',') || []) as TripStatus[],
    [params.trip_status],
  );

  const renderValue = (value: TripStatus[]) => {
    const [firstValue] = value;

    return (
      <Inline>
        {firstValue && <TripStatusTag status={firstValue} />}
        {value.length > 1 && (
          <Tag color="grey" variant="subtle">
            + {value.length - 1} more
          </Tag>
        )}
      </Inline>
    );
  };

  return (
    <TextField
      select={true}
      value={statusFilter}
      onChange={({ target: { value } }) => {
        updateParams({
          trip_status: ensureTripStatusArray(value).join(','),
        });
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography variant="body1" color="textSecondary">
              Trip Status
            </Typography>
          </InputAdornment>
        ),
      }}
      SelectProps={{
        multiple: true,
        renderValue,
      }}
    >
      {TRIP_STATUSES.map((status) => (
        <MenuItem key={status} value={status}>
          <Box
            width="140px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TripStatusTag status={status} />
            {statusFilter.includes(status) && (
              <Check color="primary" fontSize="small" />
            )}
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
}
