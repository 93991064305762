import { Typography as MuiTypography } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box as SDBox, Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIError, createAPIError, isAPIError } from 'shared/api/APIError';
import { FormikDrawer, FormikDrawerContent } from 'shared/form/FormikDrawer';
import { isEmpty } from 'shared/helpers/CommonHelpers';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { BillingUpgradeDialog } from './core/BillingUpgradeDialog';
import { DriverDTO, inviteDriverDTO } from './data/DriverDTO';
import {
  CONNECTION_ALREADY_EXISTS_ERROR,
  INVITE_ALREADY_SENT_ERROR,
  JOIN_REQUEST_ALREADY_SENT_ERROR,
} from './data/DriverPageUtils';
import { trackDriversEvent } from './data/DriversAnalytics';
import { useDriversAPI } from './data/DriversAPI';

const Box = styled(SDBox)`
  padding: 6px 8px;
`;

const Typography = styled(MuiTypography)`
  font-size: 13px;
`;

interface DriverInviteDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export function DriverInviteDrawer({
  open,
  onClose,
  onOpen,
}: DriverInviteDrawerProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { inviteDriver } = useDriversAPI();
  const shouldShowSeatBasedPricing = useFlag('seats_based_pricing');
  const [requestedDriver, setRequestedDriver] = useState<{
    guid: string | undefined;
    type: string | undefined;
  }>();
  const [shouldConfirm, setShouldConfirm] = useState<boolean>(false);
  const { data: settings } = useCarrierSettings();

  const formik = useFormikEnhanced({
    enableReinitialize: true,
    validationSchema: inviteDriverDTO,
    initialValues: {
      email: '',
      phone_number: '',
      name: '',
    },
    onSubmit: (values) => {
      return inviteDriver(values);
    },
    onSubmitSuccess: (driver: DriverDTO) => {
      trackDriversEvent({
        name: 'Carrier Sent Invite To Driver',
        driverGuid: driver.guid,
      });
      addSnackbar('Invite successfully sent', { variant: 'success' });
      handleClose();
      setShouldConfirm(false);
    },
    onSubmitFailure: (e) => {
      onOpen();
      setShouldConfirm(false);
      if (isAPIError(e) && isDriverExistsError(e)) {
        const error = createAPIError(e) as APIError<{ guid: string }>;
        setRequestedDriver({ guid: error.context?.guid, type: error.type });
      }
    },
  });

  function handleClose(id?: string) {
    formik.resetForm();
    setRequestedDriver(undefined);
    onClose();
    if (id) {
      navigate(`/drivers/${id}`);
    }
  }

  const { isSubmitting, errors } = formik;
  const isValidForm = isEmpty(errors);

  const handleSubmit = () => {
    const isPayingAndValid =
      settings?.is_paying && isValidForm && !shouldShowSeatBasedPricing;
    const shouldSubmitForm = !settings?.is_paying || shouldShowSeatBasedPricing;

    if (isPayingAndValid) {
      setShouldConfirm(true);
    } else if (shouldSubmitForm) {
      void formik.submitForm();
    }
  };

  return (
    <FormikDrawer open={open} onClose={handleClose}>
      <FormikDrawerContent
        formik={formik}
        onClose={onClose}
        title="Invite Driver"
        disableAutoClose={true}
        disableNavigationBlock={!!requestedDriver?.guid}
      >
        <Stack space="large">
          <Stack space="small">
            <SDBox width="368px">
              <TextBox color="secondary">
                Information entered will be used to send the driver text message
                and email with instructions to download the Super Dispatch
                Driver App.
              </TextBox>
            </SDBox>

            <FormikTextField
              name="name"
              autoFocus={true}
              label={
                <Inline space="xxsmall">
                  Full Name
                  <Typography color="textSecondary">(Optional)</Typography>
                </Inline>
              }
              fullWidth={true}
            />

            <FormikTextField
              name="email"
              label="Email"
              fullWidth={true}
              placeholder="Type driver’s email"
            />

            <SuspendedFormikPhoneField
              fullWidth={true}
              name="phone_number"
              label="Phone Number"
            />

            {requestedDriver?.guid && (
              <Box width="368px" borderRadius="small" backgroundColor="Blue50">
                <Typography>{formatError(requestedDriver.type)}</Typography>
              </Box>
            )}
          </Stack>
          {requestedDriver?.guid ? (
            <Button
              onClick={() => {
                handleClose(requestedDriver.guid);
              }}
            >
              {formatButtonText(requestedDriver.type)}
            </Button>
          ) : (
            <Button
              type={
                settings?.is_paying && !shouldShowSeatBasedPricing
                  ? 'button'
                  : 'submit'
              }
              pending={isSubmitting}
              onClick={handleSubmit}
              disabled={!formik.dirty}
            >
              Save
            </Button>
          )}
        </Stack>
      </FormikDrawerContent>

      <BillingUpgradeDialog
        isOpen={shouldConfirm}
        isLoading={isSubmitting}
        onClose={() => {
          setShouldConfirm(false);
          onOpen();
        }}
        onClick={() => formik.submitForm()}
      />
    </FormikDrawer>
  );
}

function formatError(type?: string) {
  switch (type) {
    case JOIN_REQUEST_ALREADY_SENT_ERROR:
      return 'This driver has already requested to join your carrier. Open Request to accept them.';
    case CONNECTION_ALREADY_EXISTS_ERROR:
      return 'This driver has already joined your carrier. Open Profile to see details.';
    default:
      return "This driver has already been invited but hasn't accepted the invitation yet. Open Invite to see details.";
  }
}

function formatButtonText(type?: string) {
  switch (type) {
    case JOIN_REQUEST_ALREADY_SENT_ERROR:
      return 'Open Request';
    case CONNECTION_ALREADY_EXISTS_ERROR:
      return 'Open Profile';
    default:
      return 'Open Invite';
  }
}

function isDriverExistsError(error: APIError) {
  return (
    error.type === JOIN_REQUEST_ALREADY_SENT_ERROR ||
    INVITE_ALREADY_SENT_ERROR ||
    CONNECTION_ALREADY_EXISTS_ERROR
  );
}
