import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import {
  isIdentificationStepComplete,
  ProfileVerifiedCarrierApplicationTabs,
} from 'shared/modules/carrier-profile/core/ProfileVerifiedCarrierApplicationTabs';
import { useIsCarrierWithoutUSDOT } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { StepCargoInsurancePage } from 'shared/modules/verified-carrier-application/StepCargoInsurancePage';
import { StepCargoInsurancePageLegacy } from 'shared/modules/verified-carrier-application/StepCargoInsurancePageLegacy';
import { StepCarrierInfoPage } from 'shared/modules/verified-carrier-application/StepCarrierInfoPage';
import { StepIdentificationPage } from 'shared/modules/verified-carrier-application/StepIdentificationPage';
import { StepPhoneVerificationPage } from 'shared/modules/verified-carrier-application/StepPhoneVerificationPage';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';

const VerificationContainer = styled(Box)`
  height: 700px;
  width: 100%;
  padding: 24px 0;
`;

interface VerificationStepsProps {
  onBack: () => void;
  onClose: () => void;
  onCompleted: () => void;
}

export function VerificationSteps({
  onBack,
  onClose,
  onCompleted,
}: VerificationStepsProps) {
  const { currentStep, progress, setCurrentStep } = useVerificationSteps();
  const isCargoRenewalEnabled = useFlag('cargo_insurance_renewal');

  return (
    <VerificationContainer>
      <Stack space="medium">
        <ProfileVerifiedCarrierApplicationTabs
          type="dialog"
          activeStep={currentStep}
        />
        {currentStep === 3 ? (
          <Box
            maxWidth="90%"
            marginLeft="auto"
            marginRight="auto"
            paddingBottom="xxlarge"
          >
            {isCargoRenewalEnabled ? (
              <StepCargoInsurancePage
                onBack={() => {
                  setCurrentStep(2);
                }}
                onComplete={() => {
                  setCurrentStep(4);
                }}
              />
            ) : (
              <StepCargoInsurancePageLegacy
                onBack={() => {
                  setCurrentStep(2);
                }}
                onComplete={() => {
                  setCurrentStep(4);
                }}
              />
            )}
          </Box>
        ) : (
          <Box
            maxWidth="700px"
            marginLeft="auto"
            marginRight="auto"
            paddingBottom="xxlarge"
          >
            {currentStep === 1 && (
              <StepPhoneVerificationPage
                onBack={onBack}
                isPhoneVerificationComplete={
                  !!progress?.fmcsa_phone_verification_is_complete
                }
                onComplete={() => {
                  setCurrentStep(2);
                }}
              />
            )}
            {currentStep === 2 && (
              <StepCarrierInfoPage
                onBack={() => {
                  setCurrentStep(1);
                }}
                onComplete={() => {
                  setCurrentStep(3);
                }}
              />
            )}

            {currentStep === 4 && (
              <StepIdentificationPage
                onBack={() => {
                  setCurrentStep(3);
                }}
                onComplete={() => {
                  onCompleted();
                  onClose();
                }}
              />
            )}
          </Box>
        )}
      </Stack>
    </VerificationContainer>
  );
}

function useVerificationSteps() {
  const { data } = useVerifiedCarrierApplicationStatus();
  const { data: carrierInfo } = useCarrierInfo();
  const isNonUSDOTCarrier = useIsCarrierWithoutUSDOT();

  const progress = data?.verified_carrier_application.progress;
  const isStep1Complete = !!progress?.fmcsa_phone_verification_is_complete;
  const isStep2Complete = !!progress?.carrier_info_step_is_complete;
  const isStep3Complete = !!progress?.cargo_insurance_step_is_complete;
  const isStep4Complete =
    !!progress &&
    !!carrierInfo &&
    isIdentificationStepComplete(progress, carrierInfo, isNonUSDOTCarrier);
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3 | 4>(1);

  useEffect(() => {
    if (!isStep1Complete) {
      setCurrentStep(1);
    } else if (!isStep2Complete) {
      setCurrentStep(2);
    } else if (!isStep3Complete) {
      setCurrentStep(3);
    } else if (!isStep4Complete) {
      setCurrentStep(4);
    }
  }, [isStep1Complete, isStep2Complete, isStep3Complete, isStep4Complete]);

  return {
    currentStep,
    setCurrentStep,
    progress,
  };
}
