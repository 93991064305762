import { Tooltip } from '@material-ui/core';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { VehicleConditionIcon } from 'shared/ui/VehicleConditionIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadForTripDTO } from '../data/TripsDTO';

export function TripFormVehicles({
  vehicles = [],
}: {
  vehicles: LoadForTripDTO['vehicles'];
}) {
  const [firstVehicle] = vehicles;
  return (
    <Stack space="xsmall">
      <Inline space="xxsmall" verticalAlign="center">
        <TextBox variant="body-semibold">
          <Inline space="xxsmall">
            {joinStrings(
              ' ',
              firstVehicle?.year,
              firstVehicle?.make,
              firstVehicle?.model,
            )}
            {typeof firstVehicle?.is_inoperable === 'boolean' && (
              <VehicleConditionIcon isInoperable={firstVehicle.is_inoperable} />
            )}
          </Inline>
        </TextBox>
      </Inline>

      {vehicles.length > 1 && (
        <Tooltip
          title={
            <Stack>
              {vehicles.map((vehicle, i) => (
                <Inline key={i}>
                  {joinStrings(' ', vehicle.year, vehicle.make, vehicle.model)}
                  {typeof vehicle.is_inoperable === 'boolean' && (
                    <VehicleConditionIcon
                      isInoperable={vehicle.is_inoperable}
                      color="white"
                    />
                  )}
                </Inline>
              ))}
            </Stack>
          }
        >
          <Tag color="grey" variant="subtle">
            +{vehicles.length - 1} more
          </Tag>
        </Tooltip>
      )}
    </Stack>
  );
}
