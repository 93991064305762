import { Paper, TablePagination } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { toInteger } from 'lodash-es';
import { useLocationParams } from 'shared/routing/LocationParams';
import styled from 'styled-components';
import { useTripsPage } from '../data/TripsAPI';
import { tripsPageParamsSchema } from '../data/TripsDTO';
import { TripsTable } from './TripsTable';

const Styled1 = styled(TablePagination).attrs({ component: 'div' })`
  & {
    /* Remove space on the right to make left aligned */
    .MuiTablePagination-spacer {
      flex: 0;
    }

    /* Remove extra pixel from top for select icon  */
    .MuiTablePagination-selectIcon {
      top: unset;
    }
  }
`;

const PaginationWrapper = styled(Paper)`
  z-index: 20;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: ${Color.White};
  border-top: 1px solid ${Color.Silver400};
`;

const TableWapperBox = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  padding: 16px 32px 0 32px;
`;

const TableScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`;

export function TripsListContent() {
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const { data, error, isLoading } = useTripsPage(params);

  return (
    <Wrapper>
      <TableWapperBox>
        <TableScrollContainer>
          <TripsTable
            trips={data?.data}
            isLoading={isLoading}
            error={error}
            ordering={params.ordering}
            onOrderingChange={(ordering) => {
              updateParams({ ordering });
            }}
          />
        </TableScrollContainer>

        {data && data.pagination.count > 9 && (
          <PaginationWrapper>
            <Styled1
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={data.pagination.count}
              rowsPerPage={params.page_size}
              onRowsPerPageChange={(event) => {
                const pageSize = toInteger(event.target.value);
                const lastPage = Math.ceil(data.pagination.count / pageSize);

                updateParams({
                  page_size: pageSize,
                  page: params.page > lastPage ? lastPage : params.page,
                });
              }}
              page={params.page - 1}
              onPageChange={(_, pageNumber) => {
                updateParams({ page: pageNumber + 1 });
              }}
            />
          </PaginationWrapper>
        )}
      </TableWapperBox>
    </Wrapper>
  );
}
