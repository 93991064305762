import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import {
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import { BankVerificationDrawerFormContent } from './BankVerificationDrawerFormContent';
import {
  getStatusErrors,
  useBankVerificationFormDrawer,
} from './useBankVerificationFormDrawer';

function BankVerificationActionDrawer({ isDisabled }: { isDisabled: boolean }) {
  const { submitCount, isValid } = useFormikContext();
  return (
    <FormikDrawerActions
      submitButtonLabel="Verify"
      submitDisabled={isDisabled || (!!submitCount && !isValid)}
    />
  );
}

interface Props {
  bankAccountDetails?: SuperPayBankAccountDTO;
  onClose?: () => void;
  onComplete: () => void;
}

export function BankVerificationContentDrawer({
  bankAccountDetails,
  onClose,
  onComplete,
}: Props) {
  const formik = useBankVerificationFormDrawer({
    onComplete,
  });

  const { isExceeded } = getStatusErrors(formik.status);

  return (
    <Box maxWidth={['initial', 'initial', '432px']}>
      <FormikDrawerContent
        formik={formik}
        title="Verify Bank Account"
        disableAutoClose={true}
        onClose={onClose}
        disableNavigationBlock={true}
        actions={
          !!bankAccountDetails && (
            <BankVerificationActionDrawer isDisabled={isExceeded} />
          )
        }
      >
        <Box marginTop="-small">
          <BankVerificationDrawerFormContent
            bankAccountDetails={bankAccountDetails}
            onContactSupport={onComplete}
          />
        </Box>
      </FormikDrawerContent>
    </Box>
  );
}
