import { useDeepEqualValue } from '@superdispatch/hooks';
import { useSnackbarStack } from '@superdispatch/ui';
import { createContext, ReactNode, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createAPIError } from 'shared/api/APIError';
import { APIListQueryResult } from 'shared/api/APIListQuery';
import { APIQueryResult } from 'shared/api/APIQuery';
import {
  SearchQueryTextType,
  useSearchQueryText,
} from 'shared/helpers/ReactHelpers';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { usePromptNavigate } from 'shared/routing/NavigationBlock';
import { DriverDTO } from './data/DriverDTO';
import { useDriver, useDriverList } from './data/DriversAPI';

export interface DriversPageContext {
  rootURL: string;
  currentDriverGUID: string | undefined;
  openDriver: (guid: string, replace?: boolean) => void;
  searchQueryDrivers: SearchQueryTextType;
  driverList: APIListQueryResult<DriverDTO>;
  driver: APIQueryResult<DriverDTO>;
}

const context = createContext<null | DriversPageContext>(null);

export function useDriversPageContext(): DriversPageContext {
  return useNullableContext('DriversPageContext', context);
}

export interface DriversPageContextProviderProps {
  children?: ReactNode;
}

const rootURL = '/drivers';

const CONNECTION_ALREADY_CANCELED_ERROR_TYPE = 'ConnectionAlreadyCancelled';
const CONNECTION_ALREADY_EXPIRED_ERROR_TYPE = 'ConnectionAlreadyExpired';
const CONNECTION_DOES_NOT_EXIST_ERROR_TYPE = 'ConnectionDoesNotExist';

const errorMessages = {
  [CONNECTION_ALREADY_CANCELED_ERROR_TYPE]:
    'The request was canceled by driver.',
  [CONNECTION_ALREADY_EXPIRED_ERROR_TYPE]: 'The request expired.',
  [CONNECTION_DOES_NOT_EXIST_ERROR_TYPE]: 'The request does not exist.',
};

export function DriversPageContextProvider({
  children,
}: DriversPageContextProviderProps) {
  const { addSnackbar, clearStack } = useSnackbarStack();
  const { driverGUID: currentDriverGUID } = useParams();

  const searchQueryDrivers = useSearchQueryText();
  const [_, searchQuery] = searchQueryDrivers;
  const driverList = useDriverList({ q: searchQuery, page_size: 20 });
  const driver = useDriver(currentDriverGUID, {
    onError: (e) => {
      const error = createAPIError(e);
      const message = errorMessages[error.type as keyof typeof errorMessages];

      addSnackbar(
        message || 'An error occurred while loading driver information.',
        { variant: 'error', autoHideDuration: null },
      );
    },
  });

  const navigate = usePromptNavigate();
  const openDriver = useCallback(
    (guid: string, replace?: boolean) => {
      const driverURL = `${rootURL}/${guid}`;
      navigate(driverURL, { replace });
    },
    [navigate],
  );

  useEffect(() => {
    return () => {
      clearStack();
    };
  }, [clearStack]);

  const ctx = useDeepEqualValue({
    rootURL,
    openDriver,
    currentDriverGUID,
    searchQueryDrivers,
    driverList,
    driver,
  });

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
