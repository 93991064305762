import {
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Inline, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useLocationParams } from 'shared/routing/LocationParams';
import { tripsPageParamsSchema } from '../data/TripsDTO';
import { TripPaymentStatusTag } from './TripPaymentStatusTag';

type TripPaymentStatus =
  | 'is_customer_billed'
  | 'is_driver_paid'
  | 'is_paid_by_customer';

export function TripPaymentStatusFilter() {
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
    persistName: 'trips-payment-status-filter',
    onParseSessionParams: (prevParams) => {
      return {
        ...prevParams,
        query: '',
      };
    },
  });

  const paymentStatusFilter = useMemo(
    () =>
      [
        params.is_customer_billed && 'is_customer_billed',
        params.is_driver_paid && 'is_driver_paid',
        params.is_paid_by_customer && 'is_paid_by_customer',
      ].filter(Boolean),
    [
      params.is_customer_billed,
      params.is_driver_paid,
      params.is_paid_by_customer,
    ],
  );

  const renderValue = (value: TripPaymentStatus[]) => {
    const [firstValue] = value;

    return (
      <Inline>
        {firstValue && (
          <TripPaymentStatusTag
            isCustomerBilled={firstValue === 'is_customer_billed'}
            isDriverPaid={firstValue === 'is_driver_paid'}
            isPaidByCustomer={firstValue === 'is_paid_by_customer'}
          />
        )}
        {value.length > 1 && (
          <Tag color="grey" variant="subtle">
            + {value.length - 1} more
          </Tag>
        )}
      </Inline>
    );
  };

  return (
    <TextField
      select={true}
      value={paymentStatusFilter}
      onChange={({ target: { value } }) => {
        const isCustomerBilled = value.includes('is_customer_billed');
        const isDriverPaid = value.includes('is_driver_paid');
        const isPaidByCustomer = value.includes('is_paid_by_customer');

        if (isCustomerBilled || isDriverPaid || isPaidByCustomer) {
          updateParams({
            is_customer_billed: isCustomerBilled,
            is_driver_paid: isDriverPaid,
            is_paid_by_customer: isPaidByCustomer,
          });
        } else {
          updateParams({
            is_customer_billed: undefined,
            is_driver_paid: undefined,
            is_paid_by_customer: undefined,
          });
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography variant="body1" color="textSecondary">
              Payment Status
            </Typography>
          </InputAdornment>
        ),
      }}
      SelectProps={{
        multiple: true,
        renderValue,
      }}
    >
      <MenuItem value="is_customer_billed">
        <Box
          width="170px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TripPaymentStatusTag isCustomerBilled={true} />
          {params.is_customer_billed && (
            <Check color="primary" fontSize="small" />
          )}
        </Box>
      </MenuItem>
      <MenuItem value="is_driver_paid">
        <Box
          width="170px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TripPaymentStatusTag isDriverPaid={true} />
          {params.is_driver_paid && <Check color="primary" fontSize="small" />}
        </Box>
      </MenuItem>
      <MenuItem value="is_paid_by_customer">
        <Box
          width="170px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TripPaymentStatusTag isPaidByCustomer={true} />
          {params.is_paid_by_customer && (
            <Check color="primary" fontSize="small" />
          )}
        </Box>
      </MenuItem>
    </TextField>
  );
}
