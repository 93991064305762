import { logError } from './ErrorTracker';
import { openExternalURL } from './URLHelpers';

function sanitizeFileName(fileName: string): string {
  // Replace invalid characters with an underscore or any other preferred character
  return fileName.replace(/[\\/:*?"<>|]/g, '_');
}

export function downloadBlob(blob: Blob, fileName: string) {
  const objectUrl = window.URL.createObjectURL(blob);
  try {
    const downloadLink = document.createElement('a');
    Object.assign(downloadLink, {
      style: 'display: none',
      href: objectUrl,
      download: sanitizeFileName(fileName),
    });
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(objectUrl);
  } catch (error) {
    openExternalURL(objectUrl, { target: '_blank' });
    logError(error, 'Failed to download file');
  }
}
