import { MenuItem, TextField, Toolbar, Typography } from '@material-ui/core';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useLocation } from 'react-router-dom';
import { useLazyRef } from 'shared/hooks/useLazyRef';
import { LinkButton } from 'shared/routing/Links';
import { useLocationParams } from 'shared/routing/LocationParams';
import { useFlag } from 'shared/settings/FeatureToggles';
import { TripPaymentStatusFilter } from '../core/TripPaymentStatusFilter';
import { TripsPageFilter } from '../core/TripsPageFilter';
import { TripStatusFilter } from '../core/TripStatusFilter';
import {
  TripsFilterField,
  tripsPageParamsSchema,
  tripsPageParamsSchemaLegacy,
  TripsSearchType,
  TRIPS_FILTER_FIELDS,
} from '../data/TripsDTO';
import { TripsSearchField } from './TripsSearchField';

export const SEARCH_TYPE_LABELS: Array<[TripsSearchType, string]> = [
  ['trip', 'Trip Name'],
  ['driver', 'Driver Name'],
];

export function TripsListHeader() {
  const shouldShowTripChanges = useFlag('trip_improvements_v2');
  const getLazyRef = useLazyRef<HTMLInputElement, TripsFilterField>();
  const { search } = useLocation();
  const [params, updateParams] = useLocationParams({
    yupSchema: shouldShowTripChanges
      ? tripsPageParamsSchema
      : (tripsPageParamsSchemaLegacy as unknown as typeof tripsPageParamsSchema),
  });

  const isParamsDirty =
    params.query ||
    params.trip_status ||
    params.dispatcher_guid ||
    params.driver_guid ||
    params.is_customer_billed ||
    params.is_driver_paid ||
    params.is_paid_by_customer;

  return (
    <Toolbar>
      <Box width="100%" padding="small">
        <Stack space="large">
          <Columns space="xxsmall">
            <Column width="fluid">
              <Typography variant="h2">Trips</Typography>
            </Column>

            {!shouldShowTripChanges && (
              <Column width="1/3">
                <Columns space="small">
                  <Column width="content">
                    <TextField
                      select={true}
                      aria-label="search by"
                      value={params.search_type}
                      onChange={({ target: { value } }) => {
                        updateParams({
                          page: 1,
                          search_type: value as TripsSearchType,
                        });
                      }}
                    >
                      {SEARCH_TYPE_LABELS.map(([key, label]) => (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Column>

                  <Column width="fluid">
                    <TripsSearchField
                      value={params.query || ''}
                      placeholder="Search for trips..."
                      onChange={(value) => {
                        updateParams({
                          page: 1,
                          query: value,
                        });
                      }}
                    />
                  </Column>
                </Columns>
              </Column>
            )}

            <Column width="fluid">
              <Inline horizontalAlign="right">
                <LinkButton
                  variant="primary"
                  to={{
                    pathname: '/trips/create',
                    search,
                  }}
                >
                  <Typography variant="h5">Create Trip</Typography>
                </LinkButton>
              </Inline>
            </Column>
          </Columns>
          {shouldShowTripChanges && (
            <Inline>
              <TripsSearchField
                placeholder="Search..."
                value={params.query || ''}
                onChange={(value) => {
                  updateParams({
                    page: 1,
                    query: value,
                  });
                }}
              />
              {TRIPS_FILTER_FIELDS.map((filter) => {
                const ref = getLazyRef(filter);

                return (
                  <TripsPageFilter
                    key={filter}
                    name={filter}
                    inputRef={ref}
                    value={params[filter] ?? undefined}
                    onChange={(guid) => {
                      updateParams({ [filter]: guid, page: undefined });
                    }}
                  />
                );
              })}

              <TripStatusFilter />
              <TripPaymentStatusFilter />

              {isParamsDirty && (
                <Button
                  variant="text"
                  onClick={() => {
                    updateParams({
                      query: undefined,
                      trip_status: undefined,
                      dispatcher_guid: undefined,
                      driver_guid: undefined,
                      is_customer_billed: undefined,
                      is_driver_paid: undefined,
                      is_paid_by_customer: undefined,
                    });
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Inline>
          )}
        </Stack>
      </Box>
    </Toolbar>
  );
}
