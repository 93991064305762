import { Link, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  DrawerList,
  Inline,
  Stack,
  useSnackbarStack,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { partition } from 'lodash-es';
import { useMemo } from 'react';
import {
  MutationDrawer,
  MutationDrawerContent,
} from 'shared/form/MutationDrawer';
import { trackEvent } from 'shared/helpers/Analytics';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { emitLoadEvent } from '../../../carrier/loads/data/LoadEvents';
import {
  useAcceptOffers,
  useOffersList,
} from '../../../carrier/offers/offers/data/OffersAPI';
import emptyLoadOfferImage from './assets/empty-load.png';
import { LoadOffersDrawerItem } from './LoadOffersDrawerItem';

const OffersTerms = () => (
  <TextBox color="secondary" display="inline">
    By accepting all offers you agree to our{' '}
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.superdispatch.com/terms-of-service"
    >
      Terms of Service
    </Link>
  </TextBox>
);

interface LoadOffersDrawerContentProps {
  onClose: () => void;
}

function LoadOffersDrawerContent({ onClose }: LoadOffersDrawerContentProps) {
  const offersList = useOffersList();

  const { addSnackbar } = useSnackbarStack();
  const acceptOffers = useAcceptOffers({
    onSuccess({ user_message }, offers) {
      onClose();
      trackEvent('[CTMS] Accepted All Load Offers', {
        number_of_accepted_offers: offers.length,
      });
      addSnackbar(user_message || 'Offers accepted.', { variant: 'success' });
      emitLoadEvent('accepted');
    },
    onError({ message }) {
      addSnackbar(message || 'Failed to accept offers.', { variant: 'error' });
    },
  });

  const [counterOffers, offers] = useMemo(() => {
    const allOffers = offersList.data?.pages.flatMap((page) =>
      page.data.filter((offer) => {
        return !!offer.load;
      }),
    );

    return partition(allOffers, (offer) => offer.is_counter_offer);
  }, [offersList.data]);

  const handleAcceptAllOffers = () => {
    acceptOffers.mutate(offers.map((offer) => offer.guid));
  };

  return (
    <MutationDrawerContent
      onClose={onClose}
      title={
        <Inline verticalAlign="center" space="small">
          <TextBox variant="heading-3">Load Offers</TextBox>
          {!offersList.hasNextPage &&
            !!offersList.data?.pages[0]?.pagination.count &&
            !counterOffers.length && (
              <Box display="flex">
                <Button
                  variant="neutral"
                  startIcon={<Check color="primary" />}
                  size="small"
                  pending={acceptOffers.isLoading}
                  onClick={handleAcceptAllOffers}
                >
                  Accept All Offers
                </Button>
              </Box>
            )}
        </Inline>
      }
      enableAutoClose={true}
      disableDrawerContent={true}
    >
      {!counterOffers.length && !!offers.length && (
        <Box maxWidth="480px" paddingLeft={['large']} paddingRight={['large']}>
          <OffersTerms />
        </Box>
      )}

      {!offersList.isFetching &&
        !offersList.data?.pages[0]?.pagination.count && (
          <Box
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Stack space="medium" align="center">
              <img
                width="68px"
                height="80px"
                src={emptyLoadOfferImage}
                alt="No Load Offers"
              />
              <Stack space="xsmall" align="center">
                <Typography color="textSecondary" align="center" variant="h4">
                  No Offers Yet
                </Typography>
                <Box width="320px">
                  <Typography align="center" color="textSecondary">
                    To receive offers, start by booking a load. Once the shipper
                    approves your booking, offers will appear here.
                  </Typography>
                </Box>
              </Stack>
              <Button
                onClick={() => {
                  openExternalURL('/loadboard');
                }}
                variant="primary"
                size="large"
              >
                Find Loads on Super Loadboard
              </Button>
            </Stack>
          </Box>
        )}

      <DrawerList disablePadding={true}>
        {!!counterOffers.length && (
          <>
            <Box marginLeft="large">
              <TextBox variant="heading-4">Counter Offers</TextBox>
            </Box>

            {counterOffers.map((offer) => (
              <LoadOffersDrawerItem offer={offer} key={offer.guid} />
            ))}
          </>
        )}

        {!!counterOffers.length && !!offers.length && (
          <Box marginLeft="large" marginTop="medium">
            <Stack>
              <Inline space="small">
                <TextBox variant="heading-4">Load Offers</TextBox>
                <Button
                  variant="neutral"
                  startIcon={<Check color="primary" />}
                  size="small"
                  pending={acceptOffers.isLoading}
                  onClick={handleAcceptAllOffers}
                >
                  Accept All Offers
                </Button>
              </Inline>
              <OffersTerms />
            </Stack>
          </Box>
        )}

        {offers.map((offer) => (
          <LoadOffersDrawerItem offer={offer} key={offer.guid} />
        ))}

        {(offersList.isFetching || offersList.hasNextPage) && (
          <ListItem>
            <ListItemText
              disableTypography={true}
              primary={<Skeleton />}
              secondary={<Skeleton />}
            />
          </ListItem>
        )}

        {!offersList.isFetching && offersList.hasNextPage && (
          <VisibilityObserver
            render={({ ref }) => <div ref={ref} />}
            onChange={(visibility) => {
              if (visibility === 'visible') {
                void offersList.fetchNextPage();
              }
            }}
          />
        )}
      </DrawerList>
    </MutationDrawerContent>
  );
}

export interface LoadOffersDrawerProps extends LoadOffersDrawerContentProps {
  open: boolean;
}

export function LoadOffersDrawer({ open, onClose }: LoadOffersDrawerProps) {
  return (
    <MutationDrawer open={open} onClose={onClose}>
      <LoadOffersDrawerContent onClose={onClose} />
    </MutationDrawer>
  );
}
