import { Card, LinearProgress, Typography } from '@material-ui/core';
import { Color, Inline, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';

const Container = styled.div<{ maxWidth?: string }>`
  position: relative;
  margin: -8px auto 16px;
  max-width: ${({ maxWidth = '720px' }) => maxWidth};
  display: flex;
  flex-direction: column;

  &[data-is-mobile='true'] {
    margin: 0 auto 16px;
    & .MuiPaper-outlined {
      border: none;
    }
  }
`;
const Progress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: ${Color.Silver400};
  }
`;

interface OnboardingLayoutProps {
  children: ReactNode;
  /** In percentage */
  progress: number;
  maxWidth?: string;
}

export function OnboardingLayout({
  children,
  progress,
  maxWidth,
}: OnboardingLayoutProps) {
  const navigate = useNavigate();
  const { data } = useCarrierSettings();
  const isMobile = useResponsiveValue(true, false);

  return (
    <LoginLayout
      header={
        <Inline verticalAlign="center" space="medium">
          {!isMobile && (
            <Typography variant="h5" color="textSecondary">
              {data?.carrier.name}
            </Typography>
          )}
          <Button
            variant="neutral"
            onClick={() => {
              navigate('/logout');
            }}
          >
            Log Out
          </Button>
        </Inline>
      }
    >
      <Container maxWidth={maxWidth} data-is-mobile={isMobile}>
        <Card square={isMobile}>
          <Progress variant="determinate" value={progress} />
          <Box padding={['medium', 'large']}>{children}</Box>
        </Card>
      </Container>
    </LoginLayout>
  );
}
