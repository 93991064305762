import { IconButton, Toolbar } from '@material-ui/core';
import { Add, ArrowBack, MoreHoriz } from '@material-ui/icons';
import {
  CardButton,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { atom, useAtomValue } from 'jotai';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { useBoolean } from 'shared/hooks/useBoolean';
import { ChevronDoubleLeftIcon } from 'shared/icons';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { TripsDetailsActions } from './core/TripDetailsActions';
import { TripDetailsAttachment } from './core/TripDetailsAttachments';
import { TripDetailsDriverContent } from './core/TripDetailsDriverContent';
import { TripDetailsInternalNotes } from './core/TripDetailsInternalNotes';
import { TripDetailsName } from './core/TripDetailsName';
import { TripDetailsPayment } from './core/TripDetailsPayment';
import { TripDetailsTable } from './core/TripDetailsTable';
import { TripLoadDialog } from './core/TripLoadDialog';
import { useTrip } from './data/TripsAPI';
import { TripActions } from './trip-actions/TripActions';
import { TripPrimaryActions } from './trip-actions/TripPrimaryActions';

const AddLoadCardButton = styled(CardButton)`
  min-height: 140px;
`;
const CardsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
`;
const ShowMapButton = styled(Button)`
  position: absolute;
  top: 100px;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 4px 8px 0px rgba(25, 35, 52, 0.1),
    0px 0px 4px 0px rgba(25, 35, 52, 0.1);
`;

const HIDE_MAP_STORAGE_KEY = 'trips-details-hide-map';

export function TripsDetails() {
  const loadDialog = useBoolean();
  const { tripGUID = '' } = useParams<{ tripGUID: string }>();
  const isTripsImprovementsV2 = useFlag('trip_improvements_v2');
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const hideMapStorageValue = useLocalStore(HIDE_MAP_STORAGE_KEY);

  const { data: trip, isLoading } = useTrip(tripGUID, {
    onError: (error) => {
      addSnackbar(
        error.message || 'Error occurred while fetching trip details',
        {
          variant: 'error',
        },
      );
    },
  });

  if (!trip) {
    return <PageLayout loading={isLoading} />;
  }

  return (
    <>
      <DocumentTitle title={trip.name ? `${trip.name} - Trips` : 'Trips'} />
      <PageLayout
        loading={isLoading}
        stickyHeader={true}
        disablePaddings={true}
        header={
          <Toolbar>
            <Columns space="small">
              <Column width="fluid">
                <Inline verticalAlign="center">
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                  <TripDetailsName trip={trip} />
                </Inline>
              </Column>

              <Column width="content">
                {isTripsImprovementsV2 ? (
                  <Inline horizontalAlign="right" space="small">
                    <TripPrimaryActions
                      trip={trip}
                      onEditRoute={loadDialog.setTrue}
                    />

                    <Button
                      variant="neutral"
                      id="options-button"
                      aria-controls={anchorEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={anchorEl ? 'true' : undefined}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreHoriz />
                    </Button>
                    <TripActions
                      id="options-menu"
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={handleCloseMenu}
                      trip={trip}
                      onItemClick={handleCloseMenu}
                    />
                  </Inline>
                ) : (
                  <TripsDetailsActions
                    trip={trip}
                    onAddLoad={loadDialog.setTrue}
                  />
                )}
              </Column>
            </Columns>
          </Toolbar>
        }
      >
        <Box padding="small" paddingLeft="medium" paddingRight="medium">
          <Stack space="small">
            <Columns space="small" collapseBelow="desktop">
              <Column width="fluid">
                <Stack space="small">
                  {trip.loads?.length ? (
                    <Box
                      minWidth="500px"
                      overflowY="auto"
                      borderWidth="small"
                      borderRadius="small"
                      borderColor="Silver400"
                      data-intercom-target="trip-loads-table"
                    >
                      <TripDetailsTable
                        path={`/trips/view/${tripGUID}`}
                        tripGUID={tripGUID}
                        loads={trip.loads}
                        isTripArchived={!!trip.archived}
                        route={trip.route}
                        onEditRoute={loadDialog.setTrue}
                      />
                    </Box>
                  ) : (
                    <AddLoadCardButton
                      startIcon={<Add />}
                      onClick={loadDialog.setTrue}
                      data-intercom-target="trip-loads-table"
                    >
                      Add Route
                    </AddLoadCardButton>
                  )}

                  <CardsContainer data-intercom-target="trip-attachments-and-notes">
                    <div>
                      <TripDetailsAttachment trip={trip} />
                    </div>
                    <TripDetailsInternalNotes trip={trip} />
                    {hideMapStorageValue && <TripDetailsPayment trip={trip} />}
                  </CardsContainer>
                </Stack>
              </Column>
              {!hideMapStorageValue && (
                <Column width="1/4">
                  <Stack space="small">
                    <TripDetailsDriverContent
                      trip={trip}
                      onHideMap={() => {
                        LocalStore.set(HIDE_MAP_STORAGE_KEY, 'true');
                      }}
                    />
                    <TripDetailsPayment trip={trip} />
                  </Stack>
                </Column>
              )}
            </Columns>
          </Stack>
        </Box>

        {/* Extra space to allow scrolling past content obscured by the Intercom popup */}
        <Box height="100px" />

        <TripLoadDialog
          open={loadDialog.isEnabled}
          onClose={loadDialog.setFalse}
          trip={trip}
        />

        {hideMapStorageValue && (
          <ShowMapButton
            onClick={() => {
              LocalStore.delete(HIDE_MAP_STORAGE_KEY);
            }}
            variant="neutral"
            startIcon={<ChevronDoubleLeftIcon />}
          />
        )}
      </PageLayout>
    </>
  );
}

export const hoveredFieldNameAtom = atom('');
export function useHoveredFieldNameValue() {
  return useAtomValue(hoveredFieldNameAtom);
}
