import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate } from 'react-router-dom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DispatcherPageContextProvider } from '../data/DispatchersProvider';
import { DispatchersListSidebar } from './DispatchersListSidebar';
import { DispatcherPageDetails } from './DispatchersPageDetails';

export function DispatchersPage() {
  const shouldShowDispatcherManagement = useFlag('dispatcher_management_page');

  if (!shouldShowDispatcherManagement) {
    return <Navigate to="/tms/loads" />;
  }

  return (
    <DispatcherPageContextProvider>
      <SidebarContainer sidebar={<DispatchersListSidebar />}>
        <DispatcherPageDetails />
      </SidebarContainer>
    </DispatcherPageContextProvider>
  );
}
