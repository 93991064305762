import { Color } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { WebviewLayout } from 'shared/layout/WebviewLayout';
import { BackButton } from 'shared/ui/BackButton';

interface ProfileVerificationCarrierApplicationLayoutProps {
  children: ReactNode;
}
export function ProfileVerifiedCarrierApplicationLayout({
  children,
}: ProfileVerificationCarrierApplicationLayoutProps) {
  const navigate = useNavigate();

  useDocumentTitle('Verified Carrier Application');

  if (isWebView) {
    return (
      <WebviewLayout scrollable={false} backgroundColor={Color.White}>
        {children}
      </WebviewLayout>
    );
  }

  return (
    <PageLayout
      background="White"
      header={
        <PageHeader
          title="Verified Carrier Application"
          startAction={
            <BackButton
              onClick={() => {
                navigate('/profile');
              }}
            />
          }
        />
      }
    >
      {children}
    </PageLayout>
  );
}
