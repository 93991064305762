import { Drawer, DrawerProps, IconButton } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  useUID,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider, FormikValues, useFormikContext } from 'formik';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocationChangeEffect } from 'shared/routing/LocationChangeEffect';
import { Prompt } from 'shared/routing/NavigationBlock';

interface FormikDrawerContext {
  setIsSubmitting?: (isSubmitting: boolean) => void;
}

const context = createContext<FormikDrawerContext>({});

export interface FormikDrawerProps {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  drawerProps?: Omit<DrawerProps, 'open' | 'anchor' | 'onClose'>;
}

export function FormikDrawer({
  onClose,
  children,
  drawerProps,
  open = false,
}: FormikDrawerProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ctx = useMemo<FormikDrawerContext>(
    () => ({ setIsSubmitting }),
    [setIsSubmitting],
  );

  useEffect(() => {
    if (!open) setIsSubmitting(false);
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => {
        if (!isSubmitting) onClose?.();
      }}
      {...drawerProps}
    >
      <context.Provider value={ctx}>{children}</context.Provider>
    </Drawer>
  );
}

export interface BaseFormikDrawerContentProps {
  title?: ReactNode;
  disableClose?: boolean;
  enableBack?: boolean;
  onBack?: () => void;
  actions?: ReactNode;
  children?: ReactNode;
  formik: FormikContextTypeEnhanced<FormikValues, unknown>;
  minWidth?: string;
  maxWidth?: string;
  disableNavigationBlock?: boolean;
}

export type FormikDrawerContentProps = BaseFormikDrawerContentProps &
  (
    | { onClose?: () => void; disableAutoClose: true }
    | { onClose: () => void; disableAutoClose?: false }
  );

export function FormikDrawerContent({
  formik,

  title,
  disableClose,
  enableBack,
  actions,
  children,

  onBack,
  onClose,
  disableAutoClose,
  minWidth,
  maxWidth,
  disableNavigationBlock,
}: FormikDrawerContentProps) {
  const uid = useUID();
  const iconID = `${uid}-icon`;
  const labelID = `${uid}-label`;
  const { setIsSubmitting } = useContext(context);

  const { dirty, status, isSubmitting } = formik;
  const isSubmitted = status.type === 'submitted';

  useEffect(() => {
    setIsSubmitting?.(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  useLocationChangeEffect(() => {
    if (!disableAutoClose) onClose();
  });

  return (
    <FormikProvider value={formik}>
      {!!title && (
        <DrawerTitle
          title={title}
          titleTypographyProps={{ id: labelID }}
          startAction={
            enableBack && (
              <IconButton onClick={onBack} edge="start">
                <ArrowBack />
              </IconButton>
            )
          }
          endAction={
            disableClose ? null : (
              <IconButton
                edge="end"
                onClick={onClose}
                disabled={isSubmitting}
                aria-labelledby={`${iconID} ${labelID}`}
              >
                <Close id={iconID} aria-label="close" />
              </IconButton>
            )
          }
        />
      )}

      <Form style={{ minWidth, maxWidth }} aria-labelledby={labelID}>
        <Prompt
          when={
            isSubmitting || (dirty && !isSubmitted && !disableNavigationBlock)
          }
          message="Changes have not been saved. Leaving will result in unsaved changes being lost."
        />
        <DrawerContent>{children}</DrawerContent>
        {actions}
      </Form>
    </FormikProvider>
  );
}

export interface FormikDrawerActionsProps {
  submitButtonLabel?: ReactNode;
  submitDisabled?: boolean;
}

export function FormikDrawerActions({
  submitButtonLabel = 'Save',
  submitDisabled = false,
}: FormikDrawerActionsProps) {
  const { isSubmitting } = useFormikContext();

  return (
    <DrawerActions>
      <Button type="submit" pending={isSubmitting} disabled={submitDisabled}>
        {submitButtonLabel}
      </Button>
    </DrawerActions>
  );
}
