import { Paper, Typography } from '@material-ui/core';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useAtomValue, useSetAtom } from 'jotai';
import { LatLngBounds } from 'leaflet';
import { useEffect, useMemo, useRef } from 'react';
import LeafletMap, { mapAtom } from 'shared/form/LeafletMap';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { LeafletCustomControl } from 'shared/helpers/LeafletCustomControl';
import { ChevronDoubleRightIcon } from 'shared/icons';
import styled from 'styled-components';
import { TripDTO } from '../data/TripsDTO';
import { useAddTripDetailsMarkers } from '../data/useAddTripDetailsMarkers';
import { useHoveredFieldNameValue } from '../TripsDetails';
import { TripDetailsAssignDriver } from './TripDetailsAssignDriver';

// Kansas City
const DEFAULT_CENTER: [number, number] = [39.099724, -94.578331];
const DEFAULT_ZOOM = 4;

const MapWrapper = styled(Box)`
  height: 500px;
`;

export interface TripDetailsDriverContentProps {
  trip: TripDTO;
  onHideMap: () => void;
}

export function TripDetailsDriverContent({
  trip,
  onHideMap,
}: TripDetailsDriverContentProps) {
  const setMap = useSetAtom(mapAtom);
  const map = useAtomValue(mapAtom);
  const fieldName = useHoveredFieldNameValue();
  const isFitBounds = useRef(false);
  const isVehicleInfoVisible = !!trip.vehicles_count || !!trip.vehicles_weight;
  const markers = useAddTripDetailsMarkers({ trip, fieldName });
  const bounds = useMemo(
    () => new LatLngBounds(markers.map((marker) => marker.position)),
    [markers],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      const shouldFitBounds = map && bounds.isValid() && !isFitBounds.current;
      if (shouldFitBounds) {
        map.invalidateSize().fitBounds(bounds.pad(0.1));
        isFitBounds.current = true;
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [map, bounds]);

  return (
    <Box data-intercom-target="trip-driver-content">
      <Paper>
        <Stack space="none">
          <MapWrapper>
            <LeafletMap
              center={DEFAULT_CENTER}
              zoom={DEFAULT_ZOOM}
              setMap={setMap}
              markers={markers}
            >
              <LeafletCustomControl prepend={true} position="topright">
                <Button
                  variant="neutral"
                  startIcon={<ChevronDoubleRightIcon />}
                  onClick={onHideMap}
                >
                  Hide Map
                </Button>
              </LeafletCustomControl>
            </LeafletMap>
          </MapWrapper>
          <Box padding="small" paddingTop="medium">
            <Columns align="center" space="large">
              <Column width="fluid">
                <TripDetailsAssignDriver trip={trip} />
              </Column>

              {isVehicleInfoVisible && (
                <Column width="content">
                  <Inline horizontalAlign="right">
                    <Stack space="none">
                      {!!trip.vehicles_count && (
                        <Typography variant="caption">
                          {trip.vehicles_count}{' '}
                          {formatPlural(
                            trip.vehicles_count,
                            'vehicle',
                            'vehicles',
                          )}
                        </Typography>
                      )}
                      {!!trip.vehicles_weight && (
                        <Typography variant="h4">
                          {trip.vehicles_weight} lb
                        </Typography>
                      )}
                    </Stack>
                  </Inline>
                </Column>
              )}
            </Columns>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
