import { Typography } from '@material-ui/core';
import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import paymentAdditionalIllustration from '../../assets/promo_terms_additional.png';
import paymentTermsInformationIllustration from '../../assets/promo_terms_payments_information.png';
import paymentStatusIllustration from '../../assets/promo_terms_payment_status.png';

export function PromoTerms() {
  const isMobile = useResponsiveValue(true, true, false);

  return (
    <Box
      maxWidth="670px"
      borderColor="Silver400"
      borderWidth={['none', 'small']}
      borderRadius="small"
      padding={['none', 'small']}
      margin="auto"
      paddingTop="medium"
    >
      <Stack align="center" space={['small', 'large']}>
        <Box maxWidth={['100%', '300px']}>
          <Stack space="xsmall">
            <Typography variant="h2" align="center">
              New SuperPay Terms
            </Typography>
            <TextBox variant="body" color="secondary" align="center">
              Due to popular demand, SuperPay now has additional payment term
              options!
            </TextBox>
          </Stack>
        </Box>
        <Stack space="small">
          <Columns space="small" collapseBelow="desktop">
            <Column width="1/2">
              <Box
                height={['auto', '335px']}
                backgroundColor="Silver200"
                borderRadius="small"
                padding="medium"
              >
                <Stack align="center" space="small">
                  <Stack align="center" space="xxsmall">
                    <Typography variant="h4" align="center">
                      Additional SuperPay Terms
                    </Typography>
                    <TextBox variant="body" color="secondary" align="center">
                      Shippers now have the option to choose different payment
                      term lengths.
                    </TextBox>
                  </Stack>
                  <Box maxWidth="192px">
                    <img
                      src={paymentAdditionalIllustration}
                      width="100%"
                      alt="payment additional terms"
                    />
                  </Box>
                </Stack>
              </Box>
            </Column>
            <Column width="1/2">
              <Box
                height={['auto', '335px']}
                backgroundColor="Teal50"
                borderRadius="small"
                padding="medium"
              >
                <Stack align="center" space="small">
                  <Stack align="center" space="xxsmall">
                    <Box maxWidth="200px">
                      <Typography variant="h4" align="center">
                        Carefully review listed SuperPay terms
                      </Typography>
                    </Box>
                    <TextBox variant="body" color="secondary" align="center">
                      Make sure to review the payment timeframe each Shipper has
                      set before finalizing your booking.
                    </TextBox>
                  </Stack>
                  <Box maxWidth="253px">
                    <img
                      src={paymentTermsInformationIllustration}
                      width="100%"
                      alt="payment information terms"
                    />
                  </Box>
                </Stack>
              </Box>
            </Column>
          </Columns>
          <Box backgroundColor="Blue50" borderRadius="small" padding="medium">
            <Columns align="center" collapseBelow="desktop" space="small">
              <Column>
                <Stack space="xxsmall" align={isMobile ? 'center' : 'left'}>
                  <Box maxWidth={['200px', '150px', '150px']}>
                    <Typography
                      variant="h4"
                      align={isMobile ? 'center' : 'left'}
                    >
                      Know when your payment is coming
                    </Typography>
                  </Box>
                  <TextBox
                    variant="body"
                    color="secondary"
                    align={isMobile ? 'center' : 'left'}
                  >
                    Your expected payment deposit date will be reflected inside
                    the order after delivery confirmation has been received.
                  </TextBox>
                </Stack>
              </Column>
              <Column width="content">
                <Stack align="right">
                  <Box maxWidth="320px">
                    <img
                      src={paymentStatusIllustration}
                      width="100%"
                      alt="payment status"
                    />
                  </Box>
                </Stack>
              </Column>
            </Columns>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
