import { FormikPasswordField, FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { Alert, Box } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { createAPIError } from 'shared/api/APIError';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import { validatePasswordStrength } from 'shared/utils/PasswordUtils';
import { yupString } from 'shared/utils/YupUtils';
import { ref } from 'yup';
import { useDispatcherProfileAPI } from '../data/DispatchersAPI';
import { dispatcherProfilePasswordSchema } from '../data/DispatchersDTO';

const editDispatcherProfilePasswordSchema =
  dispatcherProfilePasswordSchema.shape({
    newConfirm: yupString().oneOf(
      [ref('new')],
      'Not matched with new password',
    ),
  });

interface DispatcherEditPasswordDrawerContentProps {
  onClose: () => void;
}

export function DispatcherEditPasswordDrawerContent({
  onClose,
}: DispatcherEditPasswordDrawerContentProps) {
  const { updateDispatcherPassword } = useDispatcherProfileAPI();
  const { data } = useDispatcherProfile();
  const isChangePasswordMessageEnabled = useFlag(
    'save_new_password_to_generic_user_in_dispatcher',
  );
  const [errMsgs, setErrMsgs] = useState<string[]>();
  const formik = useAppFormik({
    validationSchema: editDispatcherProfilePasswordSchema,
    onSubmit(values) {
      return updateDispatcherPassword(
        dispatcherProfilePasswordSchema.cast(values),
      );
    },
    onSubmitSuccess() {
      onClose();
    },
    getSuccessMessage({ user_message }) {
      return user_message || 'Password Changed';
    },
    onSubmitFailure(e) {
      const error = createAPIError<{ new: string[] }>(e);
      if (error.context?.new) {
        setErrMsgs(error.context.new);
      }
    },
  });

  const passwordstrength = useMemo(
    () => validatePasswordStrength(formik.values.new),
    [formik.values.new],
  );

  return (
    <FormikDrawerContent
      formik={formik}
      onClose={onClose}
      title="Change Password"
      disableAutoClose={true}
      actions={
        <FormikDrawerActions
          submitDisabled={!formik.isValid || !formik.dirty}
        />
      }
    >
      <Stack space="small">
        {isChangePasswordMessageEnabled && !!data?.driver && (
          <Box maxWidth="368px">
            <Alert icon={<WarningIcon />} severity="caution">
              You also have driver role. Your Driver App password will also
              update.
            </Alert>
          </Box>
        )}
        <FormikTextField
          label="Current Password"
          name="current"
          type="password"
          fullWidth={true}
        />

        <Stack space="xsmall">
          <FormikPasswordField
            label="New Password"
            type="password"
            name="new"
            fullWidth={true}
          />

          <PasswordValidationStepper
            passwordstrength={passwordstrength}
            value={formik.values.new}
          />
        </Stack>

        <FormikPasswordField
          label="Re-enter New Password"
          name="newConfirm"
          type="password"
          fullWidth={true}
        />
        {errMsgs && (
          <Box maxWidth="368px">
            <Alert icon={false} severity="critical">
              <ul>
                {errMsgs.map((msg, i) => (
                  <li key={i}>{msg}</li>
                ))}
              </ul>
            </Alert>
          </Box>
        )}
      </Stack>
    </FormikDrawerContent>
  );
}

interface DispatcherEditPasswordDrawerProps
  extends DispatcherEditPasswordDrawerContentProps {
  open: boolean;
}

export function DispatcherEditPasswordDrawer({
  onClose,
  open,
}: DispatcherEditPasswordDrawerProps) {
  return (
    <FormikDrawer open={open} onClose={onClose}>
      <DispatcherEditPasswordDrawerContent onClose={onClose} />
    </FormikDrawer>
  );
}
