import { kebabCase } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { To, useLocation } from 'react-router-dom';
import { logError, logWarning } from 'shared/helpers/ErrorTracker';
import { SessionStore } from 'shared/helpers/Store';
import { JsonObject } from 'type-fest';
import { parse, stringify } from 'urltron';
import { ObjectSchema } from 'yup';
import { usePromptNavigate } from './NavigationBlock';

function tryParse<T>(search: string): T {
  try {
    return parse(search) as T;
  } catch (error: unknown) {
    logWarning('Parse error in LocationParams', {
      error,
      search,
    });
  }

  return parse(new URLSearchParams(search).toString()) as T;
}

export interface LocationParamsOptions<T extends JsonObject> {
  persistName?: string;
  yupSchema?: ObjectSchema<T>;
  onParseSessionParams?: (params: T) => T;
}

export interface UpdateParamsOptions {
  /**
   * Used for `history` api.
   * @default 'push'
   */
  navigation?: 'push' | 'replace';

  /**
   * Params update strategy
   *
   * `merge` – to merge with pre previous values, e.g:
   * `updateParams({ page: 2 }, { mode: 'merge' })`
   *  will change `{ page: 1, page_size: 10 }` to `{ page: 2, page_size: 10 }`
   *
   * `reset` – to override with the default values, e.g:
   * `updateParams({ page_size: 50 }, { mode: 'reset' })`
   * will change `{ page: 3, page_size: 10 }` to `{ page: 1, page_size: 50 }`
   *
   * `override` - sets only provided values, e.g:
   * `updateParams({ group_by: 'name' }, { mode: 'override' })`
   * will change `{ page: 3, page_size: 10 }` to `{ group_by: 'name' }`
   *
   * @default 'merge'
   */
  strategy?: 'merge' | 'reset';
}

export type UpdateParams<T> = (
  input: Partial<T> | ((params: T) => Partial<T>),
  options?: UpdateParamsOptions,
) => void;

export function useLocationParams<T extends JsonObject>({
  yupSchema,
  persistName,
  onParseSessionParams,
}: LocationParamsOptions<T> = {}): readonly [T, UpdateParams<T>] {
  const parseParams = useCallback(
    (search: string): T => {
      let nextParams = tryParse<T>(search);

      if (yupSchema) {
        try {
          nextParams = yupSchema.cast(nextParams);
        } catch (e: unknown) {
          logError(e, 'LocationParams');
        }
      }

      return nextParams;
    },
    [yupSchema],
  );

  const location = useLocation();
  const params = useMemo(
    () => parseParams(location.search),
    [parseParams, location.search],
  );

  const navigate = usePromptNavigate();

  const updateParams = useCallback<UpdateParams<T>>(
    (input, { strategy = 'merge', navigation = 'push' } = {}) => {
      const prevParams = parseParams(location.search);

      if (typeof input == 'function') input = input(prevParams);
      if (strategy === 'merge') input = { ...prevParams, ...input };

      const search = getSearchParamString(input);
      const nextLocation: To = {
        search,
      };
      const shouldReplace = navigation === 'replace';

      navigate(nextLocation, { replace: shouldReplace });
    },
    [navigate, parseParams, location.search],
  );

  const persistKey = !persistName ? null : `@@lp-${kebabCase(persistName)}`;

  useEffect(
    () => {
      if (persistKey && !location.search) {
        const persistedValue = SessionStore.get(persistKey);
        if (persistedValue) {
          navigate({ search: persistedValue }, { replace: true });
        }
      }
    },
    // We want this effect to run only during the initial mount.
    [persistKey, parseParams, location.search, navigate],
  );

  const onParse = useCallback(
    (prevParams: T): T => {
      if (onParseSessionParams) {
        return onParseSessionParams(prevParams);
      }
      return prevParams;
    },
    [onParseSessionParams],
  );

  useEffect(() => {
    if (persistKey) {
      const prevParams = parseParams(location.search);
      let { search } = location;

      const result = onParse(prevParams);
      search = getSearchParamString(result);

      SessionStore.set(persistKey, search);
    }
  }, [persistKey, location.search, onParse, parseParams, location]);

  return [params, updateParams];
}

function getSearchParamString<T>(input: Partial<T>): string {
  const accumulatedObject: T = {} as T;
  const filteredInput = Object.entries(input).reduce<T>((acc, [key, value]) => {
    if (value !== null) {
      acc[key as keyof T] = value as T[keyof T];
    }
    return acc;
  }, accumulatedObject);

  //https://github.com/recurrency/urltron/issues/7
  return stringify(filteredInput);
}
