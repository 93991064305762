import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Alert } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { CelebrationIcon } from 'shared/icons/CelebrationIcon';

export function useSignupCelebration() {
  const navigate = useNavigate();

  function closeSignupCelebrationNotification() {
    navigate('/tms/loads', { replace: true });
  }

  return {
    closeSignupCelebrationNotification,
  };
}

export function SignUpCelebrationNotification() {
  const { closeSignupCelebrationNotification } = useSignupCelebration();
  return (
    <Alert
      icon={<CelebrationIcon />}
      severity="info"
      onClose={() => {
        closeSignupCelebrationNotification();
      }}
    >
      <Stack space="xxsmall">
        <Typography variant="body1">
          Your 7-day free trial of Carrier TMS starts now!
        </Typography>
        <Typography>
          You have unlimited access to explore advanced load management
          features.
        </Typography>
      </Stack>
    </Alert>
  );
}
