import { Typography } from '@material-ui/core';
import { useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { DialogWithIllustration } from 'shared/ui/DialogWithIllustration';
import styled from 'styled-components';
import verifyPhoneIllustration from '../assets/verify-phone.png';

const MainIllustration = styled.img`
  width: 140px;
`;

const InlineActions = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

interface VerifyFmcsaPhoneDialogProps {
  isOpen: boolean;
  onCancel: () => void;
}

export function VerifyFmcsaPhoneDialog({
  isOpen,
  onCancel,
}: VerifyFmcsaPhoneDialogProps) {
  const navigate = useNavigate();
  const isMobile = useResponsiveValue(true, false);
  return (
    <DialogWithIllustration
      open={isOpen}
      illustration={<MainIllustration src={verifyPhoneIllustration} />}
      title="Verify your phone number before uploading documentation"
      actions={
        <InlineActions>
          <Button onClick={onCancel} variant="neutral" fullWidth={isMobile}>
            Do It Later
          </Button>
          <Button
            onClick={() => {
              navigate('/profile/phone-verification');
            }}
            fullWidth={isMobile}
          >
            Verify now
          </Button>
        </InlineActions>
      }
    >
      <Typography align="center">
        Verifying your phone number helps us keep our platform secure and your
        identity safe.
      </Typography>
    </DialogWithIllustration>
  );
}

interface VerifyFmcsaPhoneProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export function VerifyFmcsaPhone({
  isOpen: isOpenProp,
  onClose,
}: VerifyFmcsaPhoneProps) {
  const navigate = useNavigate();

  const { data } = useVerifiedCarrierApplicationStatus();
  const verificationStatus = data?.verified_carrier_application;
  const isPhoneNotVerified =
    !!verificationStatus &&
    !verificationStatus.progress.fmcsa_phone_verification_is_complete;

  const isOpen =
    isOpenProp ||
    (isPhoneNotVerified &&
      !['non_verifiable', 'not_submitted'].includes(verificationStatus.status));

  return (
    <VerifyFmcsaPhoneDialog
      onCancel={() => {
        if (isOpenProp && onClose) {
          onClose();
        } else {
          navigate('/profile');
        }
      }}
      isOpen={isOpen}
    />
  );
}
