import * as Cookies from 'js-cookie';
import { useMemo } from 'react';

export function getCookie(name: string): string | undefined {
  // Avoid private storage errors
  try {
    return Cookies.get(name);
  } catch {
    return undefined;
  }
}

export function setCookie(name: string, value: string): void {
  try {
    Cookies.set(name, value);
  } catch {}
}

export function deleteCookie(name: string): void {
  try {
    Cookies.remove(name);
  } catch {}
}

export function useCookie(name: string): string | undefined {
  return useMemo(() => getCookie(name), [name]);
}
