import { Link, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import cargoInsuranceExample from '../assets/cargoInsuranceExample.png';
import { DocumentPreview } from './DocumentPreview';
import { ImageMarker } from './ImageMarker';

export function CargoInsurancePreview() {
  return (
    <DocumentPreview
      title="Cargo Insurance Example Preview"
      imgSrc={cargoInsuranceExample}
      header={
        <Typography variant="inherit" paragraph={true}>
          For more detailed instructions refer to{' '}
          <Link
            href="https://support.superdispatch.com/en/articles/3112938-how-do-i-become-a-verified-carrier"
            target="_blank"
            rel="noreferrer"
            color="primary"
          >
            our support article.
          </Link>
        </Typography>
      }
      footer={
        <div>
          <Typography variant="h6" color="textSecondary" gutterBottom={true}>
            Certificate Holder
          </Typography>

          <Stack aria-label="Certificate Holder address" space="none">
            <Typography>Super Dispatch</Typography>
            <Typography>905 McGee St. #210</Typography>
            <Typography>Kansas City, MO 64106</Typography>
          </Stack>
        </div>
      }
    >
      <ImageMarker
        top="27%"
        left="33%"
        label="1"
        title={
          <Typography align="center">
            The company name on insurance matches the company name on your Super
            Dispatch account
          </Typography>
        }
      />

      <ImageMarker
        top="48%"
        left="65%"
        label="2"
        title={
          <Typography align="center">
            The policy effective date and policy expiration date must be visible
            and not expired
          </Typography>
        }
      />

      <ImageMarker
        top="67%"
        left="27%"
        label="3"
        title={
          <Typography align="center">
            Cargo insurance coverage is included. Usually, this is listed as
            “Motor Truck Cargo” or “On-hook”
          </Typography>
        }
      />

      <ImageMarker
        top="86%"
        left="30%"
        label="4"
        title={
          <Typography align="center">
            Super Dispatch is showing as a certificate holder.
          </Typography>
        }
      />
    </DocumentPreview>
  );
}
