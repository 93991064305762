import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { useCarrierInfo } from '../carrier-profile/CarrierInfo';
import { useSaveBillingAddressMutation } from './SubscriptionAPI';

interface SubscriptionAddBillingAddressDialogProps {
  open: boolean;
  onClose: () => void;
}

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function SubscriptionAddBillingAddressDialog({
  open,
  onClose,
}: SubscriptionAddBillingAddressDialogProps) {
  return (
    <Dialog open={open}>
      <BillingAddressDialogContent onClose={onClose} />
    </Dialog>
  );
}

interface BillingAddressDialogContentProps {
  onClose: () => void;
}

function BillingAddressDialogContent({
  onClose,
}: BillingAddressDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data: carrierInfo } = useCarrierInfo();
  const { mutate: saveBillingAddress, isLoading } =
    useSaveBillingAddressMutation({
      onSuccess: () => {
        onClose();
        addSnackbar('Billing address confirmed', { variant: 'success' });
      },
      onError: (e) => {
        addSnackbar(e.message, { variant: 'error' });
      },
    });

  const doesCompanyExist = carrierInfo?.country && carrierInfo.state;

  function handleConfirm() {
    if (doesCompanyExist) {
      saveBillingAddress({
        billing_address: carrierInfo.address,
        billing_city: carrierInfo.city,
        billing_country: carrierInfo.country,
        billing_state: carrierInfo.state,
        billing_zip: carrierInfo.zip,
      });
    } else {
      openExternalURL('/tms/settings/subscription/edit_billing_address');
      onClose();
    }
  }

  return (
    <>
      <DialogTitle>
        {doesCompanyExist ? 'Confirm' : 'Add'} Your Billing Address Today!
      </DialogTitle>
      <DialogContent>
        <Stack space="small">
          <Stack space="xsmall">
            <Typography>
              As part of the Super Dispatch Verified Carrier Network, we need
              your help to meet new compliance requirements. Update your billing
              info to:
            </Typography>
            <List>
              <Stack space="xsmall">
                <li>Stay Compliant - Effortlessly meet new legal standards.</li>
                <li>
                  Avoid Interruptions - Keep your operations running smooth.
                </li>
              </Stack>
            </List>
          </Stack>
          {doesCompanyExist && (
            <Stack space="xxsmall">
              <Box
                backgroundColor="Blue50"
                paddingTop="xsmall"
                paddingBottom="xsmall"
                paddingLeft="small"
                paddingRight="small"
                borderRadius="small"
              >
                <Columns align="center">
                  <Column width="fluid">
                    <Stack space="none">
                      <Typography color="textSecondary">
                        Suggested Billing Address
                      </Typography>
                      <Typography>
                        {`${carrierInfo.address} ${carrierInfo.city}, `}
                        {joinStrings(
                          ', ',
                          carrierInfo.state + ' ' + carrierInfo.zip,
                          carrierInfo.country,
                        )}
                      </Typography>
                    </Stack>
                  </Column>
                  <Column width="content">
                    <Button
                      onClick={() => {
                        openExternalURL(
                          '/tms/settings/subscription/edit_billing_address',
                        );
                        onClose();
                      }}
                      variant="text"
                    >
                      Edit Address
                    </Button>
                  </Column>
                </Columns>
              </Box>
              <Typography color="textSecondary">
                Based on your company address
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Inline>
          <Button disabled={isLoading} onClick={onClose} variant="neutral">
            I’ll Do it Later
          </Button>
          <Button pending={isLoading} onClick={handleConfirm}>
            {doesCompanyExist ? 'Yes, I Confirm' : 'Add Billing Address'}
          </Button>
        </Inline>
      </DialogActions>
    </>
  );
}
