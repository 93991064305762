import { Link, Typography } from '@material-ui/core';
import { FormikPasswordField, FormikTextField } from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginErrorDialog } from 'shared/auth/core/LoginErrorDialog';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import {
  AuthResponse,
  isInactiveAccountError,
  isInvalidRoleError,
  useAuthAPI,
} from 'shared/auth/data/AuthAPI';
import { loginSchema } from 'shared/auth/data/AuthDTO';
import { trackLoginEvent } from 'shared/auth/data/LoginAnalytics';
import { useAppFormik } from 'shared/form/AppFormik';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { useVerification } from './core/VerificationStorage';
import { setAuthTokenLocally } from './data/AuthUtils';
import { useSubscriptionFetchExpirement } from './SubscriptionExpirement';

export interface LoginPageProps {
  initialEmail: string;
  onOpenForgotPassword: () => void;
  onEmailChange: (email: string) => void;
}

export function LoginPage({
  initialEmail,
  onEmailChange,
  onOpenForgotPassword,
}: LoginPageProps) {
  const navigate = useNavigate();
  const { login } = useAuthAPI();
  const { handleFetchExperiment, isLoading } = useSubscriptionFetchExpirement();
  const {
    saveVerificationEmail,
    saveVerificationPhoneNumber,
    saveVerificationCodeChannel,
  } = useVerification();

  const formik = useAppFormik({
    enableReinitialize: false,
    validationSchema: loginSchema,
    initialValues: { email: initialEmail },
    onSubmit(values) {
      return login(values);
    },
    onSubmitSuccess(response: AuthResponse) {
      if (response.data.is_mfa_enabled) {
        saveVerificationEmail(formik.values.email);
        saveVerificationPhoneNumber(response.data.phone_number);
        saveVerificationCodeChannel(response.data.mfa_channel);
        navigate({
          pathname: '/login/verify',
        });
      } else {
        setAuthTokenLocally(response.data.token);

        handleFetchExperiment();
      }
    },

    getErrorMessage(error) {
      return isInactiveAccountError(error) ? null : error.message;
    },

    onSubmitFailure(error) {
      if (isInvalidRoleError(error)) {
        trackLoginEvent({ name: 'CTMS: Attempted to Login with Driver Email' });
      }
    },
  });

  const { values } = formik;

  useValueObserver(values.email, () => {
    onEmailChange(values.email);
  });

  useEffect(() => {
    trackLoginEvent({ name: 'CTMS: Opened New Login Page' });
  }, []);

  return (
    <FormikProvider value={formik}>
      <LoginErrorDialog
        title="Account Deactivated"
        onClose={() => {
          formik.resetForm({ values });
        }}
        message={
          formik.status.type !== 'rejected' ||
          !isInactiveAccountError(formik.status.payload)
            ? undefined
            : formik.status.payload.message
        }
      />

      <LoginLayoutContent>
        <Form aria-label="login" onSubmit={formik.handleSubmit}>
          <Stack space="large">
            <Stack space="small">
              <Typography variant="h1" align="center">
                Carrier TMS
              </Typography>
              <FormikTextField name="email" label="Email" fullWidth={true} />
              <FormikPasswordField
                name="password"
                label="Password"
                fullWidth={true}
              />
            </Stack>

            <Button
              type="submit"
              size="large"
              fullWidth={true}
              pending={formik.isSubmitting || isLoading}
            >
              Log In
            </Button>

            <Columns>
              <Column>
                <Link
                  type="button"
                  component="button"
                  align="center"
                  color="primary"
                  onClick={() => {
                    onOpenForgotPassword();
                    trackLoginEvent({ name: 'CTMS: Clicked Forgot Password' });
                  }}
                >
                  Forgot password?
                </Link>
              </Column>

              <Column width="content">
                <Link
                  type="button"
                  component="button"
                  align="center"
                  color="primary"
                  onClick={() => {
                    showNewIntercomMessage();
                    trackLoginEvent({ name: 'CTMS: Clicked Contact Support' });
                  }}
                >
                  Contact support
                </Link>
              </Column>
            </Columns>
          </Stack>
        </Form>
      </LoginLayoutContent>

      <Typography align="center" color="textSecondary">
        By signing in, you agree to Super Dispatch’s <br />
        <Link href="https://superdispatch.com/terms-of-service">
          Terms &amp; Conditions
        </Link>{' '}
        and{' '}
        <Link href="https://superdispatch.com/privacy-policy/">
          Privacy Policy
        </Link>
      </Typography>
    </FormikProvider>
  );
}
