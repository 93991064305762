import {
  mdiAccountOff,
  mdiArrowLeftRight,
  mdiArrowRightCircle,
  mdiCalendarMonth,
  mdiCarEstate,
  mdiCarHatchback,
  mdiCarPickup,
  mdiCash,
  mdiCashFast,
  mdiCheckDecagram,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiCloseCircleOutline,
  mdiContentCopy,
  mdiPound,
  mdiRvTruck,
  mdiShieldCar,
  mdiTextBox,
  mdiTowTruck,
  mdiVanUtility,
} from '@mdi/js';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const AccountOffIcon = createSvgIcon('AccountOff', mdiAccountOff);
export const CalendarMonthIcon = createSvgIcon(
  'CalendarMonth',
  mdiCalendarMonth,
);
export const CashIcon = createSvgIcon('Cash', mdiCash);
export const CashFastIcon = createSvgIcon('CashFast', mdiCashFast);
export const ContentCopyIcon = createSvgIcon('Copy', mdiContentCopy);
export const PoundIcon = createSvgIcon('Pound', mdiPound);
export const TowTruckIcon = createSvgIcon('TowTruck', mdiTowTruck);
export const TextBoxIcon = createSvgIcon('TextBox', mdiTextBox);
export const ShieldCarIcon = createSvgIcon('ShieldCarIcon', mdiShieldCar);
export const LengthIcon = createSvgIcon('Length', mdiArrowLeftRight);
export const ArrowRightCircledIcon = createSvgIcon(
  'ArrowRightCircled',
  mdiArrowRightCircle,
);
export const SedanIcon = createSvgIcon('Sedan', mdiCarHatchback);
export const PickupIcon = createSvgIcon('Pickup', mdiCarPickup);
export const SUVIcon = createSvgIcon('SUV', mdiCarEstate);
export const VanIcon = createSvgIcon('Van', mdiVanUtility);
export const RVIcon = createSvgIcon('RV', mdiRvTruck);
export const CloseCircleIcon = createSvgIcon(
  'CloseCircle',
  mdiCloseCircleOutline,
);
export const CheckDecagramIcon = createSvgIcon(
  'CheckDecagram',
  mdiCheckDecagram,
);
export const ChevronDoubleLeftIcon = createSvgIcon(
  'ChevronDoubleLeft',
  mdiChevronDoubleLeft,
);
export const ChevronDoubleRightIcon = createSvgIcon(
  'ChevronDoubleRight',
  mdiChevronDoubleRight,
);
