import { trackTripsEvent } from '../data/TripsAnalytics';
import { useAddTripLoad, useRemoveTripLoad } from '../data/TripsAPI';
import {
  TripFormLoadListItem,
  TripFormLoadListItemProps,
} from './TripFormLoadListItem';

export interface TripFormLoadListItemFormEditProps
  extends TripFormLoadListItemProps {
  tripGuid: string;
  driverGuid?: string;
}

export function TripFormLoadListItemFormEdit({
  tripGuid,
  driverGuid,
  ...props
}: TripFormLoadListItemFormEditProps) {
  const addLoad = useAddTripLoad(tripGuid, {
    onSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Added Load to Trip',
        trip_guid: tripGuid,
      });
    },
  });
  const removeLoad = useRemoveTripLoad(tripGuid, {
    onSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Removed Load from Trip',
        trip_guid: tripGuid,
      });
    },
  });

  return (
    <TripFormLoadListItem
      {...props}
      onSelect={(load) => {
        addLoad.mutate(load.guid);
      }}
      onDeselect={(load) => {
        removeLoad.mutate(load.guid);
      }}
      isLoading={addLoad.isLoading || removeLoad.isLoading}
    />
  );
}
