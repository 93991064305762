import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIError } from 'shared/api/APIError';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import {
  useAuthAPI,
  useResendVerificationCode,
  VerificationResponse,
} from 'shared/auth/data/AuthAPI';
import { verifyAccountSchema } from 'shared/auth/data/AuthDTO';
import { useAppFormik } from 'shared/form/AppFormik';
import { Countdown } from 'shared/ui/Countdown';
import { ResendVerification } from './core/ResendVerification';
import { useVerification } from './core/VerificationStorage';
import { setAuthTokenLocally } from './data/AuthUtils';
import { useSubscriptionFetchExpirement } from './SubscriptionExpirement';

export const PERMISSION_DENIED = 'PermissionDenied';
export const VERIFICATION_ACCESS_DENIED = 'VerificationAccessDenied';

export function VerifyAccountPage() {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const {
    email,
    verificationChannel,
    phoneNumber,
    saveVerificationCodeChannel,
  } = useVerification();
  const { checkVerificationCode } = useAuthAPI();
  const { handleFetchExperiment } = useSubscriptionFetchExpirement();
  const [retryDate, setRetryDate] = useState<DateTime>(() =>
    DateTime.local().plus({ seconds: 20 }),
  );

  const [permissionDeniedError, setPermissionDeniedError] =
    useState<APIError | null>(null);

  const { mutate: resendVerificationCode, isLoading } =
    useResendVerificationCode({
      onSuccess: (response) => {
        saveVerificationCodeChannel(
          verificationChannel === 'sms' ? 'email' : 'sms',
        );
        handleResendVerification();
        addSnackbar(response.user_message, { variant: 'success' });
      },
      onError: (error: APIError) => {
        if (
          error.type === PERMISSION_DENIED ||
          error.type === VERIFICATION_ACCESS_DENIED
        ) {
          setPermissionDeniedError(error);
        } else {
          addSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const formik = useAppFormik({
    enableReinitialize: false,
    initialValues: { code: '', email },
    validationSchema: verifyAccountSchema,
    onSubmit(values) {
      return checkVerificationCode({
        ...values,
        channel: verificationChannel || '',
      });
    },
    onSubmitSuccess: (response: VerificationResponse) => {
      setAuthTokenLocally(response.data.token);

      handleFetchExperiment();
    },
    onSubmitFailure: (error: APIError) => {
      if (
        error.type === PERMISSION_DENIED ||
        error.type === VERIFICATION_ACCESS_DENIED
      ) {
        setPermissionDeniedError(error);
      } else {
        addSnackbar(error.message, { variant: 'error' });
      }
    },
    getErrorMessage: '',
  });

  const handleChangeChannel = () => {
    resendVerificationCode({
      email: formik.values.email || '',
      channel: verificationChannel === 'email' ? 'sms' : 'email',
    });
  };

  function handleResendVerification() {
    setRetryDate(DateTime.local().plus({ seconds: 22 }));
  }

  const onOpenLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (!email) {
      onOpenLogin();
    }
  }, [email, onOpenLogin]);

  return (
    <>
      <Dialog maxWidth="xs" open={!!permissionDeniedError}>
        <DialogTitle>Log In Again</DialogTitle>
        <DialogContent>
          <Typography>{permissionDeniedError?.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPermissionDeniedError(null);
              onOpenLogin();
            }}
          >
            Back to Log in
          </Button>
        </DialogActions>
      </Dialog>

      <LoginLayoutContent>
        <FormikProvider value={formik}>
          <Form aria-label="verify">
            <Stack space="large">
              <Stack space="small">
                <Inline space="xsmall" verticalAlign="center">
                  <IconButton
                    edge="start"
                    onClick={onOpenLogin}
                    aria-label="go back"
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h2">
                    Check your{' '}
                    {verificationChannel === 'sms' ? 'messages!' : 'inbox!'}
                  </Typography>
                </Inline>

                <Stack space="none">
                  <Typography>
                    We&#8217;ve sent you a temporary 6-digit login code at{' '}
                  </Typography>
                  <Typography>
                    <b>
                      {verificationChannel === 'sms' ? phoneNumber : email}.
                    </b>{' '}
                    Please enter this code to login to your account.
                  </Typography>
                </Stack>
              </Stack>

              <FormikTextField
                label="Verification Code"
                name="code"
                fullWidth={true}
                autoFocus={true}
              />
              <Countdown targetDate={retryDate}>
                {(timeLeft, isElapsed) => (
                  <Stack space="large">
                    <ResendVerification
                      email={formik.values.email || ''}
                      onError={setPermissionDeniedError}
                      isElapsed={isElapsed}
                      timeLeft={timeLeft}
                      onResendVerificationCode={handleResendVerification}
                    />

                    <Stack space="small">
                      <Button
                        type="submit"
                        size="large"
                        fullWidth={true}
                        disabled={!formik.dirty}
                        pending={formik.isSubmitting}
                      >
                        Log In
                      </Button>

                      {!!phoneNumber && (
                        <Button
                          fullWidth={true}
                          variant="text"
                          onClick={handleChangeChannel}
                          disabled={
                            formik.isSubmitting || isLoading || !isElapsed
                          }
                        >
                          {verificationChannel !== 'email'
                            ? 'Receive Code via Email'
                            : 'Receive Code via SMS(Text Message)'}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Countdown>

              <Alert severity="info">
                <Stack space="xsmall">
                  <TextBox color="primary">
                    Two-Factor Authentication (2FA) is now required to log in to
                    Super Dispatch.
                  </TextBox>
                  <Link
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.superdispatch.com/en/articles/8892077-security-2-factor-authentication"
                  >
                    Learn more
                  </Link>
                </Stack>
              </Alert>
            </Stack>
          </Form>
        </FormikProvider>
      </LoginLayoutContent>
    </>
  );
}
