import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'shared/layout/PageLayout';
import { LoadStage } from '../loads/data/LoadDTO';
import { useLoadsPageContext } from '../loads/loads-page/data/LoadsPageParamsContext';
import { LoadsList } from '../loads/LoadsList';

export function ReportsLoadsPage() {
  const { params } = useLoadsPageContext();
  const navigate = useNavigate();

  return (
    <LoadsList
      pageHeader={
        <PageHeader
          title={pageTitle(params.stage)}
          startAction={
            <IconButton
              edge="start"
              size="small"
              aria-label="go back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
          }
        />
      }
    />
  );
}

function pageTitle(stage: LoadStage): string {
  switch (stage) {
    case 'receivable':
      return 'Accounts Receivable Loads';
    case 'receivable/past-due':
      return 'Accounts Receivable Loads (Payment Past Due)';
    case 'payable':
      return 'Accounts Payable Loads';
    case 'revenue':
      return 'Company Revenue Loads';
    default:
      return 'Loads';
  }
}
