import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSuperPaySettings } from '../../data/SuperPayAPI';

export function useVerificationAvailable(fallBackUrl: string) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: superPaySettings } = useSuperPaySettings();

  const isReadyForVerification = useMemo(() => {
    const [bankAccount = null] = superPaySettings?.bank_accounts ?? [];
    return (
      bankAccount?.verification_status === 'pending' &&
      bankAccount.is_verification_available
    );
  }, [superPaySettings]);

  useEffect(() => {
    if (!isReadyForVerification) {
      navigate(
        {
          pathname: fallBackUrl,
          search,
        },
        { replace: true },
      );
    }
  }, [isReadyForVerification, search, fallBackUrl, navigate]);
}
