import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuperPayIcon } from 'shared/icons/SuperPayLabel';
import { SuperPayRequestShipperDialog } from 'shared/modules/superpay/SuperPayRequestShipperDialog';
import { SuperPayRequestShipperGradientBox } from 'shared/modules/superpay/SuperPayRequestShipperGradientBox';
import { SUPERPAY_WEBVIEW_ROUTES } from '../../../carrier/profile/superpay/SuperPayRoute';

function SuperPayRequestShipperBannerContent({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      color="inherit"
      href="#"
    >
      <SuperPayRequestShipperGradientBox>
        <Columns align="center">
          <Column>
            <Inline verticalAlign="center" space="xxsmall">
              <Typography>Request</Typography>
              <SuperPayIcon />
              <Typography>from Shippers</Typography>
            </Inline>
          </Column>
          <Column width="content">
            <IconButton size="small" edge="start" onClick={onClick}>
              <ArrowForwardIos fontSize="small" color="action" />
            </IconButton>
          </Column>
        </Columns>
      </SuperPayRequestShipperGradientBox>
    </Link>
  );
}

export function SuperPayRequestShipperBanner() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <>
      <SuperPayRequestShipperBannerContent
        onClick={() => {
          setIsOpenDialog(true);
        }}
      />
      <SuperPayRequestShipperDialog
        source="SuperPay Settings"
        onClose={() => {
          setIsOpenDialog(false);
        }}
        isOpen={isOpenDialog}
      />
    </>
  );
}

export function SuperPayRequestShipperWebViewBanner() {
  const navigate = useNavigate();

  return (
    <SuperPayRequestShipperBannerContent
      onClick={() => {
        navigate(
          `${SUPERPAY_WEBVIEW_ROUTES.requestShipper}?back_url=${SUPERPAY_WEBVIEW_ROUTES.accountDetails}&utm_medium=SuperPay Settings`,
        );
      }}
    />
  );
}
