import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Color, Inline, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useSetAtom } from 'jotai';
import React, { lazy, useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useReorderTripLoads } from '../data/TripsAPI';
import { TripDTO, TripLoadDTO } from '../data/TripsDTO';
import { hoveredFieldNameAtom } from '../TripsDetails';
import { StyledCell, TripDetailsTableRow } from './TripDetailsTableRow';
import { TripDetailsStopPointRow } from './TripDetailsTableStopPointRow';

const StyledTableContainer = styled(TableContainer)`
  overflow-y: auto;
  max-height: calc(100vh - 100px);

  .MuiTableHead-root {
    position: sticky;
    top: 0;
    z-index: 999;
  }
`;

const DragDropContext = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.DragDropContext,
  })),
);

const Draggable = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.Draggable,
  })),
);

const Droppable = lazy(() =>
  import('react-beautiful-dnd').then((module) => ({
    default: module.Droppable,
  })),
);

const StickyTableRow = styled(TableRow)`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  & th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: React.CSSProperties,
) => {
  if (draggableStyle?.transform) {
    const [, y] = draggableStyle.transform.match(/-?\d{1,}/g) || [];

    return {
      ...draggableStyle,
      transform: `translateY(${y}px)`,
      boxShadow: isDragging ? `0 2px 10px 0 ${Color.Black20}` : undefined,
    };
  }

  return draggableStyle;
};

function arrayMove<T>(array: T[], from: number, to: number) {
  const newArray = Array.from(array);
  newArray.splice(to, 0, ...newArray.splice(from, 1));

  return newArray;
}

interface TripLoadsTableProps {
  tripGUID: string;
  loads: TripLoadDTO[];
  route: TripDTO['route'];
  isTripArchived: boolean;
  path: string;
  onEditRoute: () => void;
}

export function TripDetailsTable({
  tripGUID,
  loads,
  route,
  isTripArchived,
  onEditRoute,
}: TripLoadsTableProps) {
  const { addSnackbar } = useSnackbarStack();
  const [sortedLoads, setSortedLoads] = useState(loads);
  const reorderLoads = useReorderTripLoads(tripGUID);
  const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);

  const handleSortEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index !== source.index) {
      const nextSortedLoads = arrayMove(
        sortedLoads,
        source.index,
        destination.index,
      ).map((load, i) => ({
        ...load,
        load_order: i,
      }));

      setSortedLoads(nextSortedLoads);

      reorderLoads.mutate(
        nextSortedLoads.map(({ guid, load_order }) => ({
          load_guid: guid,
          load_order,
        })),
        {
          onSuccess() {
            addSnackbar("Load's order was successfully updated", {
              variant: 'success',
            });
          },
          onError() {
            setSortedLoads(loads);
            addSnackbar("Error occurred while updating the load's order", {
              variant: 'error',
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    setSortedLoads(loads);
  }, [loads]);

  return (
    <StyledTableContainer>
      <Box padding="small" backgroundColor="White">
        <Inline space="small">
          <TextBox variant="heading-3">Route</TextBox>
          <Button variant="text" startIcon={<Edit />} onClick={onEditRoute}>
            Edit Route
          </Button>
        </Inline>
      </Box>
      <Table size="small" stickyHeader={true}>
        <TableHead>
          <StickyTableRow>
            <StyledCell />
            <StyledCell>
              <Typography variant="h5" color="textSecondary">
                Load
              </Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5" color="textSecondary">
                Origin
              </Typography>
            </StyledCell>
            <StyledCell>
              <Typography variant="h5" color="textSecondary">
                Destination
              </Typography>
            </StyledCell>
            <StyledCell customWidth="250px">
              <Typography variant="h5" color="textSecondary">
                Vehicles
              </Typography>
            </StyledCell>
            <StyledCell customWidth="120px">
              <Typography variant="h5" color="textSecondary">
                Pickup Date
              </Typography>
            </StyledCell>
            <StyledCell customWidth="120px">
              <Typography variant="h5" color="textSecondary">
                Delivery Date
              </Typography>
            </StyledCell>
            <StyledCell customWidth="120px">
              <Typography variant="h5" color="textSecondary">
                Customer
              </Typography>
            </StyledCell>
            {!isTripArchived && <StyledCell customWidth="60px" />}
          </StickyTableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleSortEnd}>
          <Droppable droppableId="TripLoadsTable">
            {({ droppableProps, innerRef: droppableRef, placeholder }) => (
              <TableBody {...droppableProps} ref={droppableRef}>
                {route?.origin && (
                  <TripDetailsStopPointRow
                    origin={route.origin}
                    isTripArchived={isTripArchived}
                    position="start"
                    onMouseLeave={() => {
                      setHoveredFieldName('');
                    }}
                    onMouseOver={() => {
                      setHoveredFieldName('start_address');
                    }}
                  />
                )}
                {sortedLoads.map((load, index) => (
                  <Draggable
                    isDragDisabled={isTripArchived}
                    draggableId={`TripLoadsTable-${load.guid}`}
                    index={load.load_order ?? 0}
                    key={load.guid}
                  >
                    {(
                      { dragHandleProps, draggableProps, innerRef },
                      snapshot,
                    ) => (
                      <TripDetailsTableRow
                        {...draggableProps}
                        {...dragHandleProps}
                        ref={innerRef}
                        load={load}
                        isOrigin={index === 0 && !route?.origin}
                        isDestination={
                          index === sortedLoads.length - 1 &&
                          !route?.destination
                        }
                        tripGUID={tripGUID}
                        isTripArchived={isTripArchived}
                        onMouseLeave={() => {
                          setHoveredFieldName('');
                        }}
                        onMouseOver={() => {
                          setHoveredFieldName(`loads[${index}]`);
                        }}
                        // eslint-disable-next-line react/forbid-component-props
                        style={getItemStyle(
                          snapshot.isDragging,
                          draggableProps.style,
                        )}
                      />
                    )}
                  </Draggable>
                ))}
                {placeholder}

                {route?.destination && (
                  <TripDetailsStopPointRow
                    destination={route.destination}
                    isTripArchived={isTripArchived}
                    position="end"
                    onMouseLeave={() => {
                      setHoveredFieldName('');
                    }}
                    onMouseOver={() => {
                      setHoveredFieldName('end_address');
                    }}
                  />
                )}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </StyledTableContainer>
  );
}
