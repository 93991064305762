import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DriverDTO } from '../data/DriverDTO';
import { trackDriversEvent } from '../data/DriversAnalytics';
import { useCancelDriverInvite } from '../data/DriversAPI';

interface DriverInviteCancelDialogProps {
  open: boolean;
  onClose: () => void;
  driver: DriverDTO;
}

const OverflowBox = styled(Box)`
  word-break: break-all;
  overflow-x: hidden;
`;

export function DriverInviteCancelDialog({
  driver,
  open,
  onClose,
}: DriverInviteCancelDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const { mutate: cancelInvite, isLoading } = useCancelDriverInvite({
    onSuccess: () => {
      trackDriversEvent({
        name: 'Carrier Canceled Driver Invite',
        driverGuid: driver.guid,
      });
      addSnackbar('Invite canceled');
      navigate('/drivers', { replace: true });

      onClose();
    },
  });

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>Cancel Invite</DialogTitle>
      <DialogContent>
        <OverflowBox>
          <Typography>
            Do you want to cancel invite to {driver.email}?
          </Typography>
        </OverflowBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} variant="text">
          No
        </Button>
        <Button
          onClick={() => {
            cancelInvite(driver.guid);
          }}
          pending={isLoading}
          variant="critical"
        >
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
