import { InputAdornment, Typography } from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { Ref } from 'react';
import { InputClearButton } from 'shared/ui/InputClearButton';
import { DispatcherField } from '../../dispatchers/core/DispatcherField';
import { DriverField } from '../../drivers/core/DriverField';

const filterNameMap = {
  driver_guid: 'Drivers',
  dispatcher_guid: 'Dispatchers',
};

export interface LoadsPageFilterResourceFieldProps {
  name: 'driver_guid' | 'dispatcher_guid';
  inputRef: Ref<HTMLInputElement>;
  value?: string;
  onChange: (value?: string) => void;
}

export function TripsPageFilter({
  name,
  value,
  onChange,
  inputRef,
}: LoadsPageFilterResourceFieldProps) {
  const uid = useUID();
  const FilterAutocomplete =
    name === 'driver_guid' ? DriverField : DispatcherField;

  return (
    <FilterAutocomplete
      id={uid}
      value={value}
      forcePopupIcon={false}
      disableClearable={true}
      onChange={onChange}
      TextFieldProps={{
        inputRef,
        placeholder: 'Search and select',
        InputProps: {
          startAdornment: (
            <InputAdornment position="start" component="label" htmlFor={uid}>
              <Typography variant="body1" color="textSecondary">
                {filterNameMap[name]}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {!!value && (
                <InputClearButton
                  aria-label="autocomplete clear button"
                  onClick={(event) => {
                    event.stopPropagation();
                    onChange(undefined);
                  }}
                />
              )}
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
