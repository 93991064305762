import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import { useCancelDispatcherInvite } from '../data/DispatchersAPI';

interface DispatcherInviteCancelDialogProps {
  dispatcher: DispatcherDTO;
  open: boolean;
  onClose: () => void;
}

export function DispatcherInviteCancelDialog({
  dispatcher,
  open,
  onClose,
}: DispatcherInviteCancelDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const { mutate: cancelInvite, isLoading } = useCancelDispatcherInvite({
    onSuccess: () => {
      addSnackbar('Invite canceled');
      onClose();
      navigate('/dispatchers', { replace: true });
    },
  });

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>Cancel Invite</DialogTitle>
      <DialogContent>
        <Typography>
          Do you want to cancel invite to {dispatcher.email}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} variant="text">
          No
        </Button>
        <Button
          onClick={() => {
            cancelInvite(dispatcher.guid);
          }}
          pending={isLoading}
          variant="critical"
        >
          Yes, Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
