import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { ArrowForward, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useUID,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import React, { ReactNode } from 'react';
import { LinkAnchor } from 'shared/routing/Links';
import styled from 'styled-components';
import { LoadCountType, useDashboardLoadsCount } from './DashboardAPI';

const DashboardViewLoadBox = styled(Box)``;

const DashboardLinkAnchor = styled(LinkAnchor)`
  &:hover {
    ${DashboardViewLoadBox} {
      color: ${Color.Blue300};
    }
  }
`;

interface DashboardCardContainerProps {
  hasLink: boolean;
  children?: ReactNode;
  uid: string;
  linkTo: string;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export function DashboardCardContainer({
  hasLink,
  children,
  uid,
  linkTo,
  onLinkClick,
}: DashboardCardContainerProps) {
  return (
    <Box aria-labelledby={uid}>
      {hasLink ? (
        <DashboardLinkAnchor to={linkTo} onClick={onLinkClick}>
          {children}
        </DashboardLinkAnchor>
      ) : (
        children
      )}
    </Box>
  );
}

interface DashboardCardProps {
  title: string;
  loadCountType: LoadCountType;
  linkTo: string;
  tooltipText: string;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export function DashboardCard({
  title,
  loadCountType,
  linkTo,
  tooltipText,
  onLinkClick,
}: DashboardCardProps) {
  const uid = useUID();
  const { data } = useDashboardLoadsCount(loadCountType);
  const hasLink = data != null && data.count > 0;

  return (
    <DashboardCardContainer
      hasLink={hasLink}
      uid={uid}
      linkTo={linkTo}
      onLinkClick={onLinkClick}
    >
      <Card>
        <CardContent>
          <Stack space="small">
            <Inline space="xsmall" verticalAlign="center">
              <Typography variant="h4" id={uid}>
                {title}
              </Typography>

              <Tooltip placement="right" title={tooltipText}>
                <Info color="action" fontSize="small" />
              </Tooltip>
            </Inline>

            <Columns align="bottom">
              <Column width="fluid">
                {data == null ? (
                  <Skeleton height={28} />
                ) : (
                  <Typography variant="h1" aria-label={`${title} count`}>
                    {data.count}
                  </Typography>
                )}
              </Column>

              {hasLink && (
                <Column width="content">
                  <DashboardViewLoadBox>
                    <Inline verticalAlign="center">
                      <Typography variant="subtitle2">View Loads</Typography>
                      <ArrowForward fontSize="small" />
                    </Inline>
                  </DashboardViewLoadBox>
                </Column>
              )}
            </Columns>
          </Stack>
        </CardContent>
      </Card>
    </DashboardCardContainer>
  );
}
