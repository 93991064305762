import {
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Column, Columns, Stack, useUID } from '@superdispatch/ui';
import { startCase } from 'lodash-es';
import { InlineDateRangeField } from 'shared/form/InlineDateRangeField';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useLocationParams } from 'shared/routing/LocationParams';
import { ReportsNotAvailable } from './core/ReportNotAvailbale';
import { ReportsCard, ReportsCardItem } from './core/ReportsCard';
import { ReportsPageHeader } from './core/ReportsPageHeader';
import { useCompanyRevenueReports } from './data/ReportsAPI';
import { useReportsContext } from './data/ReportsContext';
import {
  ACCOUNTING_METHOD,
  reportsCompanyRevenueParamsSchema,
} from './data/ReportsDTO';
import { ReportsLoadsLink } from './ReportsLoadsLink';

export function ReportsCompanyRevenuePage() {
  const uid = useUID();
  const [params, setParams] = useLocationParams({
    yupSchema: reportsCompanyRevenueParamsSchema,
    persistName: 'ReportsCompanyRevenue',
  });
  const { accounting_method, start_date, end_date } = params;
  const { data, isLoading, error } = useCompanyRevenueReports(params);
  const { isAvailable } = useReportsContext();

  useErrorSnackbar(error);

  return (
    <>
      <DocumentTitle title="Company Revenue - Reports" />

      <PageLayout
        loading={isLoading}
        stickyHeader={true}
        header={
          <ReportsPageHeader
            title="Company Revenue"
            helpLink="https://support.superdispatch.com/everything-dispatch-dashboard/company-revenue-billing-report"
          />
        }
      >
        {isAvailable ? (
          <Stack space="small">
            <TextField
              id="accountingMethod"
              select={true}
              value={accounting_method}
              onChange={({ target: { value } }) => {
                setParams({ accounting_method: value });
              }}
              InputProps={{
                'aria-labelledby': uid,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography color="textSecondary" id={uid}>
                      Accounting method:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            >
              {ACCOUNTING_METHOD.map((x) => (
                <MenuItem key={x} value={x}>
                  {startCase(x)}
                </MenuItem>
              ))}
            </TextField>

            <InlineDateRangeField
              label="Choose Period"
              value={[start_date, end_date]}
              onChange={({ stringValue: [start, finish] }) => {
                setParams({ start_date: start, end_date: finish });
              }}
            />

            <Columns space="small" collapseBelow="desktop">
              <Column width={['fluid', 'fluid', '3/4']}>
                <TableContainer component={Paper}>
                  <Table aria-label="Accounts Receivable Table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Load Count</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.shippers?.length === 0 && (
                        <TableRow>
                          <TableCell align="center" size="medium" colSpan={3}>
                            <Typography color="textSecondary">
                              There are no company revenue data
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {data?.shippers?.map((row) => (
                        <TableRow key={row.company_name}>
                          <TableCell component="th" scope="row">
                            {row.company_name}
                          </TableCell>

                          <TableCell>
                            <Typography gutterBottom={true}>
                              {row.order_count}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography gutterBottom={true}>
                              {formatCurrency(row.revenue)}
                            </Typography>

                            <ReportsLoadsLink
                              stage="revenue"
                              companyName={row.company_name}
                              params={params}
                            >
                              View Loads
                            </ReportsLoadsLink>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Column>

              <Column width={['fluid', 'fluid', '1/4']}>
                <ReportsCard title="Total">
                  <ReportsCardItem
                    label="Company Revenue:"
                    value={data?.total}
                  />
                </ReportsCard>
              </Column>
            </Columns>
          </Stack>
        ) : (
          <ReportsNotAvailable />
        )}
      </PageLayout>
    </>
  );
}
