import { MenuItem } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { yupEnum, yupObject, yupString } from 'shared/utils/YupUtils';
import { useCreateCarrierMoovAccount } from '../../data/MoovAPI';
import {
  MoovActionError,
  MOOV_BUSINESS_TYPES,
  MOOV_BUSINESS_TYPE_DISPAY_NAMES,
} from '../../data/MoovDTO';
import { trackSuperPayEvent } from '../../data/SuperPayAnalytics';
import { logPaymentInfo } from '../PaymentLogger';

const ALPANUMERIC_SPECIAL_CHARACTERS_WITH_SPACE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9&()\[\]"',.-]+(\s[a-zA-Z0-9&()\[\]"',.-]+)*$/gm;

const businessDetailsValidationSchema = yupObject({
  legalBusinessName: yupString()
    .max(64)
    .matches(
      ALPANUMERIC_SPECIAL_CHARACTERS_WITH_SPACE_REGEX,
      'Only english letters and numbers are allowed',
    )
    .required(),
  businessType: yupEnum(MOOV_BUSINESS_TYPES).required(),
  einNumber: yupString()
    .test('numbers only', 'Invalid EIN', hasOnlyDigits)
    .length(9)
    .required(),
});

const MOOV_ACCOUNT_DESCRIPTION =
  'Transportation services for shippers and brokers.';

interface Props {
  onComplete: () => void;
  onSettled: () => void;
}

interface FormProps {
  disabled?: boolean;
}

export function useBusinessDetailsForm({ onComplete, onSettled }: Props) {
  const { data: settings } = useCarrierSettings();
  const { mutateAsync: createCarrierMoovAccount } =
    useCreateCarrierMoovAccount();

  return useAppFormik({
    initialValues: { legalBusinessName: settings?.carrier.name },
    validationSchema: businessDetailsValidationSchema,
    onSubmit: ({ businessType, legalBusinessName, einNumber }) => {
      if (!settings) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }

      return createCarrierMoovAccount({
        businessType,
        legalBusinessName,
        carrier_guid: settings.carrier.guid,
        description: MOOV_ACCOUNT_DESCRIPTION,
        einNumber,
      });
    },
    onSubmitSuccess: () => {
      trackSuperPayEvent({
        name: 'Carrier Submitted Business Details',
      });

      logPaymentInfo(
        'Carrier Submitted Business Details',
        'BusinessDetailsForm',
      );
      onComplete();
      onSettled();
    },
    getErrorMessage: (error: MoovActionError) => {
      if (error.statusCode === 403) {
        return 'Not authorized to create business account.';
      }

      return `Failed to submit the form. ${error.message}`;
    },
    onSubmitFailure: () => {
      onSettled();
    },
  });
}

export function BusinessDetailsForm({ disabled }: FormProps) {
  return (
    <Stack space="small">
      <FormikTextField
        label="Legal Business Name"
        name="legalBusinessName"
        fullWidth={true}
        disabled={disabled}
      />

      <FormikTextField
        label="Business Type"
        name="businessType"
        placeholder="Select option"
        select={true}
        fullWidth={true}
        disabled={disabled}
      >
        {MOOV_BUSINESS_TYPES.map((businessType) => (
          <MenuItem key={businessType} value={businessType}>
            {MOOV_BUSINESS_TYPE_DISPAY_NAMES[businessType]}
          </MenuItem>
        ))}
      </FormikTextField>

      <FormikTextField
        label="Employer Identification Number (EIN)"
        name="einNumber"
        fullWidth={true}
        helperText="9 digits. You can find your business’s EIN on tax returns, credit reports, payroll paperwork, or licenses and permits."
        disabled={disabled}
      />
    </Stack>
  );
}
