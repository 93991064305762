import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { Prompt } from 'shared/routing/NavigationBlock';

export function useBlockFormChanges<T, TResponse>(
  formik: FormikContextTypeEnhanced<T, TResponse>,
) {
  return (
    <Prompt
      when={
        formik.status.type !== 'submitted' &&
        (formik.dirty || formik.isSubmitting)
      }
      message="Changes have not been saved. Leaving will result in unsaved changes being lost."
    />
  );
}
