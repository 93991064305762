import { Outlet } from 'react-router-dom';
import { AppVersionChecker } from '../app/AppVersionChecker';

export function RootOutlet() {
  return (
    <>
      {import.meta.env.MODE !== 'development' && <AppVersionChecker />}
      <Outlet />
    </>
  );
}
