import { useDeepEqualValue } from '@superdispatch/hooks';
import { useEffect } from 'react';
import { updateAuthToken } from 'shared/auth/AuthToken';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import { identifyErrorTrackerUser } from 'shared/helpers/ErrorTracker';
import { hideIntercomLauncher } from 'shared/helpers/Intercom';
import { ErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useExperiments } from 'shared/modules/experiments/ExperimentsAPI';
import { trackPublicOfferEvent } from '../data/PublicOfferAnalytics';
import { usePublicOfferPageContext } from '../PublicOfferPageContext';
import { PublicOfferAcceptedPage } from './PublicOfferAcceptedPage';
import { PublicOfferCanceledPage } from './PublicOfferCanceledPage';
import { PublicOfferDeclinedPage } from './PublicOfferDeclinedPage';
import { PublicOfferPendingPage } from './PublicOfferPendingPage';

export function PublicOfferPage() {
  const {
    publicOffer: { data, error },
  } = usePublicOfferPageContext();

  const isLoaded = !!data;
  const user = useDeepEqualValue(data?.user);
  const { data: experiment } = useExperiments(user?.token);

  useEffect(() => {
    if (isLoaded) {
      if (user) {
        const token = data.user?.token;
        identifyErrorTrackerUser({
          id: user.guid,
          carrier_guid: data.carrier_guid,
        });
        identifyAnalytics({
          guid: user.guid,
          hash: user.hash,
          accountType: user.company_type,
          experiment_group: experiment?.groups.map((e) => e.number),
          company: {
            id: data.carrier_guid,
            guid: data.carrier_guid,
          },
        });
        if (token) {
          updateAuthToken(token);
        }
      } else {
        identifyAnalytics();
      }

      trackPublicOfferEvent({ name: 'CTMS: Viewed Load Offer' });
    }
  }, [user, isLoaded, experiment, data?.carrier_guid, data?.user?.token]);

  useEffect(() => {
    hideIntercomLauncher();
  }, []);

  if (error) return <ErrorSnackbar error={error} />;
  if (!data) return <SplashScreen />;

  switch (data.status) {
    case 'accepted':
      return <PublicOfferAcceptedPage offer={data} />;
    case 'pending':
      return <PublicOfferPendingPage offer={data} />;
    case 'declined':
      return <PublicOfferDeclinedPage offer={data} />;
    case 'canceled':
      return <PublicOfferCanceledPage offer={data} />;
    default:
      return null;
  }
}
