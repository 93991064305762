import { SidebarContainer } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { ReportsSidebar } from './core/ReportsSidebar';
import { ReportsContextProvider } from './data/ReportsContext';
import { ReportsAccountsPayablePage } from './ReportsAccountsPayable';
import { ReportsAccountsReceivablePage } from './ReportsAccountsReceivablePage';
import { ReportsCompanyRevenuePage } from './ReportsCompanyRevenuePage';
import { ReportsCustomPage } from './ReportsCustomPage';
import { ReportsDriverPayPage } from './ReportsDriverPayPage';
import { ReportsLoadsPage } from './ReportsLoadsPage';

interface ReportsPageRouteProps {
  children: ReactElement;
}

function ReportsPageRoute({ children }: ReportsPageRouteProps) {
  return (
    <SidebarContainer sidebar={<ReportsSidebar />}>{children}</SidebarContainer>
  );
}

export function ReportsContainerOutlet() {
  return (
    <LoadsContextProvider>
      <LoadsPageContextProvider>
        <ReportsContextProvider>
          <Outlet />
        </ReportsContextProvider>
      </LoadsPageContextProvider>
    </LoadsContextProvider>
  );
}

export const reportsRoutes: RouteObject[] = [
  {
    path: 'reports/:page?',
    element: <ReportsContainerOutlet />,
    children: [
      {
        path: 'loads',
        element: <ReportsLoadsPage />,
      },
      {
        path: 'driver-pay',
        element: (
          <ReportsPageRoute>
            <ReportsDriverPayPage />
          </ReportsPageRoute>
        ),
      },
      {
        path: 'accounts-receivable',
        element: (
          <ReportsPageRoute>
            <ReportsAccountsReceivablePage />
          </ReportsPageRoute>
        ),
      },
      {
        path: 'company-revenue',
        element: (
          <ReportsPageRoute>
            <ReportsCompanyRevenuePage />
          </ReportsPageRoute>
        ),
      },
      {
        path: 'accounts-payable',
        element: (
          <ReportsPageRoute>
            <ReportsAccountsPayablePage />
          </ReportsPageRoute>
        ),
      },
      {
        path: 'custom-report/:reportGUID?/:action?',
        element: (
          <ReportsPageRoute>
            <ReportsCustomPage />
          </ReportsPageRoute>
        ),
      },
      {
        index: true,
        element: <Navigate to="/reports/accounts-receivable" />,
      },
    ],
  },
];
