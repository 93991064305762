import { Box } from '@superdispatch/ui-lab';
import { atom, useAtomValue } from 'jotai';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  ZoomControl,
} from 'react-leaflet';
import styled from 'styled-components';

const Map = styled(MapContainer)`
  .leaflet-tile-pane {
    filter: grayscale(1) !important;
  }
  .leaflet-popup {
    margin-bottom: 10px !important;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none !important;
  }

  .leaflet-popup-content {
    margin: 8px !important;
  }

  .leaflet-popup-content p {
    margin: 0 !important;
  }

  .active-marker-icon {
    z-index: 999999 !important;
  }

  width: 100%;
  height: 100%;
`;

export interface MarkerProps {
  position: [number, number];
  iconUrl: string;
  popup?: React.ReactNode;
  name: string;
  type: 'pickup' | 'delivery';
  isActive?: boolean;
}

interface LeafletMapProps {
  center: [number, number];
  zoom: number;
  markers: MarkerProps[];
  setMap: (map: L.Map) => void;
  children?: React.ReactNode;
}

const ComponentResize = () => {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
};

export default function LeafletMap({
  center,
  zoom,
  markers,
  setMap,
  children,
}: LeafletMapProps) {
  return (
    <Box position="relative" width="100%" height="100%">
      <Map
        attributionControl={false}
        ref={setMap}
        center={center}
        zoom={zoom}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ComponentResize />
        {markers.map(({ position, iconUrl, popup, isActive }, index) => (
          <Marker
            key={index}
            icon={L.icon({
              iconUrl,
              className: isActive ? 'active-marker-icon' : '',
            })}
            position={position}
            eventHandlers={{
              mouseover: (event: L.LeafletMouseEvent) => {
                if (event.target instanceof L.Marker) {
                  event.target.openPopup();
                }
              },
              mouseout: (event: L.LeafletMouseEvent) => {
                if (event.target instanceof L.Marker) {
                  event.target.closePopup();
                }
              },
            }}
          >
            <Popup>{popup}</Popup>
          </Marker>
        ))}

        {children}
        <ZoomControl position="topright" />
      </Map>
    </Box>
  );
}

export const mapAtom = atom<L.Map | null>(null);
export function useMapValue() {
  return useAtomValue(mapAtom);
}
