import { useEffect } from 'react';
import { setupUserflow } from 'shared/helpers/Userflow';
import { useDispatcherProfileWithoutErrorBoundary } from 'shared/modules/dispatcher/DispatcherAPI';
import { useFlag } from 'shared/settings/FeatureToggles';

export function useStartUserFlow(isFetching: boolean) {
  const isUserflowEnabled = useFlag('enable_userflow');
  const { data: dispatcher } = useDispatcherProfileWithoutErrorBoundary();

  useEffect(() => {
    const isFeatureEnabled = isUserflowEnabled && !isFetching;

    if (dispatcher && isFeatureEnabled) {
      void setupUserflow({
        guid: dispatcher.guid,
        name: dispatcher.name,
        email: dispatcher.email,
        created_at: dispatcher.created_at,
      });
    }
  }, [dispatcher, isUserflowEnabled, isFetching]);
}
