import {
  IconButton,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { FormikValues, useField, useFormikContext } from 'formik';
import { forwardRef, useMemo, useState } from 'react';
import { QUARTER_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { CloseIcon } from 'shared/icons/CloseIcon';
import styled from 'styled-components';
import { ContactDTO } from '../../contacts/data/ContactDTO';
import { useContactsList } from '../../contacts/data/ContactsAPI';
import { OrdersDTO } from '../data/LoadDTO';

interface LoadFieldCommonProps {
  TextFieldProps?: StandardTextFieldProps;
}
interface BaseSytntheticEvent extends React.ChangeEvent {
  key: string;
}

const MultipleOrderFieldText = styled(TextField)`
  /* Inline input next to tags rather than next line on focus */
  width: 100%;
  margin-top: 8px;
  overflow: hidden;

  & .MuiAutocomplete-inputRoot {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    & .MuiAutocomplete-input {
      width: 100%;
    }
  }
`;

interface EmailFieldMultipleProps
  extends Omit<
    LoadFieldCommonProps,
    'options' | 'onChange' | 'getOptionLabel'
  > {
  name: string;
  onChange?: (
    value: OrdersDTO | undefined,
    reason: AutocompleteChangeReason,
  ) => void;
}

export const LoadEmailSelectionField = forwardRef(
  (
    { onChange, TextFieldProps, name, ...props }: EmailFieldMultipleProps,
    ref,
  ) => {
    const { setValues } = useFormikContext<FormikValues>();
    const [field, { touched, error }] = useField<ContactDTO[]>({ name });
    const [isEmailEnabled] = useField<boolean>({ name: 'email' });
    const [search, setSearch] = useState('');
    const query = useDebouncedValue(search, QUARTER_OF_A_SECOND);

    const errorMessage = touched && error;

    const { data, isFetching } = useContactsList(
      {
        q: query,
        page_size: 50,
      },
      {
        enabled: !!query,
      },
      true,
    );

    const emailOptions = useMemo(
      () => (data ? data.pages.flatMap((item) => item.data) : null),
      [data],
    );

    const options = useMemo(
      () =>
        emailOptions && emailOptions.length > 0
          ? emailOptions
          : [
              {
                name: '',
                email: query,
                address: '',
                city: '',
                contact: '',
                guid: '',
                notes: '',
                phone: '',
                state: '',
                zip: '',
              },
            ],
      [emailOptions, query],
    );

    const handleDelete = (index: number) => {
      const filteredOrders = field.value.filter(
        (_item, fieldIndex) => fieldIndex !== index,
      );
      void setValues((value) => ({ ...value, emails: filteredOrders }));
    };

    return (
      <Autocomplete
        {...props}
        options={options}
        ref={ref}
        multiple={true}
        freeSolo={true}
        filterSelectedOptions={true}
        disableClearable={true}
        disabled={!isEmailEnabled.value}
        value={field.value}
        loading={isFetching}
        aria-label="contact-list"
        getOptionLabel={(option) => option.email}
        getOptionSelected={(option, value) => option.email === value.email}
        filterOptions={(filterOptions) => {
          return filterOptions.filter((option) => option.email !== '');
        }}
        onChange={(event: BaseSytntheticEvent, selectedValue) => {
          const lastElement = selectedValue[selectedValue.length - 1];

          if (event.key === 'Backspace') {
            const removeLatesElement = field.value.filter(
              (_, index) => index < field.value.length - 1,
            );

            void setValues((value) => ({
              ...value,
              emails: removeLatesElement,
            }));
          } else if (event.key === 'Enter') {
            if (typeof lastElement === 'string') {
              void setValues((value) => ({
                ...value,
                emails: [
                  ...field.value,
                  { name: '', guid: '', email: lastElement },
                ],
              }));
            } else {
              void setValues((value) => ({
                ...value,
                emails: [...field.value, lastElement],
              }));
            }
          } else if (isContactDTO(lastElement)) {
            const emails = lastElement.email
              .split(',')
              .map((item) => item.replace(' ', ''));

            void setValues((value) => ({
              ...value,
              emails: [
                ...field.value,
                ...emails.map((email) => ({
                  email,
                  name: '',
                  guid: '',
                })),
              ],
            }));
          }
        }}
        renderTags={(value) => {
          return (
            <Inline space="xxsmall">
              {value.map((option, index) => {
                return (
                  <Tag
                    key={index}
                    color="grey"
                    variant="subtle"
                    id={option.guid}
                  >
                    <Inline space="xxsmall">
                      <TextBox wrapOverflow={true} color="primary">
                        {option.email}
                      </TextBox>

                      <IconButton
                        onClick={() => {
                          handleDelete(index);
                        }}
                        size="small"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Inline>
                  </Tag>
                );
              })}
            </Inline>
          );
        }}
        renderOption={(option) => (
          <Stack>
            <Inline space="xxsmall" verticalAlign="center">
              <Typography variant="h6">{option.name}</Typography>
              <Typography variant="body2">{option.email}</Typography>
            </Inline>
          </Stack>
        )}
        renderInput={(params) => (
          <MultipleOrderFieldText
            {...params}
            {...TextFieldProps}
            placeholder="Enter individual emails on each line or separate them with comma (,)"
            onChange={(event) => {
              const text = event.target.value.replace(/,/g, '');
              const hasCommaOrSpace = /,|\s/.test(event.target.value);

              setSearch(text);

              if (hasCommaOrSpace && text.trim() !== '') {
                void setValues((value) => ({
                  ...value,
                  emails: [
                    ...field.value,
                    { name: '', guid: '', email: text.trim() },
                  ],
                }));
              }
            }}
            multiline={true}
            minRows={2}
            InputProps={{
              ...TextFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: params.InputProps.startAdornment,
            }}
            variant="outlined"
            fullWidth={true}
            disabled={!isEmailEnabled.value}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        )}
      />
    );
  },
);

function isContactDTO(obj: any): obj is ContactDTO {
  return obj && typeof obj === 'object' && 'email' in obj;
}
