import { Menu } from '@material-ui/core';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDeleteSavedReport } from '../data/ReportsAPI';
import { ReportsCustomDTO } from '../data/ReportsDTO';

const Form = styled.form`
  padding: 8px 24px;
  min-width: 360px;
`;

interface ReportDeleteMenuProps {
  savedReport: ReportsCustomDTO;
  onCancel: () => void;
  onDeleteSuccess: () => void;
  deleteAnchorRef: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

export const ReportDeleteMenu = ({
  savedReport,
  onCancel,
  onDeleteSuccess,
  deleteAnchorRef,
  open,
  onClose,
}: ReportDeleteMenuProps) => {
  const navigate = useNavigate();
  const { guid } = useParams();
  const { addSnackbar } = useSnackbarStack();
  const { mutate, isLoading } = useDeleteSavedReport({
    onSuccess: () => {
      addSnackbar('Report Deleted', { variant: 'success' });
      onDeleteSuccess();
      if (guid === savedReport.guid) {
        navigate('/reports/custom-report/', { replace: true });
      }
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Menu
      anchorEl={deleteAnchorRef}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          mutate(savedReport.guid);
        }}
      >
        <Stack space="small">
          <TextBox variant="heading-3">Delete {savedReport.name}?</TextBox>
          <TextBox>This action cannot be undone.</TextBox>
          <Box paddingTop="small">
            <Inline horizontalAlign="right" space="small">
              <Button variant="neutral" disabled={isLoading} onClick={onCancel}>
                Cancel
              </Button>

              <Button
                variant="critical"
                autoFocus={true}
                type="submit"
                disabled={isLoading}
                pending={isLoading}
              >
                Delete Report
              </Button>
            </Inline>
          </Box>
        </Stack>
      </Form>
    </Menu>
  );
};
