import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { NullableDateString } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { InlineDateRangeField } from 'shared/form/InlineDateRangeField';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { ExpeditedPayBanner } from 'shared/modules/superpay/expedited-payment-reminder/ExpeditedPayBanner';
import { SuperPayYouGotPaidBanner } from 'shared/modules/superpay/SuperPayYouGotPaidBanner';
import { useLocationParams } from 'shared/routing/LocationParams';
import { ReportsNotAvailable } from './core/ReportNotAvailbale';
import { ReportsCard, ReportsCardItem } from './core/ReportsCard';
import { ReportsPageHeader } from './core/ReportsPageHeader';
import { useAccountsReceivableReports } from './data/ReportsAPI';
import { useReportsContext } from './data/ReportsContext';
import {
  ReportsAccountsReceivableShipperDTO,
  ReportsPeriodDTO,
  reportsPeriodSchema,
} from './data/ReportsDTO';
import { ReportsLoadsLink } from './ReportsLoadsLink';

interface AccountsReceivableTableProps {
  missing?: boolean;
  data?: ReportsAccountsReceivableShipperDTO[];
  loading?: boolean;
  startDate?: NullableDateString;
  endDate?: NullableDateString;
}
function AccountsReceivableTable({
  data,
  startDate,
  endDate,
}: AccountsReceivableTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Accounts Receivable Table">
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Load Count</TableCell>
            <TableCell>Total Due</TableCell>
            <TableCell>Load Count</TableCell>
            <TableCell>Past Due</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length === 0 && (
            <TableRow>
              <TableCell align="center" size="medium" colSpan={5}>
                <Typography color="textSecondary">
                  There are no accounts receivable data
                </Typography>
              </TableCell>
            </TableRow>
          )}

          {data?.map((row) => (
            <TableRow key={row.company_name}>
              <TableCell component="th" scope="row">
                {row.company_name}
              </TableCell>
              <TableCell>{row.total_due_order_count}</TableCell>
              <TableCell>
                <Typography gutterBottom={true}>
                  {formatCurrency(row.total_due_amount)}
                </Typography>

                <ReportsLoadsLink
                  stage="receivable"
                  companyName={row.company_name}
                  params={{
                    start_date: startDate,
                    end_date: endDate,
                  }}
                >
                  View Loads
                </ReportsLoadsLink>
              </TableCell>
              <TableCell>{row.past_due_order_count}</TableCell>
              <TableCell>
                <Typography gutterBottom={true}>
                  {formatCurrency(row.past_due_amount)}
                </Typography>

                <ReportsLoadsLink
                  stage="receivable/past-due"
                  companyName={row.company_name}
                  params={{
                    start_date: startDate,
                    end_date: endDate,
                  }}
                >
                  View Loads
                </ReportsLoadsLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ReportsAccountsReceivablePage() {
  const [queryParams, setParams] = useLocationParams({
    yupSchema: reportsPeriodSchema,
    persistName: 'ReportsAccountsReceivable',
  });
  const { data, isLoading, error } = useAccountsReceivableReports(queryParams);
  const { isAvailable } = useReportsContext();

  const params = useMemo<ReportsPeriodDTO>(
    () => ({
      start_date: queryParams.start_date || data?.start_date,
      end_date: queryParams.end_date || data?.end_date,
    }),
    [data, queryParams],
  );

  useErrorSnackbar(error);

  return (
    <>
      <DocumentTitle title="Accounts Receivable - Reports" />

      <PageLayout
        stickyHeader={true}
        loading={isLoading}
        header={
          <ReportsPageHeader
            title="Accounts Receivable"
            helpLink="https://support.superdispatch.com/everything-dispatch-dashboard/accounts-receivable-billing-report"
          />
        }
      >
        {isAvailable ? (
          <Stack space="medium">
            <ExpeditedPayBanner source="Reports Page" />
            <SuperPayYouGotPaidBanner source="Reports Page Banner" />
            <Stack space="small">
              <InlineDateRangeField
                label="Choose Period"
                value={[params.start_date, params.end_date]}
                onChange={({ stringValue: [start, finish] }) => {
                  setParams({ start_date: start, end_date: finish });
                }}
              />

              <Columns space="small" collapseBelow="desktop">
                <Column width="3/4">
                  <Stack space="small">
                    <AccountsReceivableTable
                      data={data?.shippers}
                      loading={isLoading}
                      startDate={params.start_date}
                      endDate={params.end_date}
                    />

                    {!!data?.shippers_with_missing_info.length && (
                      <Stack space="xxsmall">
                        <TextBox variant="heading-3">
                          Orders with missing information (broker name or price)
                        </TextBox>

                        <AccountsReceivableTable
                          data={data.shippers_with_missing_info}
                          missing={true}
                          loading={isLoading}
                          startDate={params.start_date}
                          endDate={params.end_date}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Column>

                <Column width="1/4">
                  <ReportsCard title="Total">
                    <ReportsCardItem
                      label="Current Due:"
                      value={data?.current_due}
                    />
                    <ReportsCardItem label="Past Due:" value={data?.past_due} />
                    <ReportsCardItem
                      label="Total Due:"
                      value={data?.total_due}
                    />
                  </ReportsCard>
                </Column>
              </Columns>
            </Stack>
          </Stack>
        ) : (
          <ReportsNotAvailable />
        )}
      </PageLayout>
    </>
  );
}
