import { useDeepEqualMemo } from '@superdispatch/hooks';
import { useCallback } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import {
  parseSearchQuery,
  updateSearchQuery,
  URLSearchQuery,
  URLSearchQueryInit,
} from 'shared/utils/URLUtils';

export type QueryUpdater<TValue, TPartial = Partial<TValue>> = (
  updater: TPartial | ((prevState: TValue) => TPartial),
  replace?: true,
) => void;

export function useQuery(
  defaultValues?: URLSearchQueryInit,
): [URLSearchQuery, QueryUpdater<URLSearchQuery, URLSearchQueryInit>] {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useDeepEqualMemo(() => {
    const queryParams = parseSearchQuery(search);
    return { ...defaultValues, ...queryParams };
  }, [defaultValues, search]);

  const setQuery = useCallback<
    QueryUpdater<URLSearchQuery, URLSearchQueryInit>
  >(
    (partialOrFactory, replace) => {
      const currentSearch = search;
      const nextSearch = updateSearchQuery(currentSearch, (prevQuery) => {
        const nextQuery =
          typeof partialOrFactory === 'function'
            ? (partialOrFactory(prevQuery) as URLSearchQueryInit)
            : partialOrFactory;

        return { ...prevQuery, ...nextQuery };
      });

      if (nextSearch !== currentSearch) {
        navigate({ search: nextSearch }, { replace });
      }
    },
    [search, navigate],
  );

  return [query, setQuery];
}

export function useBasename() {
  const href = useHref('');
  return href.replace(/\/$/, '');
}
