import {
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FormattedDate, parseDate } from '@superdispatch/dates';
import { OverflowText, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useSetAtom } from 'jotai';
import { useMapValue } from 'shared/form/LeafletMap';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import styled from 'styled-components';
import { formatLoadStatus } from '../../loads/data/LoadDTO';
import { LoadForTripDTO } from '../data/TripsDTO';
import { hoveredFieldNameAtom } from '../TripsDetails';
import { TripFormVehicles } from './TripFormVehicles';

const ListItemTextContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
`;

const ListItemTextContentSecondary = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 400px;
  & .SD-Tag-root {
    flex-shrink: 0;
  }
`;

export interface TripFormLoadListItemProps {
  load: LoadForTripDTO;
  isLoading?: boolean;
  onSelect?: (load: LoadForTripDTO) => void;
  onDeselect?: (load: LoadForTripDTO) => void;
}

export function TripFormLoadListItem({
  load,
  onDeselect,
  onSelect,
  isLoading,
}: TripFormLoadListItemProps) {
  const map = useMapValue();
  const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);
  const [loadsField, _, { setValue }] = useField<LoadForTripDTO[]>({
    name: 'loads',
  });
  const [_loadsGuidsField, { error: errorGuid }] = useField<string[]>({
    name: 'load_guids',
  });
  const selectedIndex = loadsField.value.findIndex(
    ({ guid }) => guid === load.guid,
  );

  const hasFieldError = errorGuid === load.guid;

  const handleClick = () => {
    if (selectedIndex > -1) {
      void setValue(loadsField.value.filter(({ guid }) => guid !== load.guid));
      onDeselect?.(load);
    } else {
      if (load.pickup_latitude && load.pickup_longitude) {
        map?.flyTo([load.pickup_latitude, load.pickup_longitude], 5);
      }
      setHoveredFieldName(`loads[${loadsField.value.length}]`);
      void setValue(loadsField.value.concat(load));
      onSelect?.(load);
    }
  };

  const hasLocation =
    load.pickup_latitude &&
    load.pickup_longitude &&
    load.delivery_latitude &&
    load.delivery_longitude;

  return (
    <Tooltip
      key={load.guid}
      placement="right"
      title={
        !hasLocation
          ? "Can't add the load with incomplete or unrecognized address"
          : ''
      }
    >
      <span>
        {/* Wrap disabled elements in a <span> to enable Tooltip activation on hover */}
        <ListItem
          divider={true}
          button={true}
          disabled={!hasLocation || isLoading}
          onMouseLeave={() => {
            setHoveredFieldName('');
          }}
          onMouseEnter={() => {
            setHoveredFieldName(`loads[${selectedIndex}]`);
          }}
          onClick={handleClick}
        >
          <ListItemIcon>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Checkbox checked={selectedIndex > -1} color="primary" />
            )}
          </ListItemIcon>
          <Stack>
            <ListItemText
              disableTypography={true}
              primary={
                <ListItemTextContent>
                  <ListItemTextContentSecondary>
                    <OverflowText
                      gutterBottom={false}
                      disableUnderline={true}
                      TooltipProps={{
                        placement: 'top',
                        interactive: true,
                      }}
                      variant="h4"
                      color={load.load_id ? 'inherit' : 'textSecondary'}
                    >
                      {load.load_id || 'No Load ID'}
                    </OverflowText>

                    <Tag
                      aria-label="status tag"
                      variant="subtle"
                      color={
                        load.status === 'new'
                          ? 'teal'
                          : load.status === 'picked_up'
                          ? 'yellow'
                          : 'green'
                      }
                    >
                      {formatLoadStatus(load.status)}
                    </Tag>
                  </ListItemTextContentSecondary>

                  <Typography color="textSecondary">{load.customer}</Typography>
                </ListItemTextContent>
              }
            />

            <LoadWaypoints
              dense={true}
              inline={true}
              pickupAddress={
                load.origin || (
                  <Typography color="textSecondary">Not Available</Typography>
                )
              }
              pickupDate={
                load.origin_date && (
                  <>
                    {parseDate(load.origin_date).weekdayShort}
                    {', '}
                    <FormattedDate
                      date={load.origin_date}
                      variant="ShortDate"
                    />
                  </>
                )
              }
              deliveryAddress={
                load.destination || (
                  <Typography color="textSecondary">Not Available</Typography>
                )
              }
              deliveryDate={
                load.destination_date && (
                  <>
                    {parseDate(load.destination_date).weekdayShort}
                    {', '}
                    <FormattedDate
                      date={load.destination_date}
                      variant="ShortDate"
                    />
                  </>
                )
              }
            />

            {!!load.vehicles && (
              <Box marginTop="xsmall">
                <TripFormVehicles vehicles={load.vehicles} />
              </Box>
            )}

            {hasFieldError && (
              <TextBox color="red">
                Load is already in another trip or canceled
              </TextBox>
            )}
          </Stack>
        </ListItem>
      </span>
    </Tooltip>
  );
}
