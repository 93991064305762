import { useValueObserver } from '@superdispatch/hooks';
import { Location, useLocation } from 'react-router-dom';

export function useLocationChangeEffect(
  effect: (location: Location, previousLocation: Location) => void,
): void {
  const location = useLocation();

  useValueObserver(location, (previousLocation) => {
    effect(location, previousLocation);
  });
}
