import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginContainerPage } from 'shared/auth/LoginContainerPage';
import { openExternalURL } from 'shared/helpers/URLHelpers';

export function RedirectToLogin() {
  const navigate = useNavigate();

  if (
    import.meta.env.VITE_APP_NAME === 'slbd' &&
    (import.meta.env.VITE_APP_TARGET === 'preview' ||
      import.meta.env.VITE_APP_TARGET === 'local')
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      navigate({
        search: `?next=${window.location.pathname}`,
      });
    }, [navigate]);

    return <LoginContainerPage />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    openExternalURL('/tms/login{?next}', {
      next: window.location.pathname + window.location.search,
    });
  }, []);

  return null;
}
