import { ControlPosition } from 'leaflet';
import { createRef, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

interface Props {
  position: ControlPosition | null;
  children?: React.ReactNode;
  container?: React.HTMLAttributes<HTMLDivElement>;
  prepend?: boolean;
}

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

export function LeafletCustomControl(props: Props) {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null | undefined>(
    document.createElement('div'),
  );
  const positionClass =
    (props.position && POSITION_CLASSES[props.position]) ||
    POSITION_CLASSES.topright;
  const controlContainerRef = createRef<HTMLDivElement>();
  const map = useMap();

  /**
   * Whenever the control container ref is created,
   * Ensure the click / scroll propagation is removed
   * This way click/scroll events do not bubble down to the map
   */
  useEffect(() => {
    void (async () => {
      const {
        DomEvent: { disableClickPropagation, disableScrollPropagation },
      } = await import('leaflet');
      if (controlContainerRef.current !== null) {
        disableClickPropagation(controlContainerRef.current);
        disableScrollPropagation(controlContainerRef.current);
      }
    })();
  }, [controlContainerRef]);

  /**
   * Whenever the position is changed, go ahead and get the container of the map and the first
   * instance of the position class in that map container
   */
  useEffect(() => {
    const mapContainer = map.getContainer();
    const [targetDiv] = mapContainer.getElementsByClassName(positionClass);
    setPortalRoot(targetDiv as HTMLElement);
  }, [map, positionClass]);

  /**
   * Whenever the portal root is complete,
   * append or prepend the control container to the portal root
   */
  useEffect(() => {
    if (portalRoot != null) {
      if (props.prepend !== undefined && props.prepend) {
        portalRoot.prepend(controlContainerRef.current as Node);
      } else {
        portalRoot.append(controlContainerRef.current as Node);
      }
    }
  }, [portalRoot, props.prepend, controlContainerRef]);

  return (
    <div
      {...props.container}
      ref={controlContainerRef}
      // eslint-disable-next-line react/forbid-dom-props
      className="leaflet-control"
    >
      {props.children}
    </div>
  );
}
