import { ButtonBase, Tooltip } from '@material-ui/core';
import { Done, FileCopy } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { ReactElement, useCallback } from 'react';
import { useClipboard } from 'shared/helpers/DomHelpers';

interface CopyToClipboardTooltipProps {
  children: ReactElement;
  textToCopy: string;
  title: string;
  onCopy?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function CopyToClipboardTooltip({
  children,
  textToCopy,
  title,
  onCopy,
}: CopyToClipboardTooltipProps) {
  const { copy, status, reset } = useClipboard();

  const handleClose = useCallback(() => {
    // Reset copy status after tooltip is closed, there was issue with resetting status before tooltip is closed
    setTimeout(reset, 200);
  }, [reset]);

  const handleCopy = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (textToCopy && status !== 'copied') {
        copy(textToCopy);
        onCopy?.(event);
      }
    },
    [copy, onCopy, status, textToCopy],
  );

  return (
    <Tooltip
      interactive={true}
      onClose={handleClose}
      placement="top"
      title={
        textToCopy && (
          <ButtonBase disableRipple={true} onClick={handleCopy}>
            <Inline verticalAlign="center" space="xxsmall">
              {status === 'copied' ? (
                <>
                  <Done htmlColor={Color.White} fontSize="small" />
                  <TextBox color="white">Copied</TextBox>
                </>
              ) : (
                <>
                  <FileCopy htmlColor={Color.White} fontSize="small" />
                  <TextBox color="white">{title || 'Copy'}</TextBox>
                </>
              )}
            </Inline>
          </ButtonBase>
        )
      }
    >
      {children}
    </Tooltip>
  );
}
