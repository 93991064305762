import { createContext, ReactNode, useMemo } from 'react';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { ReportsAPI } from './ReportsAPI';
import { useReportsAvailability } from './useReposrtsAvailability';

export interface ReportsContext {
  reportsAPI: ReportsAPI;
  isAvailable: boolean;
}

const Context = createContext<null | ReportsContext>(null);

export function useReportsContext() {
  return useNullableContext('Reports', Context);
}

interface Props {
  children?: ReactNode;
}

export function ReportsContextProvider({ children }: Props) {
  const { isAvailable } = useReportsAvailability();
  const ctx = useMemo<ReportsContext>(
    () => ({ reportsAPI: new ReportsAPI(), isAvailable }),
    [isAvailable],
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
