import { DialogActions } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button, ButtonVariantProp } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { APIMutationResult } from 'shared/api/APIMutation';
import {
  MutationDialog,
  MutationDialogContent,
} from 'shared/form/MutationDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { DriverDeactivateDialogContent } from './core/DriverDeactivationDialogContent';
import { DriverDTO } from './data/DriverDTO';
import { trackDriversEvent } from './data/DriversAnalytics';
import {
  useDeleteDriver,
  useDriver,
  useReactivateDriver,
} from './data/DriversAPI';

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

interface BaseDriversPageMutationContentProps {
  guid: string | undefined;
  children: ReactNode;
  actionName: string;
  onDismiss: () => void;
  mutation: APIMutationResult<string>;
  submitButtonLabel?: ReactNode;
  submitButtonVariant?: ButtonVariantProp;
  getSuccessMessage: (driver?: DriverDTO) => ReactNode;
}

function BaseDriversPageMutationContent({
  guid,
  children,
  mutation,
  onDismiss,
  actionName,
  getSuccessMessage,
  submitButtonVariant,
  submitButtonLabel = actionName,
}: BaseDriversPageMutationContentProps) {
  const { data: driver } = useDriver(guid);
  const { addSnackbar } = useSnackbarStack();

  return (
    <MutationDialogContent
      onClose={onDismiss}
      disableDialogContent={!children}
      title={`${actionName} ${driver?.name || 'driver'}?`}
      actions={
        <DialogActions>
          <Button
            variant="neutral"
            onClick={onDismiss}
            disabled={mutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            pending={!driver || mutation.isLoading}
            variant={submitButtonVariant}
            onClick={() => {
              if (guid) {
                mutation.mutate(guid, {
                  onSuccess(response) {
                    addSnackbar(
                      response.user_message || getSuccessMessage(driver),
                    );
                  },
                  onError(error) {
                    addSnackbar(error.message, { variant: 'error' });
                  },
                });
              }
            }}
          >
            {submitButtonLabel}
          </Button>
        </DialogActions>
      }
    >
      {children}
    </MutationDialogContent>
  );
}

interface DriversPageMutationDialogContentProps {
  guid?: string;
  onDismiss: () => void;
  onSuccess?: (nextDriverGUID?: string) => void;
}

function DriverDeleteDialogContent({
  guid,
  onDismiss,
  onSuccess = onDismiss,
}: DriversPageMutationDialogContentProps) {
  const { data } = useCarrierSettings();
  const mutation = useDeleteDriver({
    onSuccess(_variables, _response, context) {
      trackDriversEvent({
        name: 'Carrier Removed Driver',
        driverGuid: guid,
      });
      onSuccess(context);
    },
  });
  const isSeatBasedPricingEnabled = useFlag('seats_based_pricing');
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );

  return (
    <BaseDriversPageMutationContent
      guid={guid}
      actionName="Remove"
      mutation={mutation}
      onDismiss={onDismiss}
      submitButtonVariant="critical"
      submitButtonLabel="Remove Driver"
      getSuccessMessage={(driver) =>
        `${driver?.name || 'Driver'} removed from Drivers`
      }
    >
      <List>
        {!isMultiCarrierConnectionEnabled && (
          <li>Removing a driver is permanent. This cannot be undone.</li>
        )}
        <li>This driver will be removed from your drivers list.</li>
        <li>
          The orders assigned to this driver will not be deleted but will be
          unassigned from them.
        </li>

        {data?.is_paying && !isSeatBasedPricingEnabled && (
          <li>Your monthly fee will reduce starting the next billing cycle.</li>
        )}
      </List>
    </BaseDriversPageMutationContent>
  );
}

function DriverReactivateDialogContent({
  guid,
  onDismiss,
  onSuccess = onDismiss,
}: DriversPageMutationDialogContentProps) {
  const { data } = useCarrierSettings();
  const mutation = useReactivateDriver({
    onSuccess() {
      onSuccess();
    },
  });

  return (
    <BaseDriversPageMutationContent
      guid={guid}
      mutation={mutation}
      onDismiss={onDismiss}
      actionName="Reactivate"
      getSuccessMessage={(driver) => `${driver?.name || 'Driver'} reactivated`}
    >
      {data?.is_paying && (
        <ul>
          <li>The driver will be added to your current subscription plan.</li>
          <li>You will be charged for this driver.</li>
        </ul>
      )}
    </BaseDriversPageMutationContent>
  );
}

export type DriversPageMutationDialogType =
  | 'delete'
  | 'deactivate'
  | 'reactivate'
  | 'reinvite'
  | 'plan_upgrade';

export interface DriversPageMutationDialogProps
  extends DriversPageMutationDialogContentProps {
  open: boolean;
  type: DriversPageMutationDialogType;
}

export function DriversPageMutationDialog({
  open,
  type,
  ...props
}: DriversPageMutationDialogProps) {
  return (
    <MutationDialog open={open} onClose={props.onDismiss}>
      {type === 'delete' && <DriverDeleteDialogContent {...props} />}
      {type === 'deactivate' && <DriverDeactivateDialogContent {...props} />}
      {type === 'reactivate' && <DriverReactivateDialogContent {...props} />}
    </MutationDialog>
  );
}
