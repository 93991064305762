import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button, DescriptionItem } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import certHolderIllustration from '../assets/cert_holder_dialog_illustration.png';

const CustomSizedDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 690px;
  }
`;

const PaddedDialogContent = styled(DialogContent)`
  padding: 48px 32px;
`;

const CenteredDialogActions = styled(DialogActions)`
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0;
  }
`;

interface VerifyCertificateHolderDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function VerifyCertificateHolderDialog({
  open,
  onClose,
  onSubmit,
}: VerifyCertificateHolderDialogProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <CustomSizedDialog open={open} onClose={onClose}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h1" align="center">
          Verify Super Dispatch is a Certificate Holder
        </Typography>
      </DialogTitle>
      <PaddedDialogContent>
        <Inline space="medium" verticalAlign="center">
          <img
            width="291px"
            src={certHolderIllustration}
            alt="certificate holder illustration"
          />
          <Stack space="small">
            <Typography variant="h4">Have your insurance agent:</Typography>
            <Stack space="xxsmall">
              <DescriptionItem icon={<Check />}>
                <Typography variant="body1">
                  List as a certificate holder:
                </Typography>
              </DescriptionItem>
              <DescriptionItem inset={true}>
                <Stack space="none">
                  <Typography>Super Dispatch</Typography>
                  <Typography>905 McGee St. #210</Typography>
                  <Typography>Kansas City, MO 64106</Typography>
                </Stack>
              </DescriptionItem>
            </Stack>
            <Stack space="xxsmall">
              <DescriptionItem icon={<Check />}>
                <Typography variant="body1">Email your COI to:</Typography>
              </DescriptionItem>
              <DescriptionItem inset={true}>
                <Typography>compliance@superdispatch.com</Typography>
              </DescriptionItem>
            </Stack>
          </Stack>
        </Inline>
      </PaddedDialogContent>
      <CenteredDialogActions>
        <Button fullWidth={isMobile} variant="neutral" onClick={onClose}>
          No, It is not done
        </Button>
        <Button fullWidth={isMobile} onClick={onSubmit}>
          Done, Save and Submit
        </Button>
      </CenteredDialogActions>
    </CustomSizedDialog>
  );
}
