import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';
import { LoginContainerPage } from 'shared/auth/LoginContainerPage';
import { RedirectToTMS } from 'shared/auth/LoginContextProvider';
import { LogoutContainerPage } from 'shared/auth/LogoutContainerPage';
import { ResetPasswordSuccessPage } from 'shared/auth/ResetPasswordSuccessPage';
import { VerifyAccountContainer } from 'shared/auth/VerifyAccountContainer';
import { VerifyAccountPage } from 'shared/auth/VerifyAccountPage';
import { ServerErrorBoundary } from 'shared/errors/ServerErrorBoundary';
import { RootOutlet } from 'shared/layout/RootOutlet';
import { ExperimentsContainer } from 'shared/modules/experiments/ExperimentContainer';
import { OnboardingWrapper } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { OnboardingInviteTeamStepPage } from 'shared/modules/onboarding/OnboardingInviteTeamStepPage';
import { OnboardingQuestionaireStep1Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep1Page';
import { OnboardingQuestionaireStep2Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep2Page';
import { OnboardingQuestionaireStep3Page } from 'shared/modules/onboarding/OnboardingQuestionnaireStep3Page';
import { OnboardingWelcomeStepPage } from 'shared/modules/onboarding/OnboardingWelcomeStepPage';
import { basePath } from 'shared/utils/RouterUtils';
import { CarrierOutlet } from './CarrierOutlet';
import { ContactsPage } from './contacts/ContactsPage';
import { DashboardLoadsPage } from './dashboard/DashboardLoadsPage';
import { DashboardPage } from './dashboard/DashboardPage';
import { DispatcherInvitationPage } from './dispatchers/dispatcher-invitation/DispatcherInvitationPage';
import { DispatchersPage } from './dispatchers/dispatcher-management/DispatchersPage';
import { DispatcherProfilePage } from './dispatchers/dispatchers-profile/DispatcherProfilePage';
import { DriverAccountPage } from './driver-account/DriverAccount';
import { DriverAccountContainerPage } from './driver-account/DriverAccountContainerPage';
import { DriverVerifyAccountPage } from './driver-account/DriverAccountVerifyAuthPage';
import { DriverAccountWrapper } from './driver-account/DriverAccountWrapper';
import { DriverDeleteAccountCompletionPage } from './driver-account/DriverDeleteAccountCompletionPage';
import { DriverDeleteAccountConfirmationPage } from './driver-account/DriverDeleteAccountConfirmationPage';
import { DriverDeleteAccountPage } from './driver-account/DriverDeleteAccountPage';
import { DriverAccountDeletionLayout } from './drivers/driver-account-deletion/DriverAccountDeletionPage';
import { CompletionStepPage } from './drivers/driver-account-deletion/steps/CompletionStepPage';
import { ConfirmationStepPage } from './drivers/driver-account-deletion/steps/ConfirmationStepPage';
import { MainStepPage } from './drivers/driver-account-deletion/steps/MainStepPage';
import { QuestionnaireStepPage } from './drivers/driver-account-deletion/steps/QuestionnaireStepPage';
import { DriverActivationPage } from './drivers/driver-activation/DriverActivationPage';
import { DriverInvitationPage } from './drivers/driver-invitation/DriverInvitationPage';
import { DriversPage } from './drivers/DriversPage';
import { LoadsContextProvider } from './loads/data/LoadsContext';
import { LoadsPageContextProvider } from './loads/loads-page/data/LoadsPageParamsContext';
import { loadsRoutes } from './loads/LoadsRoutes';
import { OffersPage } from './offers/offers/OffersPage';
import { PublicOfferPage } from './offers/public-offers/pages/PublicOfferPage';
import { PublicOfferPageContextProvider } from './offers/public-offers/PublicOfferPageContext';
import { profileRoutes } from './profile/ProfileRoutes';
import { reportsRoutes } from './reports/ReportsRoutes';
import { settingsRoutes } from './settings/SettingsRoutes';
import { SplitLoadsPage } from './split-loads/SplitLoadsPage';
import { TrackingContainerPage } from './tracking/TrackingContainer';
import { tripsRoutes } from './trips/TripsRoutes';

export const carrierAppRoutes: RouteObject[] = [
  ...loadsRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
  ...tripsRoutes,
  ...profileRoutes,
  {
    path: 'offers/:offerGUID?',
    element: <OffersPage />,
  },
  {
    path: 'dashboard',
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: 'loads',
        element: (
          <LoadsContextProvider>
            <LoadsPageContextProvider>
              <DashboardLoadsPage />
            </LoadsPageContextProvider>
          </LoadsContextProvider>
        ),
      },
    ],
  },
  {
    path: 'tracking',
    element: <TrackingContainerPage />,
  },
  {
    path: 'dispatchers',
    children: [
      {
        path: 'profile/:action?',
        element: <DispatcherProfilePage />,
      },
      {
        path: ':dispatcherGUID?',
        element: <DispatchersPage />,
      },
    ],
  },
  {
    path: 'contacts/:contactGUID?/:action?',
    element: <ContactsPage />,
  },
  {
    path: 'drivers/:driverGUID?/:action?',
    element: <DriversPage />,
  },
  {
    path: 'loads-split/:loadGUID',
    element: <SplitLoadsPage />,
  },
  {
    path: 'ratings',
    element: <Navigate to={`/profile/overview${window.location.search}`} />,
  },
  {
    path: '*',
    element: <Navigate to="/loads" />,
  },
];

function SplitOrderRedirectPage() {
  const params = useParams<{ id: string }>();
  const { search } = useLocation();

  return <Navigate to={`/loads-split/${params.id}${search}`} />;
}

function ViewOffersRedirectPage() {
  const params = useParams<{ key: string }>();
  const { search } = useLocation();

  return <Navigate to={`/public-offer/${params.key}${search}`} />;
}

export const router = createBrowserRouter(
  [
    {
      element: <RootOutlet />,
      children: [
        {
          path: '/orders/:id/split',
          element: <SplitOrderRedirectPage />,
        },
        {
          path: '/offers/:key/view/*',
          element: <ViewOffersRedirectPage />,
        },
        {
          path: '/login/verify',
          element: (
            <VerifyAccountContainer>
              <VerifyAccountPage />
            </VerifyAccountContainer>
          ),
        },
        {
          path: '/login/*',
          element: (
            <RedirectToTMS>
              <LoginContainerPage />
            </RedirectToTMS>
          ),
        },
        {
          path: '/reset-password-success',
          element: <ResetPasswordSuccessPage />,
        },
        { path: '/logout', element: <LogoutContainerPage /> },
        {
          path: '/public-offer/:guid',
          element: (
            <PublicOfferPageContextProvider>
              <PublicOfferPage />
            </PublicOfferPageContextProvider>
          ),
        },
        {
          path: '/driver-invite/:invitationCode',
          element: <DriverInvitationPage />,
        },
        {
          path: '/driver-activation/:activationCode',
          element: <DriverActivationPage />,
        },
        {
          path: '/driver-account-deletion/:token/*',
          element: <DriverAccountDeletionLayout />,
          children: [
            { path: 'questionnaire', element: <QuestionnaireStepPage /> },
            { path: 'confirmation', element: <ConfirmationStepPage /> },
            { path: 'completion', element: <CompletionStepPage /> },
            { index: true, element: <MainStepPage /> },
          ],
        },
        {
          path: '/dispatcher-invite/:invitationCode',
          element: <DispatcherInvitationPage />,
        },
        {
          path: '/driver-login',
          element: <DriverAccountContainerPage />,
        },
        {
          path: '/driver-account/*',
          children: [
            {
              index: true,
              element: (
                <DriverAccountWrapper>
                  <DriverAccountPage />
                </DriverAccountWrapper>
              ),
            },
            {
              path: 'verify',
              element: (
                <VerifyAccountContainer>
                  <DriverVerifyAccountPage />
                </VerifyAccountContainer>
              ),
            },
            {
              path: 'delete',
              children: [
                {
                  index: true,
                  element: (
                    <DriverAccountWrapper>
                      <DriverDeleteAccountPage />
                    </DriverAccountWrapper>
                  ),
                },
                {
                  path: 'completion',
                  element: <DriverDeleteAccountCompletionPage />,
                },
                {
                  path: 'confirmation',
                  element: (
                    <DriverAccountWrapper>
                      <DriverDeleteAccountConfirmationPage />
                    </DriverAccountWrapper>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: '/onboarding/*',
          children: [
            { path: 'invite-team', element: <OnboardingInviteTeamStepPage /> },
            {
              path: 'questions-step-3',
              element: <OnboardingQuestionaireStep3Page />,
            },
            {
              path: 'questions-step-2',
              element: <OnboardingQuestionaireStep2Page />,
            },
            {
              path: 'questions-step-1',
              element: <OnboardingQuestionaireStep1Page />,
            },
            { index: true, element: <OnboardingWelcomeStepPage /> },
          ],
        },
        {
          element: (
            <ServerErrorBoundary>
              <ExperimentsContainer>
                <OnboardingWrapper>
                  <CarrierOutlet />
                </OnboardingWrapper>
              </ExperimentsContainer>
            </ServerErrorBoundary>
          ),
          children: carrierAppRoutes,
        },
      ],
    },
  ],

  {
    basename: basePath,
  },
);
