import { useEffect } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { usePageTrack } from 'shared/helpers/Analytics';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackTripsEvent } from './data/TripsAnalytics';
import { TripDetailsPage } from './trip-details-page/TripDetailsPage';
import { CreateTripPage } from './trip-form-page/TripFormPage';
import { TripsListPage } from './trips-list-page/TripsListPage';
import { TripsDetails } from './TripsDetails';
import { TripsList } from './TripsList';

export function TripsContainerLayout() {
  usePageTrack('Trips');

  useEffect(() => {
    trackTripsEvent({
      name: 'CTMS: Opened React Trips Page',
    });
  }, []);

  return <Outlet />;
}

function TripDetailsPageRoute() {
  const isNewTripsPageEnabled = useFlag('trips_improvements');

  if (isNewTripsPageEnabled) {
    return <TripsDetails />;
  }

  return <TripDetailsPage />;
}

function TripsListPageRoute() {
  const isNewTripsPageEnabled = useFlag('trips_improvements');

  if (isNewTripsPageEnabled) {
    return <TripsList />;
  }

  return <TripsListPage />;
}

function TripsCreatePageRoute() {
  const isNewTripsPageEnabled = useFlag('trips_improvements');

  if (isNewTripsPageEnabled) {
    return <CreateTripPage />;
  }

  return <TripsListPage />;
}

export const tripsRoutes: RouteObject[] = [
  {
    path: 'trips',
    element: <TripsContainerLayout />,
    children: [
      {
        path: 'create',
        element: <TripsCreatePageRoute />,
      },
      {
        path: 'view/:tripGUID/:page?/:action?/:loadGUID?',
        element: <TripDetailsPageRoute />,
      },
      {
        path: ':action?/:actionGUID?',
        element: <TripsListPageRoute />,
      },
    ],
  },
];
