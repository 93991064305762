import { CircularProgress, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Stack, useSnackbarStack, VisibilityObserver } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { updateSearchQuery } from 'shared/utils/URLUtils';
import { useUnratedShippers } from './data/CarrierRatingsAPI';
import { RateShipperDialog } from './RateShipperDialog';
import { RatingIllustration } from './RatingIllustration';
import { UnratedShipper } from './UnratedShipper';

const isLoadboardMobile = import.meta.env.VITE_APP_NAME === 'slbm';

export function CarrierRatingsToGive() {
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const location = useLocation();
  const [_query, setQuery] = useQuery();

  const { data, error, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useUnratedShippers();

  const unratedShippers = useMemo(() => {
    return data?.pages.flatMap((page) => page.data);
  }, [data]);

  const [currentShipperGuid, setCurrentShipperGuid] = useState<string>();

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch ratings to give', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  const onClickRate = (guid: string) => {
    if (isLoadboardMobile) {
      const search = updateSearchQuery(location.search, (searchParams) => ({
        ...searchParams,
        utm_medium: 'Ratings To Give',
      }));

      navigate({
        pathname: `/rate-shipper/${guid}/`,
        search,
      });
    } else {
      setQuery({ utm_medium: 'Ratings To Give' }); // for analytics
      setCurrentShipperGuid(guid);
    }
  };

  return (
    <>
      <RateShipperDialog
        key={currentShipperGuid}
        shipperGUID={currentShipperGuid as string}
        isOpen={!!currentShipperGuid}
        onClose={() => {
          setQuery({ utm_medium: undefined });
          setCurrentShipperGuid(undefined);
        }}
      />

      {data ? (
        unratedShippers?.length ? (
          <Box width="100%" paddingTop="xsmall">
            <Stack space="large" align="center">
              {unratedShippers.map((unratedShipper) => (
                <UnratedShipper
                  key={unratedShipper.guid}
                  unratedShipper={unratedShipper}
                  onClickRate={() => {
                    onClickRate(unratedShipper.guid);
                  }}
                />
              ))}

              {hasNextPage && (
                <VisibilityObserver
                  onChange={(visibility) => {
                    if (!isFetchingNextPage && visibility === 'visible') {
                      void fetchNextPage();
                    }
                  }}
                  render={({ ref }) => (
                    <Box width="100%" ref={ref}>
                      {Array.from({ length: 3 }, (_, key) => (
                        <Skeleton
                          key={key}
                          width="100%"
                          height="50px"
                          animation={false}
                        />
                      ))}
                    </Box>
                  )}
                />
              )}
            </Stack>
          </Box>
        ) : (
          <Stack align="center">
            <Box
              width="90px"
              height="80px"
              marginTop={['small']}
              marginBottom="xsmall"
            >
              <RatingIllustration />
            </Box>

            <Typography variant="h3" color="textSecondary" align="center">
              All caught up!
            </Typography>

            <Typography color="textSecondary" align="center">
              Shippers you haven’t rated <br />
              yet will be listed here.
            </Typography>
          </Stack>
        )
      ) : (
        <Box width="40px" height="172px" margin="auto" paddingTop="xxlarge">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
