import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import { LinkAnchor } from 'shared/routing/Links';
import reportNotAvailableImage from '../assets/report-not-available.svg';
import { useReportsAvailability } from '../data/useReposrtsAvailability';

export function ReportsNotAvailable() {
  const { isLoading } = useReportsAvailability();

  if (isLoading) {
    return null;
  }

  return (
    <Box
      maxWidth="480px"
      padding="large"
      margin="auto"
      display="flex"
      alignItems="center"
      height="calc(100vh - 200px)"
    >
      <Stack space="medium" align="center">
        <Stack space="small" align="center">
          <img src={reportNotAvailableImage} alt="Report not available" />
          <Typography variant="h3">No Loads for Overview</Typography>
          <Typography color="textSecondary" align="center">
            Book loads on our load board or{' '}
            <LinkAnchor to="/loads" underline="none" color="primary">
              create or import
            </LinkAnchor>{' '}
            them before we show any report data
          </Typography>
        </Stack>

        <AnchorButton href="/loadboard" size="large">
          Find Loads on Super Loadboard
        </AnchorButton>
      </Stack>
    </Box>
  );
}
