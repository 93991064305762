import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { MicroDepositInitiatedContent } from './MicroDepositInitiatedContent';

interface MicroDepositInitiatedDialogProps {
  open: boolean;
  onClose: () => void;
}
export function MicroDepositInitiatedDialog({
  open,
  onClose,
}: MicroDepositInitiatedDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <MicroDepositInitiatedDialogContent onClose={onClose} />
    </Dialog>
  );
}
interface MicroDepositInitiatedDialogContentProps {
  onClose: () => void;
}

function MicroDepositInitiatedDialogContent({
  onClose,
}: MicroDepositInitiatedDialogContentProps) {
  return (
    <Box width="480px" padding="large">
      <Typography align="right">
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Typography>
      <Stack space="large" align="center">
        <MicroDepositInitiatedContent />
        <Button onClick={onClose}>Got it</Button>
      </Stack>
    </Box>
  );
}
