import { Typography } from '@material-ui/core';
import { Check, Email } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem } from '@superdispatch/ui-lab';
import { WebViewLink } from 'shared/routing/WebViewLinks';

interface InsuranceCertificateHolderInstructionProps {
  boldHeader?: boolean;
}

export function InsuranceCertificateHolderInstruction({
  boldHeader,
}: InsuranceCertificateHolderInstructionProps) {
  const isLoadboard =
    import.meta.env.VITE_APP_NAME === 'slbd' ||
    import.meta.env.VITE_APP_NAME === 'slbm';

  return (
    <Stack>
      <Typography variant={boldHeader ? 'h5' : 'body2'}>
        To expedite, please complete BOTH steps:
      </Typography>
      <Box paddingLeft="small">
        <Stack space="xsmall">
          <Typography>1. Have your insurance agent:</Typography>
          <Box paddingLeft="small">
            <Stack space="xsmall">
              <Stack space="xxsmall">
                <DescriptionItem icon={<Check />}>
                  List as Certificate Holder
                </DescriptionItem>
                <DescriptionItem inset={true}>
                  <Stack space="none">
                    <Typography>Super Dispatch</Typography>
                    <Typography>905 McGee St. #210</Typography>
                    <Typography>Kansas City, MO 64106</Typography>
                  </Stack>
                </DescriptionItem>
              </Stack>
              <Stack space="xxsmall">
                <DescriptionItem icon={<Check />}>Email to</DescriptionItem>
                <DescriptionItem inset={true}>
                  <Inline space="xxsmall" verticalAlign="center">
                    <Email fontSize="small" color="action" />
                    <Typography>compliance@superdispatch.com</Typography>
                  </Inline>
                </DescriptionItem>
              </Stack>
            </Stack>
          </Box>
          {isLoadboard ? (
            <Typography>
              2. Once emailed, upload the COI to your Carrier Profile{' '}
              <WebViewLink
                rel="noreferrer"
                target="_blank"
                href="/tms/profile/cargo_insurance"
              >
                here
              </WebViewLink>
              .
            </Typography>
          ) : (
            <Typography>
              2. Once emailed, make sure to also upload your new COI down below
              too.
            </Typography>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
