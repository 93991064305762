import { openExternalURL } from 'shared/helpers/URLHelpers';

export const { BASE_URL: basePath } = import.meta.env;
const isLocal: boolean = import.meta.env.VITE_APP_TARGET === 'local';

/**
 * Redirect to base path for local development
 */
export function redirectToBasePath() {
  if (!window.location.href.includes(basePath) && isLocal) {
    openExternalURL(basePath, { target: '_self' });
  }
}
