import { getMobileAppToken } from 'shared/data/MobileAppBridge';
import { logError } from 'shared/helpers/ErrorTracker';
import {
  deleteCookie,
  getCookie,
  setCookie,
} from 'shared/helpers/StorageHelpers';
import { LocalStore } from 'shared/helpers/Store';

const COOKIE_TOKEN_KEY = 'userToken';
export const STORAGE_TOKEN_KEY = 'tms:token';
const STORAGE_ROLE_KEY = 'tms:role';
export const DRIVER_ACCOUNT_TOKEN = 'driver_account_token';

try {
  const searchParams = new URLSearchParams(window.location.search);
  const queryToken = searchParams.get('token');
  const queryRole = searchParams.get('role');

  if (queryToken) {
    updateAuthToken(queryToken);
    searchParams.delete('token');

    // TODO: Remove this after migration
    migrateAuthToken();

    if (queryRole) {
      saveAuthRole(queryRole);
      searchParams.delete('role');
    }

    const nextSearch = searchParams.toString();

    if (!nextSearch) window.location.replace(window.location.pathname);
    else window.location.replace(`${window.location.pathname}?${nextSearch}`);
  }
} catch (e: unknown) {
  logError(e, 'AuthToken');
}

export function getAuthToken(): string | undefined {
  const mobileToken = getMobileAppToken();
  if (mobileToken) return mobileToken;

  const driverAccountToken = getDriverAccountToken();
  if (driverAccountToken) return driverAccountToken;

  const localStorageToken = LocalStore.get(STORAGE_TOKEN_KEY);
  if (localStorageToken) return localStorageToken;

  return getCookie(COOKIE_TOKEN_KEY);
}

export function updateAuthToken(token: string): void {
  setCookie(COOKIE_TOKEN_KEY, token);
}

export function clearAuthToken(): void {
  LocalStore.delete(STORAGE_TOKEN_KEY);
  // Loadboard has different domain than Carrier TMS and cookies are not shared
  // So we need to clear the cookie when loadboard auth error
  deleteCookie(COOKIE_TOKEN_KEY);
}

export function saveAuthRole(role: string | null): void {
  LocalStore.set(STORAGE_ROLE_KEY, role || null);
}

export function getAuthRole(): string | null {
  const role = LocalStore.get(STORAGE_ROLE_KEY);
  return role;
}

export function getDriverAccountToken(): string | null {
  return LocalStore.get(DRIVER_ACCOUNT_TOKEN);
}

function migrateAuthToken() {
  const localStorageToken = LocalStore.get(STORAGE_TOKEN_KEY);
  const cookieToken = getCookie(COOKIE_TOKEN_KEY);
  if (localStorageToken && cookieToken) {
    LocalStore.delete(STORAGE_TOKEN_KEY);
  } else if (localStorageToken) {
    setCookie(COOKIE_TOKEN_KEY, localStorageToken);
    LocalStore.delete(STORAGE_TOKEN_KEY);
  }
}
