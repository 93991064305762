import { AppBar, IconButton } from '@material-ui/core';
import { ArrowBack, InfoRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Inline, Stack, useSnackbarStack, useUID } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader } from 'shared/layout/PageLayout';
import { useLocationParams } from 'shared/routing/LocationParams';
import { Prompt } from 'shared/routing/NavigationBlock';
import styled from 'styled-components';
import { trackSplitLoadsEvent } from './data/SplitLoadsAnalytics';
import { useOrder, useSaveSplitedLoad } from './data/SplitLoadsAPI';
import {
  SplitDTO,
  splitPageParamsSchema,
  transformBaseLoad,
} from './data/SplitLoadsDTO';
import { SplitLoadsCardContainer } from './SplitLoadsCardContainer';
import {
  setShownIntroModal,
  shouldShowIntroModal,
  SplitLoadsIntroDialog,
} from './SplitLoadsIntroDialog';
import { SplitLoadsList } from './SplitLoadsList';

export const Layout = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LayoutHeader = styled(AppBar)`
  min-width: 0;
  max-width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
`;

export const FullHeightBox = styled(Box)`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 24px 32px;
`;

export const FullHeightColumns = styled(Box)`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
`;

export function SplitLoadsPage() {
  const uid = useUID();
  const { addSnackbar } = useSnackbarStack();
  const { loadGUID = '' } = useParams();
  const [params] = useLocationParams({
    yupSchema: splitPageParamsSchema,
    persistName: 'SplitPageParams',
  });
  const [currentModal, setCurrentModal] = useState<'intro'>();
  const [loads, setLoads] = useState<SplitDTO[]>([]);

  const { data: baseLoad, isLoading } = useOrder(loadGUID);
  const saveLoads = useSaveSplitedLoad(loadGUID, {
    onSuccess(response) {
      addSnackbar(response.user_message || 'Order was successfully split', {
        variant: 'success',
      });

      trackSplitLoadsEvent({
        name: 'Carrier Splited Order',
        is_created_by_broker: !!params.is_created_by_broker,
        utm_medium: params.utm_medium,
        number_of_splited_loads: loads.length,
        number_of_vehicles: baseLoad?.vehicles?.length,
      });

      setTimeout(() => {
        openExternalURL(params.back_url || '/tms/loads');
      }, 500);
    },
    onError(error) {
      addSnackbar(error.message || 'Failed to split order', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (baseLoad) {
      setLoads((previousLoads) => {
        const [, ...rest] = previousLoads;
        return [transformBaseLoad(baseLoad), ...rest];
      });
    }
  }, [baseLoad]);

  useEffect(() => {
    trackSplitLoadsEvent({ name: 'CTMS: Opened Split Order Page' });
    if (shouldShowIntroModal()) {
      setCurrentModal('intro');
      setShownIntroModal();
    }
  }, []);

  const onSave = () => {
    const [base, ...newLoads] = loads;
    if (base) {
      saveLoads.mutate({
        base_order_number: base.number,
        new_orders: newLoads.map((load) => ({
          order_number: load.number,
          vehicle_guids: load.vehicles?.map((vehicle) => vehicle.guid) ?? [],
        })),
      });
    }
  };

  return (
    <>
      <DocumentTitle title="Split Order" />
      <Prompt
        when={loads.length > 1 && saveLoads.isIdle}
        message="Changes have not been saved. Leaving will result in unsaved changes being lost."
      />
      <SplitLoadsIntroDialog
        isOpen={currentModal === 'intro'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
      />
      <Layout>
        <LayoutHeader>
          <PageHeader
            title="Split Order"
            startAction={
              params.back_url && (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (params.back_url) {
                      openExternalURL(params.back_url);
                    }
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )
            }
            endActions={
              <Inline space="large">
                <Button
                  disabled={isLoading || loads.length <= 1}
                  pending={saveLoads.isLoading}
                  onClick={onSave}
                  variant="primary"
                >
                  Save
                </Button>
                <IconButton
                  size="small"
                  aria-label="Intro"
                  onClick={() => {
                    setCurrentModal('intro');
                  }}
                >
                  <InfoRounded />
                </IconButton>
              </Inline>
            }
          />
        </LayoutHeader>
        <FullHeightBox aria-labelledby={uid}>
          <FullHeightColumns>
            {isLoading && (
              <Box>
                <SplitLoadsCardContainer>
                  <Stack>
                    <Skeleton width="60%" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="35%" />
                  </Stack>
                </SplitLoadsCardContainer>
              </Box>
            )}

            <SplitLoadsList
              loads={loads}
              onLoadsChanged={setLoads}
              isLoading={isLoading}
            />
          </FullHeightColumns>
        </FullHeightBox>
      </Layout>
    </>
  );
}
