import { FormikTextField, FormikTextFieldProps } from '@superdispatch/forms';
import { useField } from 'formik';
import { noop } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useBankNameByRoutingNumber } from '../../data/MoovAPI';

interface Props extends Omit<FormikTextFieldProps, 'helperText'> {
  onBankNameChange?: (bankName: string) => void;
}

export function FormikRoutingNumberField({
  name,
  onBankNameChange,
  ...props
}: Props) {
  const { mutateAsync: getBankNameByRoutingNumber } =
    useBankNameByRoutingNumber();
  const [{ value }] = useField<string>(name);
  const [bankName, setBankName] = useState<string>();

  useEffect(() => {
    let isCancelled = false;

    if (value.length === 9) {
      void getBankNameByRoutingNumber(value).then((data) => {
        if (!isCancelled) setBankName(data);
      }, noop);
    } else {
      setBankName(undefined);
    }

    return () => {
      isCancelled = true;
    };
  }, [getBankNameByRoutingNumber, value]);

  useEffect(() => {
    if (bankName) onBankNameChange?.(bankName);
  }, [bankName, onBankNameChange]);

  return (
    <FormikTextField
      {...props}
      name={name}
      helperText={
        bankName ||
        (value.length !== 9 &&
          'A 9-digit number in the lower left corner of a check')
      }
    />
  );
}
