import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const PickupIcon = createSvgIcon(
  'Pickup',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 5.08678L8.80409 6.30441L5.83792 3.26836L5.83792 12L4.16208 12L4.16208 3.26836L1.19591 6.30441L0 5.08678L5.00393 0L10 5.08678Z"
    fill={Color.Yellow500}
  />,
  { viewBox: '0 0 10 12', style: { width: 16, height: 16 } },
);
