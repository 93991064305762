import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'shared/layout/PageLayout';
import { LoadStage } from '../loads/data/LoadDTO';
import { useLoadsPageContext } from '../loads/loads-page/data/LoadsPageParamsContext';
import { LoadsList } from '../loads/LoadsList';
import { trackDashboardEvent } from './DashboardAnalytics';

export function DashboardLoadsPage() {
  const { params } = useLoadsPageContext();
  const navigate = useNavigate();

  useEffect(() => {
    switch (params.stage) {
      case 'aging':
        trackDashboardEvent({ name: `CTMS: Viewed Aging Loads` });
        break;
      case 'delayed-pickups':
        trackDashboardEvent({ name: `CTMS: Viewed Delayed Pickup Loads` });
        break;
      case 'delayed-deliveries':
        trackDashboardEvent({ name: `CTMS: Viewed Delayed Delivery Loads` });
        break;
      case 'to-pickup-today':
        trackDashboardEvent({ name: `CTMS: Viewed To Pick Up Today` });
        break;
      case 'to-deliver-today':
        trackDashboardEvent({ name: `CTMS: Viewed To Deliver Today` });
        break;
    }
  }, [params]);

  return (
    <LoadsList
      pageHeader={
        <PageHeader
          title={pageTitle(params.stage)}
          startAction={
            <IconButton
              edge="start"
              size="small"
              aria-label="go back"
              onClick={() => {
                navigate('/dashboard', { replace: true });
              }}
            >
              <ArrowBack />
            </IconButton>
          }
        />
      }
    />
  );
}

function pageTitle(stage: LoadStage): string {
  switch (stage) {
    case 'aging':
      return 'Aging Loads';
    case 'delayed-pickups':
      return 'Delayed Pickup Loads';
    case 'delayed-deliveries':
      return 'Delayed Delivery Loads';
    case 'to-pickup-today':
      return 'To Pick Up Today';
    case 'to-deliver-today':
      return 'To Deliver Today';
    default:
      return 'Loads';
  }
}
