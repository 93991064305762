import {
  Divider,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from '@material-ui/core';
import { Color, Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { downloadBlob } from 'shared/helpers/FileHelpers';
import { useBoolean } from 'shared/hooks/useBoolean';
import { CashIcon } from 'shared/icons';
import { CheckIcon } from 'shared/icons/CheckIcon';
import styled from 'styled-components';
import { TripDeleteDialog } from '../core/TripDeleteDialog';
import { useTripReport } from '../data/TripsAPI';
import { TripActionsDTO } from '../data/TripsDTO';
import { TripPaymentStatusActions } from './TripPaymentStatusActions';
import { TripStatusActions } from './TripStatusActions';
import { useTripActions } from './useTripActions';

const StyledCheckIcon = styled(CheckIcon)`
  && {
    height: 20px;
    width: 20px;
  }
`;

interface TripActionsProps extends MenuProps {
  trip: TripActionsDTO;
  onItemClick: () => void;
}

export function TripActions({
  trip,
  onItemClick,
  ...menuProps
}: TripActionsProps) {
  const { archive, unarchive } = useTripActions(trip.guid);
  const deleteDialogState = useBoolean(false);
  const { addSnackbar } = useSnackbarStack();
  const { mutate: downloadReport, isLoading: isDownloadingReport } =
    useTripReport({
      onSuccess: (blob) => {
        downloadBlob(blob, `${trip.name}-report.xlsx`);
        addSnackbar('Report downloaded');
      },
      onError: (error: Error) => {
        addSnackbar(error.message || 'Failed to download report', {
          variant: 'error',
        });
      },
    });
  const handleCloseDialog = () => {
    deleteDialogState.setFalse();
  };

  return (
    <>
      <TripDeleteDialog
        open={deleteDialogState.isEnabled}
        onClose={handleCloseDialog}
        tripGUID={trip.guid}
        tripName={trip.name}
      />

      <Menu
        {...menuProps}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Stack space="xxsmall">
          {!trip.archived && (
            <>
              <Box paddingLeft="small" paddingRight="small">
                <Inline verticalAlign="center">
                  <StyledCheckIcon htmlColor={Color.Dark100} />
                  <Typography variant="h6" color="textSecondary">
                    Trip Status
                  </Typography>
                </Inline>
              </Box>
              <TripStatusActions
                trip={trip}
                onItemClick={onItemClick}
                onArchive={() => {
                  archive.mutate(trip.guid);
                }}
              />
              <Divider />
              <Box paddingLeft="small" paddingRight="small">
                <Inline verticalAlign="center">
                  <CashIcon htmlColor={Color.Dark100} />
                  <Typography variant="h6" color="textSecondary">
                    Payment Status
                  </Typography>
                </Inline>
              </Box>
              <TripPaymentStatusActions trip={trip} onItemClick={onItemClick} />
              <Divider />
            </>
          )}

          <MenuItem
            disabled={isDownloadingReport}
            onClick={() => {
              downloadReport(trip.guid);
              onItemClick();
            }}
          >
            Download Report
          </MenuItem>
          <Divider />

          <MenuItem
            disabled={archive.isLoading || unarchive.isLoading}
            onClick={() => {
              if (trip.archived) {
                unarchive.mutate(trip.guid);
              } else {
                archive.mutate(trip.guid);
              }
              onItemClick();
            }}
          >
            {trip.archived ? 'Unarchive' : 'Archive'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteDialogState.setTrue();
              onItemClick();
            }}
          >
            Delete
          </MenuItem>
        </Stack>
      </Menu>
    </>
  );
}
