import { Tag, TagProps } from '@superdispatch/ui';
import { TripStatus } from '../data/TripsDTO';

const statusTagProps: Record<TripStatus | 'archived', TagProps> = {
  planning: {
    children: 'Planning',
    color: 'teal',
    variant: 'subtle',
  },
  ready: {
    children: 'Ready',
    color: 'blue',
    variant: 'subtle',
  },
  in_progress: {
    children: 'In Progress',
    color: 'yellow',
    variant: 'subtle',
  },
  delivered: {
    children: 'All Delivered',
    color: 'green',
    variant: 'subtle',
  },
  completed: {
    children: 'Completed',
    color: 'green',
    variant: 'bold',
  },
  archived: {
    children: 'Archived',
    color: 'grey',
    variant: 'subtle',
  },
};

export function TripStatusTag({ status }: { status: TripStatus | 'archived' }) {
  return <Tag {...statusTagProps[status]} />;
}
