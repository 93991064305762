import { Dialog, LinearProgress } from '@material-ui/core';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { merge } from 'lodash-es';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormikComposerProvider,
  useFormikComposer,
} from 'shared/form/FormikComposer';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { Prompt } from 'shared/routing/NavigationBlock';
import { ScrollToTop } from 'shared/routing/ScrollToTop';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BackButton, useGoBack } from 'shared/ui/BackButton';
import { LoadCustomerReplaceConfirmDialog } from '../core/LoadCustomerReplaceConfirmDialog';
import {
  isEmptyLoadPayment,
  LoadCustomerEditDTO,
  LoadDeliveryEditDTO,
  LoadPickupEditDTO,
} from '../data/LoadActionsDTO';
import {
  formatLoadStatus,
  isEmptyLoadLeg,
  isEmptyLoadLegVenue,
} from '../data/LoadDTO';
import { emitLoadEvent } from '../data/LoadEvents';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsCache } from '../data/LoadsAPI';
import { useLoadsContext } from '../data/LoadsContext';
import { useLoadStageFromBackURL } from '../view/ViewLoadHeaderActions';
import { EditLoadPageAttachments } from './EditLoadPageAttachments';
import { EditLoadPageCustomer } from './EditLoadPageCustomer';
import { EditLoadPageDelivery } from './EditLoadPageDelivery';
import { EditLoadPageDetails } from './EditLoadPageDetails';
import { EditLoadPageExpenses } from './EditLoadPageExpenses';
import { EditLoadPagePayment } from './EditLoadPagePayment';
import { EditLoadPagePickup } from './EditLoadPagePickup';
import { EditLoadPageVehicles } from './EditLoadPageVehicles';

function EditLoadPageSaveDialog() {
  return (
    <Dialog open={false} fullWidth={true} maxWidth="xs">
      <Box padding="medium">
        <TextBox variant="caption">Load details…</TextBox>
        <LinearProgress variant="indeterminate" value={33} />
      </Box>
    </Dialog>
  );
}

export function EditLoadPage() {
  const { addSnackbar } = useSnackbarStack();
  const { invalidateLoads } = useLoadsCache();
  const { loadGUID } = useParams();
  const { data: load } = useLoadDetails(loadGUID);
  const goBack = useGoBack(`/loads/${loadGUID}`);
  const {
    loadPriceCalculationStatus: { isCalculating },
  } = useLoadsContext();
  const loadStage = useLoadStageFromBackURL();
  const isEditLoad = useFlag('edit_load');
  const isEnabledEditLoad = isEditLoad || !load?.is_created_by_broker;

  const composer = useFormikComposer({
    onSubmitSuccess() {
      goBack();
      invalidateLoads();
      addSnackbar('Load updated');
      emitLoadEvent('edited');

      const attachmentsForm = composer.getForm('attachments');
      const pickupForm = composer.getForm('pickup');
      const deliveryForm = composer.getForm('delivery');
      const customerForm = composer.getForm('customer');
      const detailsForm = composer.getForm('details');
      const paymentsForm = composer.getForm('payment');
      const vehiclesForm = composer.getForm('vehicles');
      const expensesForm = composer.getForm('expenses');

      const eventBody: Record<string, 'Added' | 'Edited'> = {};

      if (load) {
        if (attachmentsForm.dirty) {
          eventBody.attachments =
            load.attachments?.length === 0 ? 'Added' : 'Edited';
        }

        if (pickupForm.dirty) {
          eventBody.pickup_information = isEmptyLoadLeg(load.pickup)
            ? 'Added'
            : 'Edited';
        }

        if (deliveryForm.dirty) {
          eventBody.delivery_information = isEmptyLoadLeg(load.delivery)
            ? 'Added'
            : 'Edited';
        }

        if (customerForm.dirty) {
          eventBody.customer_information = isEmptyLoadLegVenue(
            load.customer.venue,
          )
            ? 'Added'
            : 'Edited';
        }

        if (detailsForm.dirty) {
          const isInitialValuesEmpty =
            !load.number &&
            !load.internal_load_id &&
            !load.instructions &&
            load.inspection_type === 'standard';

          eventBody.load_details = isInitialValuesEmpty ? 'Added' : 'Edited';
        }

        if (paymentsForm.dirty) {
          eventBody.payment_information = isEmptyLoadPayment(load.payments[0])
            ? 'Added'
            : 'Edited';
        }

        if (vehiclesForm.dirty) {
          eventBody.vehicles = load.vehicles.length === 0 ? 'Added' : 'Edited';
        }

        if (expensesForm.dirty) {
          eventBody.expenses = load.expenses?.length === 0 ? 'Added' : 'Edited';
        }

        trackLoadsEvent({
          name: 'Carrier Edited Load',
          utm_medium: loadStage,
          utm_content: 'Edit Page',
          load_status: formatLoadStatus(load.status),
          is_created_by_broker: !!load.is_created_by_broker,
          ...eventBody,
        });
      }
    },
  });

  const [confirmCopyToLeg, setConfirmCopyToLeg] = useState<
    'pickup' | 'delivery'
  >();

  const [vehiclesTotalPrice, setVehiclesTotalPrice] = useState<number>();

  function replaceCustomerWithLeg(leg: 'pickup' | 'delivery'): void {
    const legForm = composer.getForm(leg) as FormikContextTypeEnhanced<
      LoadPickupEditDTO | LoadDeliveryEditDTO,
      unknown
    >;
    const customerForm = composer.getForm(
      'customer',
    ) as FormikContextTypeEnhanced<LoadCustomerEditDTO, unknown>;

    void customerForm.setFieldValue(
      'venue',
      merge({}, customerForm.values.venue, legForm.values.venue),
    );

    addSnackbar('Copied to Customer Information');
  }

  function handleCopyToCustomer(leg: 'pickup' | 'delivery') {
    const customer = composer.getForm('customer');

    // with type any issue of formik
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (isEmptyLoadLegVenue(customer.values.venue)) {
      replaceCustomerWithLeg(leg);
    } else {
      setConfirmCopyToLeg(leg);
    }
  }

  return (
    <PageLayout
      stickyHeader={true}
      header={
        <PageHeader
          title="Edit Load"
          startAction={<BackButton onClick={goBack} />}
          endActions={
            <Button
              variant="primary"
              disabled={!composer.state.isDirty || isCalculating}
              pending={!load || composer.state.isSubmitting}
              onClick={() => {
                composer.submitForms();
              }}
            >
              Save
            </Button>
          }
        />
      }
    >
      <ScrollToTop />
      <Prompt
        when={
          composer.state.status !== 'submitted' &&
          (composer.state.isDirty || composer.state.isSubmitting)
        }
        message="Changes have not been saved. Leaving can result in unsaved changes being lost."
      />

      <LoadCustomerReplaceConfirmDialog
        open={!!confirmCopyToLeg}
        onDismiss={() => {
          setConfirmCopyToLeg(undefined);
        }}
        onConfirm={() => {
          if (confirmCopyToLeg) replaceCustomerWithLeg(confirmCopyToLeg);
          setConfirmCopyToLeg(undefined);
        }}
      />

      <FormikComposerProvider value={composer}>
        <EditLoadPageSaveDialog />

        {!!load && (
          <Box maxWidth="1296px" margin="auto">
            <Stack space="small">
              <EditLoadPageDetails
                load={load}
                isEnabledEditLoad={isEnabledEditLoad}
              />

              <Columns space="small" collapseBelow="tablet">
                <Column width="1/2">
                  <EditLoadPagePickup
                    load={load}
                    onCopyToCustomer={() => {
                      handleCopyToCustomer('pickup');
                    }}
                  />
                </Column>

                <Column width="1/2">
                  <EditLoadPageDelivery
                    load={load}
                    onCopyToCustomer={() => {
                      handleCopyToCustomer('delivery');
                    }}
                  />
                </Column>
              </Columns>

              <EditLoadPageVehicles
                isEnabledEditLoad={isEnabledEditLoad}
                load={load}
                onTotalPriceChange={(totalPrice) => {
                  setVehiclesTotalPrice(totalPrice);
                }}
              />

              <Columns space="small" collapseBelow="tablet">
                <Column width="1/2">
                  <Stack space="small">
                    <EditLoadPageCustomer load={load} />
                    <EditLoadPageAttachments load={load} />
                  </Stack>
                </Column>

                <Column width="1/2">
                  <EditLoadPagePayment
                    isEnabledEditLoad={isEnabledEditLoad}
                    load={load}
                    vehiclesTotalPrice={vehiclesTotalPrice}
                  />
                </Column>
              </Columns>

              <EditLoadPageExpenses load={load} />
            </Stack>
          </Box>
        )}
      </FormikComposerProvider>
    </PageLayout>
  );
}
