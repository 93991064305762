import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { useNavigate } from 'react-router-dom';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { useRemoveTrip } from '../data/TripsAPI';

interface TripDeleteDialogProps {
  onClose: () => void;
  tripGUID: string;
  tripName: string;
  open: boolean;
}

export function TripDeleteDialog({
  onClose,
  tripGUID,
  tripName,
  open,
}: TripDeleteDialogProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();

  const remove = useRemoveTrip({
    onSuccess(response) {
      trackTripsEvent({
        name: 'Carrier Deleted Trip',
        trip_guid: tripGUID,
      });
      addSnackbar(response.user_message || 'Trip has been deleted', {
        variant: 'success',
      });
      onClose();
      navigate('/trips', { replace: true });
    },
    onError() {
      addSnackbar('Error occurred while deleting the trip', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title={`Delete trip ${tripName}?`}
        cancelButtonProps={{
          disabled: remove.isLoading,
          onClick(event) {
            event.stopPropagation();
            onClose();
          },
        }}
        confirmButtonProps={{
          children: 'Delete',
          variant: 'critical',
          pending: remove.isLoading,
          onClick(event) {
            event.stopPropagation();
            remove.mutate(tripGUID);
          },
        }}
      >
        <Stack space="xxsmall">
          <li>All loads will stay saved in Loads page</li>
          <li>Loads will be removed from the trip</li>
          <li>All trip related data will be deleted</li>
        </Stack>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
