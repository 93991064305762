import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import {
  formatLoadsPageField,
  isLoadSearchField,
  LoadSearchField,
  LoadsPageParams,
  LOAD_SEARCH_FIELDS,
} from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';

function useLastUsedSearchName(): LoadSearchField {
  const lastUsedName = useLocalStore('last_load_search_name');
  return isLoadSearchField(lastUsedName) ? lastUsedName : 'number';
}

interface FormValues {
  name: LoadSearchField;
  value: LoadsPageParams[LoadSearchField];
}

export interface LoadsPageFilterSearchProps {
  name: LoadSearchField | undefined;
  value: LoadsPageParams[LoadSearchField] | undefined;
  onSearch: (
    name: LoadSearchField,
    value: LoadsPageParams[LoadSearchField],
  ) => void;
}

export function LoadsPageFilterSearch({
  name,
  value,
  onSearch,
}: LoadsPageFilterSearchProps) {
  const navigate = useNavigate();
  const { params } = useLoadsPageContext();
  const lastUsedName = useLastUsedSearchName();
  const formik = useFormikEnhanced<FormValues, void>({
    initialValues: {
      name: name || lastUsedName,
      value: value || '',
    },
    validateOnMount: false,
    validateOnChange: false,
    validate(values) {
      if (values.value == null || values.value.length < 3) {
        return {
          value: 'Please enter at least 3 characters.',
        };
      }

      return undefined;
    },
    onSubmit(values) {
      onSearch(values.name, values.value);
      LocalStore.set('last_load_search_name', values.name);
    },
  });

  const { values, touched, errors, handleBlur, handleChange } = formik;

  useEffect(() => {
    const isNotAvailableSearchPage = params.stage === 'active' && !value;
    if (isNotAvailableSearchPage) {
      navigate({ search: 'stage=new' }, { replace: true });
    }
  }, [params.stage, value, navigate]);

  return (
    <FormikProvider value={formik}>
      <Inline space="xsmall">
        <FormikTextField name="name" select={true} aria-label="search name">
          {LOAD_SEARCH_FIELDS.map((field) => (
            <MenuItem key={field} value={field}>
              {formatLoadsPageField(field)}
            </MenuItem>
          ))}
        </FormikTextField>

        <ClickAwayListener
          onClickAway={() => {
            void formik.setFieldTouched('value', false);
          }}
        >
          <div>
            <Tooltip
              placement="top"
              enterDelay={200}
              title={errors.value ?? ''}
              open={!!(touched.value && errors.value)}
            >
              <TextField
                name="value"
                value={values.value}
                placeholder="Search..."
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.defaultPrevented) {
                    formik.handleSubmit();
                  }
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        color="primary"
                        aria-label="Search"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Inline>
    </FormikProvider>
  );
}
