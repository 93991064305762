import { Column, Columns, Tag } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SettingsBillingPage } from './SettingsBillingPage';
import { SettingsDriverAppPage } from './SettingsDriverAppPage';
import { SettingsFactoringPage } from './SettingsFactoringPage';
import { SettingsGeneralPage } from './SettingsGeneralPage';
import { SettingsNotificationsPage } from './SettingsNotificationsPage';
import { SettingsQuickbooksIntegrationPage } from './SettingsQuickbooksIntegrationPage';
import { SettingsSubscriptionLegacyPage } from './SettingsSubscriptionDetailsLegacyPage';
import { SettingsSubscriptionPage } from './SettingsSubscriptionDetailsPage';
import { SettingsTerminalPage } from './SettingsTerminalsPage';

interface SettingsSidebarProps {
  href: string;
  children?: ReactNode;
}

function SettingsSidebarExternalItem({ href, children }: SettingsSidebarProps) {
  return (
    <SidebarMenuItem
      external={true}
      onClick={() => {
        openExternalURL(href, { target: '_blank' });
      }}
    >
      {children}
    </SidebarMenuItem>
  );
}

export function SettingsContainerOutlet() {
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();
  return (
    <SidebarContainer
      sidebar={
        <Sidebar title="Settings">
          <SidebarMenuItemLink to="/settings/general/">
            General
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/terminals/">
            Terminals
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/driver-app/">
            Driver App
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/billing/">
            Billing
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/factoring/">
            Factoring
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/quickbooks/">
            Quickbooks Integration
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/subscription/">
            <Columns space="xsmall">
              <Column width="fluid">Subscription Details</Column>
              <Column width="content">
                {doesBillingAddressNotExist && (
                  <Tag variant="subtle" color="yellow">
                    To Do
                  </Tag>
                )}
              </Column>
            </Columns>
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/notifications/">
            Notifications
          </SidebarMenuItemLink>
          <SettingsSidebarExternalItem href="/loadboard/settings/notifications/">
            Loadboard Notifications
          </SettingsSidebarExternalItem>
        </Sidebar>
      }
    >
      <Outlet />
    </SidebarContainer>
  );
}

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings/:page?',
    element: <SettingsContainerOutlet />,
    children: [
      {
        path: 'general',
        element: <SettingsGeneralPage />,
      },
      {
        path: 'terminals/:action?',
        element: <SettingsTerminalPage />,
      },
      {
        path: 'driver-app',
        element: <SettingsDriverAppPage />,
      },
      {
        path: 'billing',
        element: <SettingsBillingPage />,
      },
      {
        path: 'factoring',
        element: <SettingsFactoringPage />,
      },
      {
        path: 'quickbooks',
        element: <SettingsQuickbooksIntegrationPage />,
      },
      {
        path: 'subscription/:action?',
        element: <SettingsSubscriptionRoute />,
      },
      {
        path: 'notifications',
        element: <SettingsNotificationsPage />,
      },
      {
        path: 'notification-emails',
        element: <Navigate to="/settings/notifications/" />,
      },
      {
        index: true,
        element: <Navigate to="/settings/general" />,
      },
    ],
  },
];

function SettingsSubscriptionRoute() {
  const isSeatBasedPriceEnabled = useFlag('seats_based_pricing');

  if (isSeatBasedPriceEnabled) {
    return <SettingsSubscriptionPage />;
  }

  return <SettingsSubscriptionLegacyPage />;
}
