import { Edit, Map } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import { AnchorButton, Button, ButtonProps } from '@superdispatch/ui-lab';
import { useMemo, useRef } from 'react';
import { useBoolean } from 'shared/hooks/useBoolean';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { TripActionsDTO } from '../data/TripsDTO';
import { DeleteTripDialog } from '../trips-list-page/DeleteTripDialog';
import { useTripActions } from './useTripActions';

interface TripPrimaryAction {
  label: string;
  action: string;
  visible: boolean;
  variant?: ButtonProps['variant'];
  startIcon?: React.ReactNode;
}

function getActions(trip: TripActionsDTO): TripPrimaryAction[] {
  const isAllLoadsDelivered =
    !!trip.loads?.length &&
    trip.loads.every((load) => load.status === 'delivered');

  return [
    {
      label: trip.loads?.length ? 'Edit Route' : 'Add Route',
      action: 'add-load',
      visible:
        trip.status !== 'completed' && !isAllLoadsDelivered && !trip.archived,
      variant: trip.driver?.guid ? 'neutral' : 'primary',
      startIcon: trip.driver?.guid ? <Edit /> : null,
    },
    {
      label: 'Set as Completed',
      action: 'set-as-completed',
      visible:
        trip.status !== 'completed' && isAllLoadsDelivered && !trip.archived,
    },
    {
      label: 'Archive',
      action: 'archive',
      visible: trip.status === 'completed' && !trip.archived,
    },
    {
      label: 'Delete',
      action: 'delete',
      visible: !!trip.archived,
      variant: 'neutral',
    },
  ];
}

interface TripPrimaryActionsProps {
  trip: TripActionsDTO;
  onEditRoute: () => void;
}

export function TripPrimaryActions({
  trip,
  onEditRoute,
}: TripPrimaryActionsProps) {
  const actions = useMemo(() => getActions(trip), [trip]);
  const snackbarMessage = useRef('');
  const { archive, editTrip } = useTripActions(trip.guid);
  const deleteDialogState = useBoolean();

  const handleAction = (action: string) => {
    switch (action) {
      case 'add-load':
        onEditRoute();
        break;
      case 'set-as-completed':
        editTrip({
          guid: trip.guid,
          name: trip.name,
          is_completed: true,
        });
        snackbarMessage.current = 'Trip is completed';
        break;
      case 'archive':
        archive.mutate(trip.guid);
        break;
      case 'delete':
        deleteDialogState.setTrue();
        break;
    }
  };

  return (
    <>
      {deleteDialogState.isEnabled && (
        <DeleteTripDialog
          onClose={deleteDialogState.setFalse}
          tripGUID={trip.guid}
        />
      )}

      <Inline space="small">
        {actions.map(
          ({ label, action, visible, variant, startIcon }) =>
            visible && (
              <Button
                key={label}
                variant={variant}
                startIcon={startIcon}
                onClick={() => {
                  handleAction(action);
                }}
              >
                {label}
              </Button>
            ),
        )}
        <AnchorButton
          onClick={() => {
            trackTripsEvent({
              name: 'Carrier Opened Map',
              trip_guid: trip.guid,
            });
          }}
          startIcon={<Map />}
          variant="neutral"
          href={trip.map_route_url || ''}
          target="_blank"
        >
          Open Map
        </AnchorButton>
      </Inline>
    </>
  );
}
