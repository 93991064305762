import { createContext, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { APIQueryResult } from 'shared/api/APIQuery';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { PublicOfferDTO } from './data/PublicOfferDTO';
import { usePublicOffer } from './data/PublicOffersAPI';

export interface PublicOfferPageContext {
  publicOffer: APIQueryResult<PublicOfferDTO>;
}

const Context = createContext<null | PublicOfferPageContext>(null);

export function usePublicOfferPageContext() {
  return useNullableContext('PublicOfferPage', Context);
}

export interface PublicOfferPageContextProviderProps {
  children?: ReactNode;
}

export function PublicOfferPageContextProvider({
  children,
}: PublicOfferPageContextProviderProps) {
  const { guid: offerGUID = '' } = useParams();

  const publicOffer = usePublicOffer(offerGUID);

  const ctx = useMemo<PublicOfferPageContext>(
    () => ({ publicOffer }),
    [publicOffer],
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}
