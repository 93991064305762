import { MenuItem } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useRef } from 'react';
import { useEditTrip } from '../data/TripsAPI';
import { TripActionsDTO } from '../data/TripsDTO';

interface TripStatusActionsProps {
  trip: TripActionsDTO;
  onItemClick: () => void;
  onArchive?: () => void;
}

export function TripStatusActions({
  trip,
  onItemClick,
  onArchive,
}: TripStatusActionsProps) {
  const { addSnackbar } = useSnackbarStack();
  const snackbarMessage = useRef('');
  const { mutate: editTrip } = useEditTrip({
    onSuccess: (_response, variables) => {
      addSnackbar(snackbarMessage.current, {
        action: variables.is_completed && (
          <Button
            onClick={() => {
              onArchive?.();
            }}
            variant="inverted"
          >
            Archive Trip
          </Button>
        ),
      });
    },
    onError: (response) => {
      addSnackbar(response.message || 'Failed to update trip status', {
        variant: 'error',
      });
    },
  });

  return trip.status === 'completed' ? (
    <MenuItem
      onClick={() => {
        editTrip({
          guid: trip.guid,
          name: trip.name,
          is_completed: false,
        });
        snackbarMessage.current = 'Trip is uncompleted';
        onItemClick();
      }}
    >
      Set as Uncompleted
    </MenuItem>
  ) : (
    <MenuItem
      onClick={() => {
        editTrip({
          guid: trip.guid,
          name: trip.name,
          is_completed: true,
        });
        snackbarMessage.current = 'Trip is completed';
        onItemClick();
      }}
    >
      Set as Completed
    </MenuItem>
  );
}
