import { MenuItem } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Color, Inline, useSnackbarStack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useRef } from 'react';
import styled from 'styled-components';
import { useEditTrip } from '../data/TripsAPI';
import { TripActionsDTO } from '../data/TripsDTO';

const StyledMenuItem = styled(MenuItem)`
  &[data-selected='true'] {
    background-color: ${Color.Blue50};
  }
`;

interface TripPaymentStatusActionsProps {
  trip: TripActionsDTO;
  onItemClick: () => void;
}

export function TripPaymentStatusActions({
  trip,
  onItemClick,
}: TripPaymentStatusActionsProps) {
  const { addSnackbar } = useSnackbarStack();
  const snackbarMessage = useRef('');
  const { mutate: editTrip } = useEditTrip({
    onSuccess: () => {
      addSnackbar(snackbarMessage.current);
    },
    onError: (response) => {
      addSnackbar(response.message || 'Failed to update trip payment status', {
        variant: 'error',
      });
    },
  });

  return (
    <>
      <StyledMenuItem
        onClick={() => {
          editTrip({
            guid: trip.guid,
            name: trip.name,
            is_customer_billed: !trip.is_customer_billed,
          });
          snackbarMessage.current = trip.is_customer_billed
            ? 'Billed status removed.'
            : 'Trip is billed.';

          onItemClick();
        }}
        data-selected={trip.is_customer_billed}
      >
        <Inline verticalAlign="center" noWrap={true}>
          {trip.is_customer_billed && <Check htmlColor={Color.Dark300} />}
          <TextBox>Mark as Billed</TextBox>
        </Inline>
      </StyledMenuItem>

      <StyledMenuItem
        onClick={() => {
          editTrip({
            guid: trip.guid,
            name: trip.name,
            is_paid_by_customer: !trip.is_paid_by_customer,
          });
          snackbarMessage.current = trip.is_paid_by_customer
            ? 'Paid by customer status is removed. '
            : 'Trip is paid by customer.';
          onItemClick();
        }}
        data-selected={trip.is_paid_by_customer}
      >
        <Inline verticalAlign="center" noWrap={true}>
          {trip.is_paid_by_customer && <Check htmlColor={Color.Dark300} />}
          <TextBox>Mark as Paid by Customer</TextBox>
        </Inline>
      </StyledMenuItem>

      <StyledMenuItem
        onClick={() => {
          editTrip({
            guid: trip.guid,
            name: trip.name,
            is_driver_paid: !trip.is_driver_paid,
          });
          snackbarMessage.current = trip.is_driver_paid
            ? 'Driver paid status removed.'
            : 'Trip payment is paid to driver. ';
          onItemClick();
        }}
        data-selected={trip.is_driver_paid}
      >
        <Inline verticalAlign="center" noWrap={true}>
          {trip.is_driver_paid && <Check htmlColor={Color.Dark300} />}
          <TextBox>Mark as Driver Paid</TextBox>
        </Inline>
      </StyledMenuItem>
    </>
  );
}
