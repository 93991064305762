import { useMemo } from 'react';
import { useLoadCounts } from '../../loads/data/LoadsAPI';
import { useLoadsPageContext } from '../../loads/loads-page/data/LoadsPageParamsContext';

export function useReportsAvailability() {
  const { params } = useLoadsPageContext();
  const { data: counts, isLoading } = useLoadCounts(params);

  const isAvailable = useMemo(() => {
    const newLoads = counts?.get('new')?.count || 0;
    const assignedLoads = counts?.get('assigned')?.count || 0;
    const pickedUpLoads = counts?.get('picked_up')?.count || 0;
    const deliveredLoads = counts?.get('delivered')?.count || 0;
    const billedLoads = counts?.get('billed')?.count || 0;
    const paidLoads = counts?.get('paid')?.count || 0;
    const archivedLoads = counts?.get('archived')?.count || 0;
    const terminalLoads = counts?.get('in_terminal')?.count || 0;

    return (
      newLoads > 0 ||
      assignedLoads > 1 ||
      pickedUpLoads > 0 ||
      deliveredLoads > 0 ||
      billedLoads > 0 ||
      paidLoads > 0 ||
      terminalLoads > 0 ||
      archivedLoads > 0
    );
  }, [counts]);

  return {
    isAvailable,
    isLoading,
  };
}
