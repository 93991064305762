import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { useMemo } from 'react';
import { InlineDateRangeField } from 'shared/form/InlineDateRangeField';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useLocationParams } from 'shared/routing/LocationParams';
import { LoadingOverlay } from 'shared/ui/LoadingOverlay';
import styled from 'styled-components';
import { ReportsNotAvailable } from './core/ReportNotAvailbale';
import { ReportsCard, ReportsCardItem } from './core/ReportsCard';
import { ReportsPageHeader } from './core/ReportsPageHeader';
import { useAccountsPayableReports } from './data/ReportsAPI';
import { useReportsContext } from './data/ReportsContext';
import { ReportsPeriodDTO, reportsPeriodSchema } from './data/ReportsDTO';
import { ReportsLoadsLink } from './ReportsLoadsLink';

const StyledTableContainer = styled(TableContainer)<{
  component: React.ElementType<React.HTMLAttributes<HTMLElement>> | undefined;
}>`
  position: relative;
`;

export function ReportsAccountsPayablePage() {
  const [queryParams, setParams] = useLocationParams({
    yupSchema: reportsPeriodSchema,
    persistName: 'ReportsAccountsPayable',
  });

  const { data, error, isLoading } = useAccountsPayableReports(queryParams);
  const { isAvailable } = useReportsContext();

  const params = useMemo<ReportsPeriodDTO>(
    () => ({
      start_date: queryParams.start_date || data?.start_date,
      end_date: queryParams.end_date || data?.end_date,
    }),
    [data, queryParams],
  );

  useErrorSnackbar(error);

  return (
    <>
      <DocumentTitle title="Accounts Payable - Reports" />

      <PageLayout
        stickyHeader={true}
        loading={isLoading}
        header={
          <ReportsPageHeader
            title="Accounts Payable"
            helpLink="https://support.superdispatch.com/everything-dispatch-dashboard/accounts-payable-billing-report"
          />
        }
      >
        {isAvailable ? (
          <Stack space="small">
            <InlineDateRangeField
              label="Choose Period"
              value={[params.start_date, params.end_date]}
              onChange={({ stringValue: [start, finish] }) => {
                setParams({ start_date: start, end_date: finish });
              }}
            />

            <Columns space="small" collapseBelow="desktop">
              <Column width={['fluid', 'fluid', '3/4']}>
                <StyledTableContainer component={Paper}>
                  <LoadingOverlay in={isLoading} />

                  <Table aria-label="Accounts Receivable Table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Load Count</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!data?.shippers?.length ? (
                        <TableRow>
                          <TableCell align="center" size="medium" colSpan={3}>
                            <Typography color="textSecondary">
                              There are no accounts payable data
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        data.shippers.map((row) => (
                          <TableRow key={row.company_name}>
                            <TableCell component="th" scope="row">
                              {row.company_name}
                            </TableCell>

                            <TableCell>
                              <Typography gutterBottom={true}>
                                {row.order_count}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography gutterBottom={true}>
                                {formatCurrency(row.amount)}
                              </Typography>

                              <ReportsLoadsLink
                                stage="payable"
                                companyName={row.company_name}
                                params={{
                                  ...params,
                                  accounting_method: 'cash',
                                }}
                              >
                                View Loads
                              </ReportsLoadsLink>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </Column>

              <Column width={['fluid', 'fluid', '1/4']}>
                <ReportsCard title="Total">
                  <ReportsCardItem
                    label="Accounts Payable:"
                    value={data?.total}
                  />
                </ReportsCard>
              </Column>
            </Columns>
          </Stack>
        ) : (
          <ReportsNotAvailable />
        )}
      </PageLayout>
    </>
  );
}
