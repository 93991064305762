import { RouterProvider } from 'react-router-dom';
import { AppContainer } from 'shared/app/AppContainer';
import { renderApp } from 'shared/app/AppRenderer';
import { router } from './carrier-router';

renderApp(
  <AppContainer>
    <RouterProvider router={router} />
  </AppContainer>,
);
