import { Tab, Tabs } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { APIError } from 'shared/api/APIError';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { useInsurances } from 'shared/modules/verified-carrier-application/core/CargoInsuranceList';
import { CargoInsurancePreview } from 'shared/modules/verified-carrier-application/core/CargoInsurancePreview';
import { ProfileCargoInsuranceForm } from 'shared/modules/verified-carrier-application/core/ProfileCargoInsuranceForm';
import { trackCarrierProfileEvent } from 'shared/modules/verified-carrier-application/data/CarrierProfileAnalytics';
import { useCarrierProfileAPI } from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import {
  CargoInsuranceDTO,
  cargoInsuranceSchema,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';
import { useCargoInsuranceExpiring } from './core/CargoInsuranceExpiring';
import {
  InsuranceExpiringBanner,
  RenewalInsurancePendingBanner,
  RenewalInsuranceVerifiedBanner,
  ReviewedBanner,
} from './core/InsuranceBanners';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { VerifyCertificateHolderDialog } from './core/VerifyCertificateHolderDialog';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileCargoInsurancePage() {
  const { addSnackbar } = useSnackbarStack();
  const [selectedInsurance, setSelectedInsurance] = useState<
    'current' | 'renewal'
  >('current');
  const [currentModal, setCurrentModal] = useState<
    'verify-certificate-holder' | undefined
  >();

  const { data } = useVerifiedCarrierApplicationStatus();
  const verifiedCarrierApplication = data?.verified_carrier_application;
  const isApplicationReviewed =
    verifiedCarrierApplication?.status === 'reviewed';

  const { currentInsurance, renewalInsurance, isLoading } = useInsurances({
    onError: () => {
      addSnackbar('Failed to fetch cargo insurance', {
        variant: 'error',
      });
    },
  });

  const { isInsuranceExpiring, isRenewalInsuranceUploaded } =
    useCargoInsuranceExpiring();
  const shouldShowRenewalTab =
    isInsuranceExpiring && verifiedCarrierApplication?.status === 'approved';

  const { updateInsurance, createInsurance } = useCarrierProfileAPI();

  const initialValues = useInitialValues(
    cargoInsuranceSchema,
    (selectedInsurance === 'current' ? currentInsurance : renewalInsurance) ||
      {},
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: cargoInsuranceSchema,
    onSubmit: (values) => {
      return values.guid ? updateInsurance(values) : createInsurance(values);
    },
    onSubmitSuccess: (_: APIResponse<CargoInsuranceDTO>, values) => {
      addSnackbar('Cargo insurance was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content:
          selectedInsurance === 'current'
            ? 'Cargo Insurance'
            : 'Cargo Insurance Renewal',
        is_new_document: !values.guid,
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update cargo insurance: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="Cargo Insurance - Profile" />

      <VerificationDialogs formik={formik} />

      <VerifyCertificateHolderDialog
        open={currentModal === 'verify-certificate-holder'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onSubmit={() => {
          setCurrentModal(undefined);
          formik.handleSubmit();
        }}
      />

      <ProfilePageLayout
        formik={formik}
        size={isWebView ? 'small' : isApplicationReviewed ? 'large' : 'medium'}
        title="Cargo Insurance"
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={() => {
              if (selectedInsurance === 'current') {
                submitVerificationForm();
              } else {
                setCurrentModal('verify-certificate-holder');
              }
            }}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            <Box paddingTop="small">
              <Stack space="small">
                {shouldShowRenewalTab && (
                  <Tabs
                    value={selectedInsurance}
                    onChange={(_, next: 'current' | 'renewal') => {
                      setSelectedInsurance(next);
                    }}
                  >
                    <Tab value="current" label="Current" />
                    <Tab
                      value="renewal"
                      label={
                        <Inline space="xxsmall" verticalAlign="center">
                          Renewal{' '}
                          {!isRenewalInsuranceUploaded && (
                            <Warning
                              htmlColor={Color.Yellow300}
                              fontSize="small"
                            />
                          )}
                        </Inline>
                      }
                    />
                  </Tabs>
                )}

                {selectedInsurance === 'current' && isApplicationReviewed && (
                  <ReviewedBanner />
                )}

                {selectedInsurance === 'renewal' && <RenewalBanners />}

                <Box maxWidth={['496px', '496px', '440px']}>
                  <ProfileCargoInsuranceForm />
                </Box>
              </Stack>
            </Box>
          </Column>

          {!isWebView && (
            <Column>
              <CargoInsurancePreview />
            </Column>
          )}
        </Columns>
      </ProfilePageLayout>
    </>
  );
}

function RenewalBanners() {
  const { currentInsurance } = useInsurances();
  const { isRenewalInsuranceUploaded, isRenewalInsuranceVerified } =
    useCargoInsuranceExpiring();

  if (isRenewalInsuranceVerified && !!currentInsurance) {
    return (
      <RenewalInsuranceVerifiedBanner
        expirationDate={currentInsurance.expiration_date}
      />
    );
  }

  if (isRenewalInsuranceUploaded) {
    return <RenewalInsurancePendingBanner />;
  }

  return <InsuranceExpiringBanner />;
}
