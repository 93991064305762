import { Drawer, DrawerProps, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DrawerContent, DrawerTitle, useUID } from '@superdispatch/ui';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useIsMutating } from 'react-query';
import { useLocationChangeEffect } from 'shared/routing/LocationChangeEffect';
import { Prompt } from 'shared/routing/NavigationBlock';

interface MutationDrawerContext {
  titleID: string;
  isLoading: boolean;
}

const context = createContext<MutationDrawerContext>({
  titleID: '',
  isLoading: false,
});

export function MutationDrawer({
  onClose,
  children,
  PaperProps,
  ...props
}: DrawerProps) {
  const titleID = useUID();
  const mutationCount = useIsMutating();
  const isLoading = mutationCount > 0;
  const ctx = useMemo<MutationDrawerContext>(
    () => ({ titleID, isLoading }),
    [titleID, isLoading],
  );

  return (
    <Drawer
      {...props}
      PaperProps={{ ...PaperProps, 'aria-labelledby': titleID }}
      onClose={(event, reason) => {
        if (!isLoading) {
          onClose?.(event, reason);
        }
      }}
    >
      <context.Provider value={ctx}>{children}</context.Provider>
    </Drawer>
  );
}

export interface MutationDrawerContentProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;

  onClose?: () => void;
  enableAutoClose?: boolean;
  disableDrawerContent?: boolean;
}

export function MutationDrawerContent({
  title,
  subtitle,
  actions,
  onClose,
  children,
  enableAutoClose,
  disableDrawerContent,
}: MutationDrawerContentProps) {
  const { titleID, isLoading } = useContext(context);
  const iconID = `${titleID}-icon`;

  useLocationChangeEffect(() => {
    if (enableAutoClose) {
      onClose?.();
    }
  });

  return (
    <>
      <Prompt
        when={isLoading}
        message="Changes have not been saved. Leaving can result in unsaved changes being lost."
      />
      {!!title && (
        <DrawerTitle
          title={title}
          subtitle={subtitle}
          titleTypographyProps={{ id: titleID }}
          endAction={
            !!onClose && (
              <IconButton
                edge="end"
                onClick={onClose}
                disabled={isLoading}
                aria-labelledby={`${iconID} ${titleID}`}
              >
                <Close id={iconID} aria-label="close" />
              </IconButton>
            )
          }
        />
      )}

      {disableDrawerContent ? (
        children
      ) : (
        <DrawerContent>{children}</DrawerContent>
      )}

      {actions}
    </>
  );
}
