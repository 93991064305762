import { parseURITemplate } from '@superdispatch/uri';
import { useEffect } from 'react';
import { WEBSITE_SIGNUP_URL } from 'shared/constants/Constants';
import { chooseUTMSource, trackEvent } from 'shared/helpers/Analytics';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { getBackURL } from 'shared/ui/BackButton';

function getUTMParamFromBackUrl(key: string) {
  const url = new URL(window.location.href);
  const next = url.searchParams.get('next');

  if (!next) {
    return url.searchParams.get(key);
  }

  try {
    const nextURL = new URL(next, window.location.origin);
    return nextURL.searchParams.get(key);
  } catch (error: unknown) {
    // ignore error
  }

  return null;
}

export function getSignupPageUrl() {
  return parseURITemplate(
    `${WEBSITE_SIGNUP_URL}/carrier/{?utm_source,utm_medium,next_url}`,
    {
      utm_medium: getUTMParamFromBackUrl('utm_medium'),
      utm_source: getUTMParamFromBackUrl('utm_source') || chooseUTMSource(),
      next_url:
        window.location.origin +
        getBackURL(window.location.search, '/tms/loads'),
    },
  );
}

export function useSignUpEvent() {
  const [utm_source, setUtmSource] = useSearchParam('utm_source');

  useEffect(() => {
    if (utm_source === 'carrier_signup') {
      trackEvent('User Signed Up', { utm_source });
      setUtmSource('');
    }
  }, [utm_source, setUtmSource]);
}
