import {
  ButtonBase,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { Inline } from '@superdispatch/ui';
import styled from 'styled-components';
import { TripShortDTO, TripsPageOrderingParam } from '../data/TripsDTO';
import { TripsTableRowLegacy } from './TripsTableRowLegacy';

interface TripsTableLegacyProps {
  trips?: TripShortDTO[];
  isLoading?: boolean;
  error: Error | null;
  onOrderingChange: () => void;
  ordering: TripsPageOrderingParam;
}

const Styled1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export function TripsTableLegacy({
  trips,
  isLoading,
  ordering,
  onOrderingChange,
}: TripsTableLegacyProps) {
  if (isLoading) {
    return (
      <Styled1>
        <CircularProgress />
      </Styled1>
    );
  }

  return (
    <Table size="small" stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="h5">Trip Name</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5">Loads</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5">Status</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5">Driver</Typography>
          </TableCell>
          <TableCell>
            <ButtonBase onClick={onOrderingChange} disableRipple={true}>
              <Inline verticalAlign="center" space="xxsmall">
                <Typography variant="h5">Modified Date</Typography>
                {ordering === '-changed_at' ? (
                  <ArrowDownward color="action" fontSize="small" />
                ) : (
                  <ArrowUpward color="action" fontSize="small" />
                )}
              </Inline>
            </ButtonBase>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {trips?.map((trip) => (
          <TripsTableRowLegacy key={trip.guid} {...trip} />
        ))}

        {trips?.length === 0 && (
          <TableRow>
            <TableCell colSpan={6} size="medium">
              <Typography color="textSecondary" align="center">
                There are no trips.
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
