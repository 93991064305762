import { Link, Typography } from '@material-ui/core';
import { Column, Columns, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { MouseEvent, useState } from 'react';
import { useAuthToken } from 'shared/auth/AuthState';
import { trackAnchorClick } from 'shared/helpers/Analytics';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { OffersPageLayout } from '../../core/OffersPageLayout';
import { PublicOfferDTO } from '../data/PublicOfferDTO';
import { PublicOfferDetails } from '../PublicOfferDetails';
import { PublicOfferPageFooter } from '../PublicOfferPageFooter';
import { PublicOfferPageHeader } from '../PublicOfferPageHeader';
import { PublicOfferStepper } from '../PublicOfferStepper';
import { PublicOfferAssignDriverPage } from './PublicOfferAssignDriverPage';

export function PublicOfferAcceptedPage({ offer }: { offer: PublicOfferDTO }) {
  const isMobile = useResponsiveValue(true, false);
  const [contentState, setContentState] = useState<
    'assign_driver' | 'offer_details'
  >('offer_details');
  const authToken = useAuthToken();

  const onAssignDriver = () => {
    if (!authToken) {
      openExternalURL(`/tms/login?next=${window.location.pathname}`);
    } else {
      setContentState('assign_driver');
      window.scrollTo(0, 0);
    }
  };

  if (contentState === 'assign_driver' && offer.user) {
    return (
      <PublicOfferAssignDriverPage
        offer={offer}
        onClose={() => {
          setContentState('offer_details');
        }}
      />
    );
  }

  return (
    <>
      <PublicOfferPageHeader offer={offer} />

      <OffersPageLayout
        header={<PublicOfferStepper />}
        footer={
          <PublicOfferPageFooter>
            <Columns space="large" align="center">
              {!!offer.user && (
                <Column width="1/2">
                  <Button
                    fullWidth={true}
                    variant="primary"
                    size="large"
                    onClick={onAssignDriver}
                  >
                    <Typography noWrap={true} color="inherit" variant="inherit">
                      {!authToken
                        ? 'Login & Assign To Driver'
                        : offer.order.driver
                        ? 'Reassign'
                        : 'Assign to Driver'}
                    </Typography>
                  </Button>
                </Column>
              )}

              {!isMobile && (
                <Column width="1/2">
                  <Link
                    href="/tms/loads"
                    onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                      trackAnchorClick(
                        'CTMS: Clicked Go To CTMS',
                        {
                          userId: offer.user?.guid,
                          is_first_offer: offer.is_first_offer,
                        },
                        event,
                      );
                    }}
                  >
                    Open Carrier TMS →
                  </Link>
                </Column>
              )}
            </Columns>
          </PublicOfferPageFooter>
        }
      >
        <PublicOfferDetails offer={offer} />
      </OffersPageLayout>
    </>
  );
}
