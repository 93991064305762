import { trackEvent } from 'shared/helpers/Analytics';
import { CARRIER_LOGISTICS } from 'shared/modules/carrier-profile/CarrierInfoDTO';

export interface CarrierProfileAnalyticsEvent {
  name: 'Carrier Updated Carrier Info';
  utm_medium: 'Carrier Profile' | 'Verified Carrier Application';
  utm_content:
    | 'Carrier Info'
    | 'Contact Info'
    | 'Cargo Insurance'
    | 'Cargo Insurance Renewal'
    | 'W-9 Form'
    | 'USDOT Certificate'
    | 'Transporter Plate Registration'
    | 'State Motor Carrier Permit'
    | 'Business License'
    | 'Identification'
    | 'Billing Info'
    | 'ACH Payment Info'
    | 'Terms and Conditions';
  logistics_type?: (typeof CARRIER_LOGISTICS)[number];
  is_carrier_has_usdot?: boolean;
  is_new_document?: boolean;
}

export function trackCarrierProfileEvent(event: CarrierProfileAnalyticsEvent) {
  const { name, ...props } = event;
  trackEvent(name, { ...props });
}
