import { useState } from 'react';
import { useQuery } from 'react-query';
import { createPath, useBlocker } from 'react-router-dom';
import { ONE_HOUR } from 'shared/constants/NumberConstants';
import { trackEvent } from 'shared/helpers/Analytics';
import { useVisibilityState } from 'shared/helpers/BrowserHelpers';
import { useBasename } from 'shared/helpers/RoutingHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { requestBuildInfo } from './data/AppVersionApi';

const APP_NAME = import.meta.env.VITE_APP_NAME.toUpperCase();

function useForceRefreshOnNavigation(
  shouldForceRefresh: boolean,
  buildInfo?: AppBuildInfo,
) {
  const basename = useBasename();
  useBlocker(({ nextLocation }) => {
    if (shouldForceRefresh) {
      trackEvent(
        'Force Refreshed On Navigation',
        { app_name: APP_NAME, ...buildInfo },
        () => {
          openExternalURL(basename + createPath(nextLocation));
        },
      );
    }

    return false;
  });
}

interface AppBuildInfo {
  created_at: string;
}

export function AppVersionChecker() {
  const [currentBuildInfo, setCurrentBuildInfo] = useState<AppBuildInfo>();
  const [shouldForceRefresh, setShouldForceRefresh] = useState(false);
  const visibilityStatus = useVisibilityState();

  useQuery<AppBuildInfo>('version', requestBuildInfo, {
    refetchInterval: visibilityStatus === 'visible' ? ONE_HOUR : false,
    onSuccess(response) {
      if (currentBuildInfo) {
        setShouldForceRefresh(
          currentBuildInfo.created_at !== response.created_at,
        );
      } else {
        setCurrentBuildInfo(response);
      }
    },
  });

  useForceRefreshOnNavigation(shouldForceRefresh, currentBuildInfo);

  return null;
}
