import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { trackTripsEvent } from '../data/TripsAnalytics';
import {
  useArchiveTrip,
  useEditTrip,
  useUnarchiveTrip,
} from '../data/TripsAPI';

export function useTripActions(guid: string, snackbarMessage?: string) {
  const { addSnackbar } = useSnackbarStack();

  const archive = useArchiveTrip({
    onSuccess() {
      trackTripsEvent({
        name: 'Carrier Archived Trip',
        trip_guid: guid,
      });
      addSnackbar('Trip is archived.', {
        variant: 'success',
      });
    },
    onError() {
      addSnackbar('Error occurred while archiving the trip', {
        variant: 'error',
      });
    },
  });

  const unarchive = useUnarchiveTrip({
    onSuccess() {
      addSnackbar('Trip is unarchived.', {
        variant: 'success',
      });
    },
    onError() {
      addSnackbar('Error occurred while unarchiving the trip', {
        variant: 'error',
      });
    },
  });

  const { mutate: editTrip } = useEditTrip({
    onSuccess: (_response, variables) => {
      addSnackbar(snackbarMessage, {
        action: variables.is_completed && (
          <Button
            onClick={() => {
              archive.mutate(guid);
            }}
            variant="inverted"
          >
            Archive Trip
          </Button>
        ),
      });
    },
    onError: (response) => {
      addSnackbar(response.message || 'Failed to update trip status', {
        variant: 'error',
      });
    },
  });

  return { archive, unarchive, editTrip };
}
