import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { OnboardingCheckboxField } from './core/OnboardingCheckboxField';
import { OnboardingLayout } from './core/OnboardingLayout';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

const OPTIONS = [
  {
    label: "I'm here to explore the product",
    description: 'Looking to learn more about what Super Dispatch offers.',
    name: 'explore_product',
  },
  {
    label: 'I’m comparing it to other solutions',
    description: 'Evaluating Super Dispatch alongside other platforms.',
    name: 'compare_solutions',
  },
  {
    label: 'I’ve decided to use Super Dispatch',
    description: 'I’ve made my decision and want to use Super Dispatch.',
    name: 'use_super_dispatch',
  },
];

export function OnboardingQuestionaireStep2Page() {
  const navigate = useNavigate();
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();

  const formik = useFormikEnhanced({
    initialValues: {
      explore_product: '',
      compare_solutions: '',
      use_super_dispatch: '',
    },
    onSubmit: (values) => {
      const selectedOption = Object.keys(values).find(
        (key: keyof typeof values) => values[key],
      );

      return saveStep({
        stepName: 'question_what_brings_you_to_ctms',
        status: 'completed',
        data: {
          selected_options: [
            OPTIONS.find((option) => option.name === selectedOption)?.label,
          ],
          unselected_options: OPTIONS.filter(
            (option) => option.name !== selectedOption,
          ).map((option) => option.label),
        },
      });
    },
    onSubmitSuccess: () => {
      navigate('/onboarding/questions-step-3');
    },
  });

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    Object.keys(formik.values).forEach((key) => {
      if (key === e.currentTarget.name) {
        void formik.setFieldValue(key, true);
      } else {
        void formik.setFieldValue(key, false);
      }
    });
  }

  return (
    <OnboardingLayout maxWidth="720px" progress={50}>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Typography variant="h2">
                What brings you to Super Dispatch today?
              </Typography>
              <Typography color="textSecondary">
                Knowing this helps us understand your needs and provide a more
                personalized experience.
              </Typography>
            </Stack>
            <Inline horizontalAlign="center">
              <Stack space="large">
                <Box width="408px">
                  <Stack space="small" align="center">
                    {OPTIONS.map((option) => (
                      <OnboardingCheckboxField
                        key={option.label}
                        disabled={formik.isSubmitting}
                        name={option.name}
                        onClick={handleClick}
                        selected={
                          !!formik.values[
                            option.name as keyof typeof formik.values
                          ]
                        }
                        label={
                          <Stack>
                            <Typography variant="h5">{option.label}</Typography>
                            <Typography color="textSecondary">
                              {option.description}
                            </Typography>
                          </Stack>
                        }
                      />
                    ))}
                  </Stack>
                </Box>
                <Inline horizontalAlign="center">
                  <Button
                    size="large"
                    type="submit"
                    disabled={!Object.values(formik.values).some(Boolean)}
                    pending={formik.isSubmitting}
                  >
                    Next
                  </Button>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Form>
      </FormikProvider>
    </OnboardingLayout>
  );
}
