import { TextField } from '@material-ui/core';
import { DrawerActions } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import {
  MutationDrawer,
  MutationDrawerContent,
} from 'shared/form/MutationDrawer';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { DecodeVINsResponse, useDecodeVINs } from '../data/VINsAPI';

function toVINArray(input: string): string[] {
  return Array.from(
    new Set(
      input
        // replace all non character values with comma
        .replace(/\W/g, ',')
        // separate values by comma
        .split(',')
        // Remove empty values
        .filter(Boolean),
    ),
  );
}

export interface LoadBulkVinDecodeDrawerContentProps {
  onClose: () => void;
  onSubmitSuccess: (
    vehicles: DecodeVINsResponse['vehicles'],
    duplicateLoads: DecodeVINsResponse['duplicate_loads'],
  ) => void;
}

export function LoadBulkVinDecodeDrawerContent({
  onClose,
  onSubmitSuccess,
}: LoadBulkVinDecodeDrawerContentProps) {
  const [rawVINs, setRawVINs] = useState('');
  const vins = useMemo(() => toVINArray(rawVINs), [rawVINs]);
  const { mutate, isLoading } = useDecodeVINs({
    onSuccess({ data }) {
      onClose();
      onSubmitSuccess(data.vehicles, data.duplicate_loads);
    },
  });

  return (
    <MutationDrawerContent
      title="Bulk VIN Decode"
      onClose={onClose}
      actions={
        <DrawerActions>
          <Button
            pending={isLoading}
            disabled={!vins.length}
            onClick={() => {
              mutate(vins);
            }}
          >
            Decode
          </Button>
        </DrawerActions>
      }
    >
      <TextField
        id="vins"
        value={rawVINs}
        onChange={(event) => {
          setRawVINs(event.target.value);
        }}
        multiline={true}
        fullWidth={true}
        disabled={isLoading}
        label="VIN Codes"
        placeholder="Enter individual VINs on each line or separate them with comma (,)"
        helperText={
          vins.length > 0 &&
          `${vins.length} ${formatPlural(
            vins.length,
            'vehicle',
            'vehicles',
          )} identified`
        }
      />
    </MutationDrawerContent>
  );
}

interface LoadBulkVinDecodeDrawerProps
  extends LoadBulkVinDecodeDrawerContentProps {
  open: boolean;
}

export function LoadBulkVinDecodeDrawer({
  open,
  onClose,
  onSubmitSuccess,
}: LoadBulkVinDecodeDrawerProps) {
  return (
    <MutationDrawer open={open} onClose={onClose}>
      <LoadBulkVinDecodeDrawerContent
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
      />
    </MutationDrawer>
  );
}
