import { Navigate, useParams } from 'react-router-dom';
import { OffersPageDetails } from './OffersPageDetails';

export function OffersPage() {
  const { offerGUID } = useParams();

  if (!offerGUID) return <Navigate to="/loads" />;

  return <OffersPageDetails guid={offerGUID} />;
}
