import { Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { FormikVenueAddressAutofill } from '../core/TripFormikVenueAddressAutofill';
import { TripCreateDTO } from '../data/TripsDTO';
import { TripFormAssignDriver } from './TripFormAssignDriver';
import { TripFormLoads } from './TripFormLoads';

export function TripForm() {
  const formik = useFormikContext<TripCreateDTO>();
  const { driver } = formik.values;

  const driverGuid = driver?.guid;

  return (
    <Box paddingTop="small" overflow="auto" height="calc(100vh - 116px)">
      <Stack space="large">
        <Box paddingLeft="medium" paddingRight="medium">
          <Stack space="small">
            <Columns space="small">
              <Column width="1/2">
                <FormikTextField
                  name="name"
                  fullWidth={true}
                  label="Trip Name"
                />
              </Column>
              <Column width="1/2">
                <TripFormAssignDriver />
              </Column>
            </Columns>
            <FormikVenueAddressAutofill
              fullWidth={true}
              name="start_address"
              startText="Start"
              data-intercom-target="trip-start-address"
            />
            <FormikVenueAddressAutofill
              fullWidth={true}
              name="end_address"
              startText="End"
            />
          </Stack>
        </Box>
        <Stack space="small">
          <Box paddingLeft="medium" paddingRight="medium">
            <Stack space="xxsmall">
              <Typography variant="h4">Trip Loads</Typography>
              <Typography color="textSecondary">
                Select the loads you want to include in your trip.
              </Typography>
            </Stack>
          </Box>

          <Box data-intercom-target="trip-loads-input">
            <TripFormLoads driverGuid={driverGuid} />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
